// @ts-nocheck


import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import CelebrationTwoToneIcon from "@mui/icons-material/CelebrationTwoTone";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function Success({ bookingFor, name }) {

    return (
        <>
        <div className="mt-5 full-width my-auto">
            <div className="p-md-4 p-3">
                <h2 className={'fw-bolder'}>
                    <span>
                        <CelebrationTwoToneIcon fontSize="large" color="primary" />&nbsp;
                    </span>
                    <span className={'mt-3'}>
                        CHECK NEARLY CONFIRMED!
                    </span>
                </h2>
                <p className={'mt-2 mt-md-4 mb-2 mb-md-3'}>
                    Congratulations, you’ve requested the world’s most-detailed property inspection - a Property Check. Money and sanity protection are now on your side. 
                </p>

                {bookingFor === 'myself' &&
                    <p className={'mt-2 mt-md-4 mb-2 mb-md-3'}>
                        You’ve been sent a confirmation via email and WhatsApp, as well as a link for a 4.99 AED deposit. This is required to lock in your booking.
                    </p>
                }


                <p className={'mt-2 mt-md-4 mb-2 mb-md-3'}>
                    We can’t wait to protect your money and sanity.
                </p>
            </div>
        </div>
        </>
    );
}

export default Success;
