//@ts-nocheck
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, FormControl, TextField, InputLabel, Select, MenuItem, FormHelperText, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getAll } from '../../../../../Services/GenericApiService';
import DeleteIcon from '@mui/icons-material/Delete';

// @ts-ignore
function InspectionTemplateForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {

    // translation
    const { t } = useTranslation();

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // linked module(s) for api
    const modulePropertyType = 'propertytype';
    const moduleRoomType = 'roomtype';

    //data 
    const [allParent, setAllParent] = useState([]);
    const [allChild, setAllChild] = useState([]);
    const [parentId, setParentId] = useState(null);
    const [childId, setChildId] = useState(null);
    const [parentChildData, setParentChildData] = useState({});
    const [roomSelections, setRoomSelections] = useState([]);
    const [noOfrooms, setNoOfrooms] = useState("Studio");

    const handleNoOfRooms = (event) => {
        setNoOfrooms(event.target.value);
    };

    const handleParent = (event) => {
        setParentId(event.target.value);
    };

    const handleChild = (event) => {
        setChildId(event.target.value);
    };

    // Handle adding a new row
    const addRoom = () => {
        setRoomSelections([...roomSelections, { rtParentId: '', rtChildId: '' }]);
    };

    // Handle removing a row
    const removeRoom = (index) => {
        const updatedSelections = [...roomSelections];
        updatedSelections.splice(index, 1);
        setRoomSelections(updatedSelections);
    };

    // Handle parent selection change
    const handleParentChange = (index, parentId) => {
        const updatedSelections = [...roomSelections];
        updatedSelections[index] = { parentId, childId: '' }; // Reset child when parent changes
        setRoomSelections(updatedSelections);
    };

    // Handle child selection change
    const handleChildChange = (index, childId) => {
        const updatedSelections = [...roomSelections];
        updatedSelections[index] = { ...updatedSelections[index], childId };
        setRoomSelections(updatedSelections);
    };

    // fetch all parent child data room
    const getAllParentChild = () => {
        let id = childId == null ? parentId : childId
        getAll(moduleRoomType + "/propertyType/" + id)
            .then((_res: any) => {
                // set all data
                setParentChildData(_res)
            })
            .catch(_err => {
                log(_err)
            })
    };

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            _data.roomType = roomSelections;
            _data.rooms = noOfrooms;
            _data.ptParentId = parentId;
            _data.ptChildId = childId;
            handleAddEvent(_data);
        } else {
            _data.roomType = roomSelections;
            _data.rooms = noOfrooms;
            _data.ptParentId = parentId;
            _data.ptChildId = childId;
            handleEditEvent(_data);
        }
    }

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // get all parent
    const getAllParent = () => {
        getAll(modulePropertyType + '/parent')
            .then((_res: any) => {
                // set all data
                setAllParent(_res)
            })
            .catch(_err => {
                log(_err)
            })
    }

    // get all parent
    const getAllChildByParent = (_parentId) => {
        getAll(modulePropertyType + '/child/' + _parentId)
            .then((_res: any) => {
                // set all data
                setAllChild(_res)
            })
            .catch(_err => {
                log(_err)
            })
    }

    // hooks
    useEffect(() => {
        getAllParent();
        if (isEditMode) {
            reset(recordToUpdate);
            setParentId(recordToUpdate.ptParentId);
            setChildId(recordToUpdate.ptChildId);

            setNoOfrooms(recordToUpdate.rooms);
            if (recordToUpdate.inspectiontemplateInspectiontemplaterooms.length > 0) {
                const mappedData = recordToUpdate.inspectiontemplateInspectiontemplaterooms.map(item => ({
                    parentId: item.inspectiontemplateroomsRoomtype.id,
                    childId: item.inspectiontemplateroomsChildRoomtype.id
                }));

                setRoomSelections(mappedData)
            }

        }
    }, []);

    useEffect(() => {
        if (parentId) {
            getAllChildByParent(parentId);
        }
    }, [parentId]);

    useEffect(() => {
        if (parentId || childId) {
            getAllParentChild();
        }
    }, [parentId, childId]);

    useEffect(() => {
        if (allParent.length > 0 && isEditMode) {
            setValue("parentId", recordToUpdate.ptParentId);
            setParentId(recordToUpdate.ptParentId);
        }
    }, [allParent]);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} Inspection Template
                                    </h3>

                                </div>


                                {/* Template Name */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                            {...register("name", { required: true })}
                                            label="Template Name"
                                            type={"text"}
                                            error={!!errors.name}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Parent Name */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="ptParentIdLabel">
                                            Parent Property Type
                                        </InputLabel>
                                        {allParent.length > 0 &&
                                            <Select labelId="ptParentIdLabel"
                                                id="ptParentIdSelect"
                                                {...register("ptParentId", { required: true })}
                                                error={!!errors.ptParentId}
                                                value={parentId}
                                                onChange={handleParent}
                                                label="Parent Property Type">
                                                {
                                                    allParent.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allParent.length == 0 &&
                                            <>
                                                <Select labelId="ptParentIdLabel"
                                                    disabled
                                                    id="ptParentIdSelect"
                                                    label="Parent Property Type">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Child Name */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="ptChildIdLabel">
                                            Child Property Type
                                        </InputLabel>
                                        {allChild.length > 0 &&
                                            <Select labelId="ptChildIdLabel"
                                                id="ptChildIdSelect"
                                                {...register("ptChildId", { required: true })}
                                                error={!!errors.ptChildId}
                                                value={childId}
                                                onChange={handleChild}
                                                label="Child Property Type">
                                                {
                                                    allChild.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allChild.length == 0 &&
                                            <>
                                                <Select labelId="ptChildIdLabel"
                                                    disabled
                                                    id="ptChildIdSelect"
                                                    label="Child Property Type">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* no.of room */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="roomsLabel">
                                            Number of Rooms
                                        </InputLabel>
                                        <Select labelId="roomsLabel"
                                            id="roomsSelect"
                                            {...register('rooms', { required: true })}
                                            error={!!errors.rooms}
                                            value={noOfrooms}
                                            onChange={handleNoOfRooms}
                                            label="Number of Rooms">

                                            <MenuItem value="Studio" key="0">Studio</MenuItem>
                                            <MenuItem value="1" key="1">1</MenuItem>
                                            <MenuItem value="2" key="2">2</MenuItem>
                                            <MenuItem value="3" key="3">3</MenuItem>
                                            <MenuItem value="4" key="4">4</MenuItem>
                                            <MenuItem value="5" key="5">5</MenuItem>
                                            <MenuItem value="6" key="6">6</MenuItem>
                                            <MenuItem value="7" key="7">7</MenuItem>
                                            <MenuItem value="8" key="8">8</MenuItem>
                                            <MenuItem value="9" key="9">9</MenuItem>
                                            <MenuItem value="10" key="10">10</MenuItem>
                                            <MenuItem value="11" key="11">11</MenuItem>
                                            <MenuItem value="12" key="12">12</MenuItem>
                                            <MenuItem value="13" key="13">13</MenuItem>
                                            <MenuItem value="14" key="14">14</MenuItem>
                                            <MenuItem value="15" key="15">15</MenuItem>



                                        </Select>
                                    </FormControl>
                                </div>

                                {/* room type section */}
                                <div>

                                    {/* Dynamically generated parent and child select boxes */}
                                    {roomSelections.map((selection, index) => (
                                        <div className='row mt-4'>
                                            <div className="col-md-4 mt-4">
                                                <FormControl fullWidth>
                                                    <InputLabel id={`parentLabel-${index}`}>Parent Room Type</InputLabel>
                                                    {Object.entries(parentChildData).length > 0 ? (
                                                        <Select
                                                            labelId={`parentLabel-${index}`}
                                                            value={selection?.parentId || ''}
                                                            onChange={(event) => handleParentChange(index, event.target.value)}
                                                            label="Parent Room Type"
                                                        >
                                                            {Object.entries(parentChildData).map(([parentId, parent]) => (
                                                                <MenuItem key={parentId} value={parentId}>
                                                                    {parent.parentName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    ) : (
                                                        <Select
                                                            labelId={`parentLabel-${index}`}
                                                            disabled
                                                            value=""
                                                            label="Parent Room Type"
                                                        >
                                                            <MenuItem value="">
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                    )}
                                                </FormControl>
                                            </div>

                                            <div className="col-md-4 mt-4">
                                                <FormControl fullWidth>
                                                    <InputLabel id={`childLabel-${index}`}>Child Room Type</InputLabel>
                                                    {selection?.parentId && parentChildData[selection.parentId]?.children.length > 0 ? (
                                                        <Select
                                                            labelId={`childLabel-${index}`}
                                                            value={selection?.childId || ''}
                                                            onChange={(event) => handleChildChange(index, event.target.value)}
                                                            label="Child Room Type"
                                                            disabled={!selection?.parentId} // Disable if no parent is selected
                                                        >
                                                            {parentChildData[selection.parentId]?.children.map((child) => (
                                                                <MenuItem key={child.id} value={child.id}>
                                                                    {child.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    ) : (
                                                        <Select
                                                            labelId={`childLabel-${index}`}
                                                            disabled
                                                            value=""
                                                            label="Child Room Type"
                                                        >
                                                            <MenuItem value="">
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                    )}
                                                    {selection?.parentId && parentChildData[selection.parentId]?.children.length === 0 && (
                                                        <FormHelperText>No Data</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>

                                            <div className="col-md-2 mt-4">
                                                {/* Button to remove the current row */}
                                                <IconButton aria-label="delete" onClick={() => removeRoom(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))}

                                    {/* Button to dynamically add a new row */}
                                    <Button variant="contained" color="primary" onClick={addRoom} className='mt-4'>
                                        Add Room
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default InspectionTemplateForm;
