// @ts-nocheck
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getAll, post } from '../../../Services/GenericApiService';
import { log } from '../../../Services/LoggerService';
import { FormControl, TextField, Typography, FormHelperText } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { LoadingButton } from '@mui/lab';

const LandingForm = () => {
    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // translation
    const { t } = useTranslation();

    // module(s) for api
    const moduleMain = 'landingpage';

    // linked components API modules (endpoints)
    const moduleChecktype = 'checktype';
    const country = 'country';

    //data vars
    const [allCheckType, setAllCheckType] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allCountries, setAllCountries] = useState([]);
    const [isOpen, setIsOpen] = useState(false); // State to track if menu is open
    const [selectedCountryCode, setSelectedCountryCode] = useState(971);

    const handleCountryCodeChange = (event) => {
        setSelectedCountryCode(event.target.value);
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    // fetch Custombookingform
    const fetchCheckType = () => {
        setIsLoading(true);
        getAll(moduleChecktype)
            .then((_res: any) => {
                setAllCheckType(_res);
                setIsLoading(false);
            })
            .catch(_err => {
                log(_err);
                setIsLoading(false);
            })
    }

    const fetchCountries = () => {
        setIsLoading(true); // show loader
        getAll(country)
            .then((_res: any) => {
                setAllCountries(_res);
                setIsLoading(false);
            })
            .catch(_err => {
                log(_err)
                setIsLoading(false); // hide loader
            })
    }

    // handle add record event
    const addRecord = (_formData: any) => {
        setIsLoading(true); // show loader
        _formData.number = selectedCountryCode + _formData.number;
        post(moduleMain, _formData)
            .then(_res => {
                setIsLoading(false); // hide loader
                window.location.href = '/booked';
            })
            .catch(_err => {
                log(_err)
                setIsLoading(false); // hide loader
            })
    }

    // form methods
    const onSubmit = (_data: any) => {
        addRecord(_data);
    }

    // hooks
    useEffect(() => {
        fetchCheckType();
        fetchCountries();
    }, [])

    return (
        <div className="card shadow-lg transparent-bg py-5 px-4 px-md-5">
            <Typography variant="h4" gutterBottom className={'gotham-black fw-bolder mb-4'}>
                GET AN <br />INSTANT PRICE
            </Typography>

            {/* Form div */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <FormControl fullWidth>
                        <TextField id="name"
                            {...register("name", { required: true })}
                            label={'NAME'}
                            type={"text"}
                            error={!!errors.name}
                            variant="outlined"
                            placeholder="John Doe"
                            className=" shadow" />
                    </FormControl>
                </div>
                <div className="row mt-3">
                    <FormControl fullWidth>
                        <TextField id="email"
                            {...register("email", { required: true })}
                            label={'EMAIL'}
                            type={"text"}
                            error={!!errors.email}
                            variant="outlined"
                            placeholder="John@example.com"
                            className=" shadow" />
                    </FormControl>
                </div>
                <div className="row mt-3">
                    <div className="col-5 col-md-4 m-0 p-0">
                        <FormControl fullWidth={true}>
                            <InputLabel id="grouped-select-label">
                                COUNTRY CODE
                            </InputLabel>
                            {allCountries.length > 0 &&
                                <Select
                                    placeholder='Select Country'
                                    id="grouped-select"
                                    className="shadow"
                                    {...register('countryCode', { required: true })}
                                    label="COUNTRY CODE"
                                    onOpen={handleOpen}
                                    onClose={handleClose}
                                    onChange={handleCountryCodeChange}
                                    value={selectedCountryCode} 

                                >
                                    {allCountries.map((element: any) => (
                                        <MenuItem value={element.countryCode} key={element.countryCode} disableRipple>
                                            {isOpen && ( // Render flag name and code only if menu is open
                                                <>
                                                    <img
                                                        src={element.flag}
                                                        alt={`Flag of ${element.name}`}
                                                        style={{ width: '20px', marginRight: '8px' }}
                                                    />
                                                    {element.name} (+{element.countryCode})
                                                </>
                                            )}
                                            {!isOpen && ( // Render only flag and code if menu is not open
                                                <>
                                                    <img
                                                        src={element.flag}
                                                        alt={`Flag of ${element.name}`}
                                                        style={{ width: '20px', marginRight: '8px' }}
                                                    />
                                                    (+{element.countryCode})
                                                </>
                                            )}
                                        </MenuItem>
                                    ))}
                                </Select>
                            }
                            {allCountries.length == 0 &&
                                <>
                                    <Select labelId="countryLabel"
                                        disabled
                                        id="countryLabelIdSelect"
                                        label="Country">
                                    </Select>
                                </>
                            }
                        </FormControl>
                    </div>
                    
                    <div className="col-7 col-md-8 m-0 pe-0 ps-2">
                    <FormControl fullWidth>
                            <TextField id="number"
                                {...register("number", { required: true })}
                                label={'NUMBER'}
                                type={"number"}
                                error={!!errors.number}
                                variant="outlined"
                                placeholder="50 123 4567"
                                className=" shadow" />
                        </FormControl>
                    </div>

                </div>

                <div className="row mt-3">
                    <FormControl fullWidth={true}>
                        <InputLabel id="checkTypeLabel">
                            TELL US THE CHECK TYPE
                        </InputLabel>
                        {allCheckType.length > 0 &&
                            <Select labelId="checkTypeLabel"
                                id="checkTypeSelect"
                                className=" shadow"
                                {...register("checkTypeId", { required: true })}
                                error={!!errors.checkTypeId}
                                value={watch('checkTypeId')}
                                label='TELL US THE CHECK TYPE'>
                                {
                                    allCheckType.map((el: any) =>
                                        <MenuItem value={el.id}
                                            key={el.id}> {el.name}
                                        </MenuItem>)
                                }
                            </Select>
                        }
                        {allCheckType.length == 0 &&
                            <>
                                <Select labelId="locationIdLabel"
                                    disabled
                                    id="checkTypeSelect"
                                    {...register("checkTypeId", { required: false })}
                                    error={!!errors.checkTypeId}
                                    value={watch('checkTypeId')}
                                    label='Check Type'>
                                    <MenuItem value={''}>
                                        No Data
                                    </MenuItem>
                                </Select>
                                <FormHelperText>
                                    No Data
                                </FormHelperText>
                            </>
                        }
                    </FormControl>

                </div>
                <div className="row mt-4">
                    <LoadingButton loading={isLoading}
                        size="large"
                        variant="contained"
                        type='submit'
                        className={'py-3 gotham-black'}>
                        {t('common.submit')}
                    </LoadingButton>

                </div>
            </form>
        </div>
    );
};

export default LandingForm;
