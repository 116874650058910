import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    register: {
                        tagline: 'Register with email',
                        haveAccount: 'Already have an account?',
                        createSuccess: 'Account Created',
                        creationMailSent: 'An activation email has been sent to you,'
                    },
                    login: {
                        tagline: 'Enter your email and password to login',
                        dontHaveAccount: 'Don\'t have an account?',
                        loginFailed: 'Login Failed',
                        invalidCredentials: 'Invalid login credentials'
                    },
                    form: {
                        email: 'Email',
                        password: 'Password'
                    },
                    common: {
                        add: 'Add',
                        edit: 'Update',
                        delete: 'Delete',
                        cancel: 'Cancel',
                        view: 'View',
                        success: 'Success',
                        allRecords: 'All Records',
                        recordDetails: 'Record Details',
                        tryAgain: 'Please try again later',
                        somethingWentWrong: 'Something went wrong',
                        createSuccess: 'Record created successfully',
                        editSuccess: 'Record updated successfully',
                        deleteSuccess: 'Record deleted successfully',
                        genericSuccess: 'Operation completed successfully',
                        next: 'Next',
                        back: 'Back',
                        submit: 'Submit',
                        filters: 'Apply Filter',
                        clearFilter: 'Clear Filter',
                        prevBooking: 'Last 3 Days Bookings',
                        todayBooking: "Today's Bookings",
                        nextBooking: 'Next 3 Days Bookings'

                    },
                    emailServer: {
                        host: 'Host',
                        port: 'Port',
                        email: 'Email',
                        password: 'Password',
                        ssltls: 'SSL/TLS',
                        active: 'Active',
                        details: 'Email Server Details'
                    },
                    environmentVariables: {
                        name: 'Name',
                        value: 'Value',
                        details: 'Environment Variable Details'
                    },
                    module: {
                        register: 'Register',
                        login: 'Login',
                        role: 'Roles',
                        user: 'Users',
                        checkType: 'Check Type',
                        custombookingform: 'Form Branding Configurations',
                        custombookingformdata: 'Inspection Requests',
                        mailServer: 'Mail Server',
                        emailTemplate: 'Email Template',
                        whatsappTemplate: 'Whatsapp Template',
                        environmentVariables: 'Environment Variable',
                        propertyType: 'Property Type',
                        roomType: 'Room Type',
                        booking: 'Booking',
                        inspectorManagementMain: 'Inspectors',
                        category: 'Category',
                        subCategory: 'Sub-Category',
                        issue: 'Issue',
                        // add new module here %TEMPLATE_MODULE_NAME%
                    },
                    category: {
                        name: 'Name',
                    },
                    subCategory: {
                        name: 'Name',
                        parentCategory: 'Parent Category'
                    },
                    issue: {
                        name: 'Name',
                        category: 'Category',
                        subCategory: 'Sub-Category'
                    },
                    role: {
                        title: 'Title',
                    },
                    checkType: {
                        name: 'Name',
                        id: 'Id'
                    },
                    propertyType: {
                        name: 'Name',
                        parent: 'Parent'
                    },
                    roomType: {
                        name: 'Name',
                        parent: 'Parent',
                        propertyType:'Property Type'
                    },
                    home: {
                        clientEmail: 'Client Email',
                        agentEmail: 'Agent Email',
                        visitSlot: 'Visit Slot',
                        visitDate: 'Visit Date',
                        client: 'Client',
                        agent: 'Agent'
                    },
                    user: {
                        firstName: 'First Name',
                        middleName: 'Middle Name',
                        lastName: 'Last Name',
                        email: 'Email',
                        contact: 'Contact',
                        roleId: 'Role',
                        isActive: 'Active',
                        isMicrosoftLinked: 'Microsoft Linked',
                        isGoogleLinked: 'Google Linked',
                        password: 'Password',
                        token: 'nan',
                        lastLogin: 'Last Login',
                    },
                    inspectorManagementMain: {
                        email: 'email',
                        inspectors: 'Inspectors',
                        slotsPerDay: 'Slots Per Day',
                        workingDayPerWeek: 'Working Day Per Week',
                        holidayStartDate: 'Holiday Start Date',
                        holidayEndDate: 'Holiday End Date',
                        holidayReason: 'Holiday Reason',
                        visitSlot: 'Visit Slot',
                        inspector:'Inspectors'

                    },
                    custombookingform: {
                        name: 'Name',
                        primaryColor: ' Select Primary Color',
                        secondaryColor: 'Select Secondary Color',
                        viewPrimaryColor: ' Selected Primary Color',
                        viewSecondaryColor: 'Select Secondary Color',
                        backgroundColor: 'Select Background Color',
                        textColor: 'Select Text Color',
                        pcHC: 'Primary Color Hex Code',
                        scHC: 'Secondary Color Hex Code',
                        bcHC: 'Background Color Hex Code',
                        tcHC: 'Text Color Hex Code',
                        hexCode: 'Hex Code',
                        logo: 'Logo',
                        tagline: 'Tag Line',
                        url: 'URL',
                    },
                    booking: {
                        contactDetails: 'Contact Details',
                        clientName: 'Client Name',
                        clientEmail: 'Client Email',
                        clientContact: 'Client Contact',
                        propertyDetails: 'Property Details',
                        checkType: 'Check Type',
                        propertyType: 'Property Type',
                        numberOfBedrooms: 'No. of Bedrooms',
                        area: 'Area',
                        community: 'Community',
                        unit: 'Unit',
                        visitDate: 'Visit Date',
                        visitSlot: 'Visit Slot',
                        formId: 'Partner Name',
                        schedule: 'Schedule',
                        specialInst: 'Special Instruction',
                        bookingRoomType: 'Booking Room Type',
                        roomType:'Room Type',
                        notes: 'Notes',
                        severity: 'Severity',
                        inspector: 'Inspector',
                    },
                    custombookingformdata: {
                        agentName: 'Agent Name',
                        agentEmail: 'Agent Email',
                        agentContact: 'Agent Contact',
                        clientName: 'Your Name',
                        clientEmail: 'Your Email',
                        clientContact: 'Your Contact',
                        checkType: 'Check Type',
                        addReInspection: 'Add Re-Inspection (+999 AED)',
                        propertyType: 'What are we inspecting?',
                        numberOfBedrooms: 'How many Bedrooms?',
                        area: 'Area',
                        community: 'Community',
                        unit: 'Unit',
                        visitDate: 'Visit Date',
                        visitSlot: 'Visit Slot',
                        formId: 'Partner Name',
                        specialInst: 'Tell us any special instructions',
                        clientPaid: 'Client Paid',
                        agentPaid: 'Agent Paid',
                        icBooking: 'Incomplete Booking',
                        propertyTypeSelect:'Property Type',
                        specialInsLong:"Tell us any special instructions"
                    }// add new module screen items here %TEMPLATE_MODULE_SCREEN_ITEMS%
                }
            },
            de: {
                translation: {
                    description: {
                        part1: 'Ändere <1>src/App.js</1> und speichere um neu zu laden.',
                        part2: 'Lerne React'
                    }
                }
            }
        }
    });

export default i18n;
