// @ts-nocheck

import React, { useEffect, useState } from 'react';
import '../../../index.scss';
import '../../../blobz.min.css';
import './Landing.css';
import {
    Box,
    Button,
    Rating,
    Typography,
    Avatar
} from "@mui/material";
import { Typewriter } from 'react-simple-typewriter'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import Tilt from 'react-parallax-tilt';
import CountUp from 'react-countup';
import ReactFloaterJs from 'react-floaterjs'
import { post, getAll, getById } from '../../../Services/GenericApiService';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { log } from '../../../Services/LoggerService';
import Marquee, { } from "react-marquee-slider";
import LandingForm from "./LandingForm";

const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")

function Landing() {
    // translation
    const { t } = useTranslation();

    //main module
    const moduleLandingpage = 'landingpage';

    //link modules for api
    const moduleVisitorCount = 'visitorcount';
    const moduleEnvironmentvariables = 'environmentvariables';

    // data vars
    const [propertyType, setPropertyType] = useState('apartment');
    const [bedrooms, setBedrooms] = useState<number>(1);
    const [houseImg, setHouseImg] = useState('apartment_1_Bed.png');
    const [googleReview, setGoogleReview] = useState([]);
    const [savingsMap, setSavingsMap] = useState({});

    // const [isNavVisible, setIsNavVisible] = useState(true);
    // const [prevScrollPos, setPrevScrollPos] = useState(0);
    // const [scrollTimeout, setScrollTimeout] = useState(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const currentScrollPos = window.pageYOffset;
    //         const isVisible = prevScrollPos > currentScrollPos;

    //         if (prevScrollPos > currentScrollPos || prevScrollPos < currentScrollPos) {
    //             setPrevScrollPos(currentScrollPos);
    //             setIsNavVisible(false);
    //         }

    //         // Clear any existing timeout
    //         clearTimeout(scrollTimeout);

    //         // Set a new timeout to detect when scrolling has stopped
    //         setScrollTimeout(setTimeout(() => {
    //             // Perform actions when scrolling has stopped
    //             setIsNavVisible(true);
    //         }, 500)); // Adjust the delay as needed
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [prevScrollPos, scrollTimeout]);

    // ui vars
    const [loading, setLoading] = useState(false);


    // On Click Handlers
    const handleChangePropertyType = (
        event: React.MouseEvent<HTMLElement>,
        propertyType: string,
    ) => {
        setPropertyType(propertyType);
    };

    const handleChangeBedrooms = (event: Event, newValue: number | number[]) => {
        setBedrooms(newValue as number);
    };

    // fetch Custombookingform
    const fetchAvgSaving = () => {
        // setLoading(true); // show loader
        getById(moduleEnvironmentvariables, 'avg_saving')
            .then((_res: any) => {
                let jsonObject = JSON.parse(_res.value);
                setSavingsMap(jsonObject);
                // setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                // setLoading(false); // hide loader
            })
    }


    // handle add record event
    const addVisitor = () => {
        post(moduleVisitorCount, {})
            .then(_res => {
            })
            .catch(_err => {
                // log(_err)
            })
    }

    //google review api
    const googleReviewApi = async () => {
        // getAll(moduleLandingpage + '/googleReviews')
        //     .then((_res: any) => {
        //         setGoogleReview(_res?.result?.reviews);
        //     })
        //     .catch(_err => {
        //         log(_err)
        //     })
    }

    // hooks
    useEffect(() => {
        fetchAvgSaving();
        addVisitor();
        googleReviewApi();
    }, []);

    // hooks
    useEffect(() => {
        if (propertyType === 'apartment') {
            setHouseImg(`${propertyType}_${bedrooms}_Bed.png`);
        } else {
            if (bedrooms === 1) {
                setHouseImg(`${propertyType}_2_Bed.png`);

            } else {
                setHouseImg(`${propertyType}_${bedrooms}_Bed.png`);
            }
        }
    }, [propertyType, bedrooms]);

    const buttonStyle = {
        position: 'fixed',
        bottom: '20px',
        zIndex: 1000,
        right: '20px',
    };

    /*useEffect(() => {
        // Wait for 2000 milliseconds (2 seconds)
        const delay = 4000;

        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, delay);

        // Clear the timeout if the component unmounts or if you want to cancel it for any reason
        return () => clearTimeout(timeoutId);
    }, []); // Empty dependency array means this effect runs once after the initial render
    */
   
    return (
        <>
            {/* {loading &&
                <div className={'d-flex h-100 w-100 justify-content-center align-items-center overflow-hidden'}>
                    <img className="img-fluid mx-auto" style={{ maxWidth: '30em' }}
                        src={require('../../../Assets/gif/logo.gif')} />
                </div>
            } */}

            {!loading &&
                <div style={{ overflowX: 'hidden' }}>
                    <Button
                        href={"https://api.whatsapp.com/send/?phone=9718002432563&text&type=phone_number&app_absent=0"}
                        target="_blank"
                        className={'text-decoration-none text-white gotham-black d-flex d-md-none pe-0'}
                        variant="contained"
                        color='primary'
                        startIcon={<WhatsAppIcon />}
                        style={buttonStyle}>
                    </Button>
                    {/* Menu */}
                    {/* ${isNavVisible ? 'd-flex' : 'd-none'} */}
                    <nav className={`navbar fixed-top transparent-bg`}>

                        {/* for laptop */}
                        <div className="row w-100 px-md-4 px-2 py-3 m-0 d-none d-md-flex">

                            {/* Logo */}
                            <div className="col-md-4 col-12 text-md-start text-center">
                                <svg width="161" height="50" viewBox="0 0 161 50" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_3207_19658)">
                                        <path d="M0.34491 29.59L127.295 29.59V-3.05176e-05L0.34491 -3.05176e-05V29.59Z"
                                            fill="#009540"></path>
                                        <path
                                            d="M8.50488 8.02002H15.0349C18.8949 8.02002 21.5249 9.74002 21.5249 13.18C21.5249 16.68 18.8449 18.55 14.9349 18.55H13.3549V22.53H8.50488V8.02002ZM14.6649 15.13C15.9049 15.13 16.7349 14.57 16.7349 13.53C16.7349 12.47 15.9649 11.93 14.6849 11.93H13.3549V15.17L14.6649 15.13Z"
                                            fill="white"></path>
                                        <path
                                            d="M22.0449 8.02002H29.1349C31.7049 8.02002 33.3449 8.66004 34.3649 9.67004C34.8124 10.1219 35.1609 10.6621 35.3882 11.256C35.6154 11.85 35.7165 12.4848 35.6849 13.12C35.7065 14.0612 35.4456 14.9872 34.9358 15.7786C34.426 16.57 33.6908 17.1905 32.8249 17.56L36.2049 22.53H30.6749L27.9349 18.38H26.9349V22.53H22.0849L22.0449 8.02002ZM28.9949 14.92C30.1549 14.92 30.8549 14.4 30.8549 13.51C30.8549 12.58 30.1349 12.12 29.0149 12.12H26.8949V14.96L28.9949 14.92Z"
                                            fill="white"></path>
                                        <path
                                            d="M36.0254 15.31C36.1275 13.2783 37.0065 11.3636 38.4806 9.9617C39.9547 8.55985 41.9112 7.77808 43.9454 7.77808C45.9796 7.77808 47.9361 8.55985 49.4102 9.9617C50.8843 11.3636 51.7633 13.2783 51.8654 15.31C51.7633 17.3416 50.8843 19.2564 49.4102 20.6582C47.9361 22.0601 45.9796 22.8419 43.9454 22.8419C41.9112 22.8419 39.9547 22.0601 38.4806 20.6582C37.0065 19.2564 36.1275 17.3416 36.0254 15.31ZM46.9654 15.31C46.9883 14.8971 46.9283 14.4838 46.789 14.0945C46.6498 13.7051 46.434 13.3475 46.1544 13.0428C45.8749 12.7381 45.5372 12.4924 45.1613 12.3202C44.7853 12.1479 44.3787 12.0527 43.9654 12.04C43.5539 12.0474 43.1482 12.1378 42.7724 12.3056C42.3966 12.4735 42.0586 12.7155 41.7785 13.017C41.4985 13.3186 41.2821 13.6735 41.1424 14.0606C41.0027 14.4477 40.9425 14.859 40.9654 15.27C40.9425 15.6833 41.0024 16.0971 41.1416 16.4871C41.2808 16.877 41.4964 17.2352 41.7758 17.5407C42.0552 17.8462 42.3928 18.0929 42.7688 18.2662C43.1448 18.4395 43.5516 18.5359 43.9654 18.55C44.3778 18.5426 44.7844 18.4519 45.1609 18.2834C45.5373 18.1149 45.8758 17.8721 46.156 17.5695C46.4363 17.2668 46.6524 16.9107 46.7915 16.5224C46.9306 16.1341 46.9897 15.7217 46.9654 15.31Z"
                                            fill="white"></path>
                                        <path
                                            d="M52.6753 8.02002H59.2053C63.0653 8.02002 65.6953 9.74002 65.6953 13.18C65.6953 16.68 63.0253 18.55 59.1053 18.55H57.5053V22.53H52.6553L52.6753 8.02002ZM58.8353 15.13C60.0753 15.13 60.9053 14.57 60.9053 13.53C60.9053 12.47 60.1353 11.93 58.9053 11.93H57.5053V15.17L58.8353 15.13Z"
                                            fill="white"></path>
                                        <path
                                            d="M66.2148 8.02002H78.5948V12.02H70.9849V13.49H78.0749V16.99H70.9849V18.53H78.6949V22.53H66.2148V8.02002Z"
                                            fill="white"></path>
                                        <path
                                            d="M79.5049 8.02002H86.5949C89.1649 8.02002 90.8049 8.66004 91.8249 9.67004C92.2737 10.1214 92.6237 10.6613 92.8526 11.2552C93.0816 11.8491 93.1845 12.4842 93.1549 13.12C93.177 14.0626 92.9152 14.99 92.4034 15.7818C91.8915 16.5736 91.1534 17.1932 90.2849 17.56L93.6649 22.49H88.1349L85.3949 18.34H84.3949V22.53H79.5049V8.02002ZM86.4549 14.92C87.6149 14.92 88.3149 14.4 88.3149 13.51C88.3149 12.58 87.5949 12.12 86.4749 12.12H84.3549V14.96L86.4549 14.92Z"
                                            fill="white"></path>
                                        <path d="M97.5053 12.12H93.2354V8.02002H106.625V12.12H102.355V22.53H97.5053V12.12Z"
                                            fill="white"></path>
                                        <path
                                            d="M111.505 17.39L106.035 8.02002H111.505L113.995 12.78L116.505 8.02002H121.875L116.395 17.33V22.53H111.505V17.39Z"
                                            fill="white"></path>
                                        <path
                                            d="M8.17516 41.98C8.16535 40.9713 8.35912 39.9709 8.74489 39.0389C9.13066 38.1068 9.7005 37.2621 10.4203 36.5554C11.1401 35.8486 11.9951 35.2944 12.9341 34.9257C13.8731 34.5571 14.8768 34.3817 15.8852 34.41C17.2243 34.3553 18.5516 34.6802 19.714 35.3472C20.8764 36.0142 21.8267 36.9962 22.4552 38.18L18.5051 40.53C18.292 40.008 17.9277 39.5616 17.4592 39.248C16.9906 38.9344 16.439 38.768 15.8751 38.77C15.4854 38.791 15.1039 38.8909 14.7538 39.0634C14.4036 39.2359 14.092 39.4776 13.8378 39.7738C13.5837 40.0701 13.3921 40.4147 13.2748 40.787C13.1575 41.1593 13.1168 41.5515 13.1551 41.94V41.99C13.1551 43.87 14.2751 45.2 15.8751 45.2C16.4589 45.1937 17.0281 45.0171 17.5131 44.6921C17.998 44.367 18.3775 43.9075 18.6052 43.37L22.6052 45.63C21.7786 47.0989 20.4924 48.2556 18.9443 48.9221C17.3962 49.5886 15.672 49.7281 14.0369 49.319C12.4018 48.91 10.9464 47.9751 9.89455 46.6581C8.84268 45.3411 8.25262 43.715 8.21514 42.03L8.17516 41.98Z"
                                            fill="white"></path>
                                        <path
                                            d="M22.9746 34.6799H27.8246V39.7999H32.2646V34.6799H37.1146V49.1899H32.2646V43.9899H27.8246V49.1899H22.9746V34.6799Z"
                                            fill="white"></path>
                                        <path
                                            d="M38.2549 34.6799H50.6349V38.6799H43.0249V40.1499H50.1249V43.6599H43.0249V45.2299H50.7449V49.2299H38.2549V34.6799Z"
                                            fill="white"></path>
                                        <path
                                            d="M50.9945 41.98C50.9847 40.9713 51.1785 39.971 51.5643 39.0389C51.95 38.1068 52.5199 37.2621 53.2397 36.5554C53.9595 35.8487 54.8144 35.2944 55.7534 34.9258C56.6924 34.5571 57.6962 34.3817 58.7045 34.41C60.0451 34.3546 61.374 34.6791 62.5382 35.3461C63.7024 36.0131 64.6544 36.9955 65.2845 38.18L61.2845 40.52C61.0725 39.9972 60.7085 39.5501 60.2396 39.2363C59.7708 38.9226 59.2186 38.7567 58.6545 38.76C58.2647 38.7811 57.8833 38.8809 57.5331 39.0534C57.183 39.2259 56.8714 39.4676 56.6172 39.7639C56.363 40.0601 56.1715 40.4048 56.0542 40.777C55.9368 41.1493 55.8961 41.5415 55.9345 41.93V41.98C55.9345 43.86 57.0545 45.19 58.6545 45.19C59.24 45.1856 59.8114 45.01 60.2982 44.6848C60.7851 44.3597 61.1662 43.8991 61.3945 43.36L65.3945 45.62C64.568 47.0889 63.2818 48.2456 61.7337 48.9121C60.1856 49.5787 58.4614 49.7181 56.8263 49.3091C55.1912 48.9 53.7358 47.9651 52.6839 46.6481C51.6321 45.3311 51.042 43.7051 51.0045 42.02L50.9945 41.98Z"
                                            fill="white"></path>
                                        <path
                                            d="M65.7949 34.6799H70.6549V40.1099L74.7749 34.6799H80.4149L75.3549 40.9799L80.5249 49.1899H74.7349L71.8949 44.5899L70.6549 46.0999V49.1899H65.7949V34.6799Z"
                                            fill="white"></path>
                                        <path d="M1.10449 41.85H127.284" stroke="white" stroke-width="1.48"
                                            stroke-miterlimit="10"></path>
                                        <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                                        <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                                        <path d="M135.705 16.15L142.145 22.59L156.045 8.69995" stroke="white"
                                            stroke-width="1.58"
                                            stroke-miterlimit="10"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3207_19658">
                                            <rect width="160.33" height="49.5" fill="white"
                                                transform="translate(0.334961)"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            {/* Whatsapp Button */}
                            <div className="col-md-8 text-md-end text-center mt-4 mt-md-0 col-12">
                                {/* Laptop Div */}
                                <div className="d-none d-md-block">
                                    <Button
                                        href={"https://api.whatsapp.com/send/?phone=9718002432563&text&type=phone_number&app_absent=0"}
                                        target="_blank"
                                        className={'text-decoration-none text-white gotham-black'}
                                        variant="contained"
                                        color='primary'
                                        startIcon={<WhatsAppIcon />}>
                                        WhatsApp
                                    </Button>
                                    &nbsp;&nbsp;
                                    <AnchorLink href='#leadForm'>
                                        <Button variant="contained" color='primary' className={' text-decoration-none text-white gotham-black'}>
                                            GET QUOTE
                                        </Button>
                                    </AnchorLink>
                                </div>
                            </div>
                        </div>

                        {/* for mobile */}
                        <div className="row w-100 px-md-4 px-1 py-1 m-0 d-md-none">

                            {/* Logo */}
                            <div className="col-6 text-md-start">
                                <svg width="120" height="50" viewBox="0 0 161 50" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_3207_19658)">
                                        <path d="M0.34491 29.59L127.295 29.59V-3.05176e-05L0.34491 -3.05176e-05V29.59Z"
                                            fill="#009540"></path>
                                        <path
                                            d="M8.50488 8.02002H15.0349C18.8949 8.02002 21.5249 9.74002 21.5249 13.18C21.5249 16.68 18.8449 18.55 14.9349 18.55H13.3549V22.53H8.50488V8.02002ZM14.6649 15.13C15.9049 15.13 16.7349 14.57 16.7349 13.53C16.7349 12.47 15.9649 11.93 14.6849 11.93H13.3549V15.17L14.6649 15.13Z"
                                            fill="white"></path>
                                        <path
                                            d="M22.0449 8.02002H29.1349C31.7049 8.02002 33.3449 8.66004 34.3649 9.67004C34.8124 10.1219 35.1609 10.6621 35.3882 11.256C35.6154 11.85 35.7165 12.4848 35.6849 13.12C35.7065 14.0612 35.4456 14.9872 34.9358 15.7786C34.426 16.57 33.6908 17.1905 32.8249 17.56L36.2049 22.53H30.6749L27.9349 18.38H26.9349V22.53H22.0849L22.0449 8.02002ZM28.9949 14.92C30.1549 14.92 30.8549 14.4 30.8549 13.51C30.8549 12.58 30.1349 12.12 29.0149 12.12H26.8949V14.96L28.9949 14.92Z"
                                            fill="white"></path>
                                        <path
                                            d="M36.0254 15.31C36.1275 13.2783 37.0065 11.3636 38.4806 9.9617C39.9547 8.55985 41.9112 7.77808 43.9454 7.77808C45.9796 7.77808 47.9361 8.55985 49.4102 9.9617C50.8843 11.3636 51.7633 13.2783 51.8654 15.31C51.7633 17.3416 50.8843 19.2564 49.4102 20.6582C47.9361 22.0601 45.9796 22.8419 43.9454 22.8419C41.9112 22.8419 39.9547 22.0601 38.4806 20.6582C37.0065 19.2564 36.1275 17.3416 36.0254 15.31ZM46.9654 15.31C46.9883 14.8971 46.9283 14.4838 46.789 14.0945C46.6498 13.7051 46.434 13.3475 46.1544 13.0428C45.8749 12.7381 45.5372 12.4924 45.1613 12.3202C44.7853 12.1479 44.3787 12.0527 43.9654 12.04C43.5539 12.0474 43.1482 12.1378 42.7724 12.3056C42.3966 12.4735 42.0586 12.7155 41.7785 13.017C41.4985 13.3186 41.2821 13.6735 41.1424 14.0606C41.0027 14.4477 40.9425 14.859 40.9654 15.27C40.9425 15.6833 41.0024 16.0971 41.1416 16.4871C41.2808 16.877 41.4964 17.2352 41.7758 17.5407C42.0552 17.8462 42.3928 18.0929 42.7688 18.2662C43.1448 18.4395 43.5516 18.5359 43.9654 18.55C44.3778 18.5426 44.7844 18.4519 45.1609 18.2834C45.5373 18.1149 45.8758 17.8721 46.156 17.5695C46.4363 17.2668 46.6524 16.9107 46.7915 16.5224C46.9306 16.1341 46.9897 15.7217 46.9654 15.31Z"
                                            fill="white"></path>
                                        <path
                                            d="M52.6753 8.02002H59.2053C63.0653 8.02002 65.6953 9.74002 65.6953 13.18C65.6953 16.68 63.0253 18.55 59.1053 18.55H57.5053V22.53H52.6553L52.6753 8.02002ZM58.8353 15.13C60.0753 15.13 60.9053 14.57 60.9053 13.53C60.9053 12.47 60.1353 11.93 58.9053 11.93H57.5053V15.17L58.8353 15.13Z"
                                            fill="white"></path>
                                        <path
                                            d="M66.2148 8.02002H78.5948V12.02H70.9849V13.49H78.0749V16.99H70.9849V18.53H78.6949V22.53H66.2148V8.02002Z"
                                            fill="white"></path>
                                        <path
                                            d="M79.5049 8.02002H86.5949C89.1649 8.02002 90.8049 8.66004 91.8249 9.67004C92.2737 10.1214 92.6237 10.6613 92.8526 11.2552C93.0816 11.8491 93.1845 12.4842 93.1549 13.12C93.177 14.0626 92.9152 14.99 92.4034 15.7818C91.8915 16.5736 91.1534 17.1932 90.2849 17.56L93.6649 22.49H88.1349L85.3949 18.34H84.3949V22.53H79.5049V8.02002ZM86.4549 14.92C87.6149 14.92 88.3149 14.4 88.3149 13.51C88.3149 12.58 87.5949 12.12 86.4749 12.12H84.3549V14.96L86.4549 14.92Z"
                                            fill="white"></path>
                                        <path d="M97.5053 12.12H93.2354V8.02002H106.625V12.12H102.355V22.53H97.5053V12.12Z"
                                            fill="white"></path>
                                        <path
                                            d="M111.505 17.39L106.035 8.02002H111.505L113.995 12.78L116.505 8.02002H121.875L116.395 17.33V22.53H111.505V17.39Z"
                                            fill="white"></path>
                                        <path
                                            d="M8.17516 41.98C8.16535 40.9713 8.35912 39.9709 8.74489 39.0389C9.13066 38.1068 9.7005 37.2621 10.4203 36.5554C11.1401 35.8486 11.9951 35.2944 12.9341 34.9257C13.8731 34.5571 14.8768 34.3817 15.8852 34.41C17.2243 34.3553 18.5516 34.6802 19.714 35.3472C20.8764 36.0142 21.8267 36.9962 22.4552 38.18L18.5051 40.53C18.292 40.008 17.9277 39.5616 17.4592 39.248C16.9906 38.9344 16.439 38.768 15.8751 38.77C15.4854 38.791 15.1039 38.8909 14.7538 39.0634C14.4036 39.2359 14.092 39.4776 13.8378 39.7738C13.5837 40.0701 13.3921 40.4147 13.2748 40.787C13.1575 41.1593 13.1168 41.5515 13.1551 41.94V41.99C13.1551 43.87 14.2751 45.2 15.8751 45.2C16.4589 45.1937 17.0281 45.0171 17.5131 44.6921C17.998 44.367 18.3775 43.9075 18.6052 43.37L22.6052 45.63C21.7786 47.0989 20.4924 48.2556 18.9443 48.9221C17.3962 49.5886 15.672 49.7281 14.0369 49.319C12.4018 48.91 10.9464 47.9751 9.89455 46.6581C8.84268 45.3411 8.25262 43.715 8.21514 42.03L8.17516 41.98Z"
                                            fill="white"></path>
                                        <path
                                            d="M22.9746 34.6799H27.8246V39.7999H32.2646V34.6799H37.1146V49.1899H32.2646V43.9899H27.8246V49.1899H22.9746V34.6799Z"
                                            fill="white"></path>
                                        <path
                                            d="M38.2549 34.6799H50.6349V38.6799H43.0249V40.1499H50.1249V43.6599H43.0249V45.2299H50.7449V49.2299H38.2549V34.6799Z"
                                            fill="white"></path>
                                        <path
                                            d="M50.9945 41.98C50.9847 40.9713 51.1785 39.971 51.5643 39.0389C51.95 38.1068 52.5199 37.2621 53.2397 36.5554C53.9595 35.8487 54.8144 35.2944 55.7534 34.9258C56.6924 34.5571 57.6962 34.3817 58.7045 34.41C60.0451 34.3546 61.374 34.6791 62.5382 35.3461C63.7024 36.0131 64.6544 36.9955 65.2845 38.18L61.2845 40.52C61.0725 39.9972 60.7085 39.5501 60.2396 39.2363C59.7708 38.9226 59.2186 38.7567 58.6545 38.76C58.2647 38.7811 57.8833 38.8809 57.5331 39.0534C57.183 39.2259 56.8714 39.4676 56.6172 39.7639C56.363 40.0601 56.1715 40.4048 56.0542 40.777C55.9368 41.1493 55.8961 41.5415 55.9345 41.93V41.98C55.9345 43.86 57.0545 45.19 58.6545 45.19C59.24 45.1856 59.8114 45.01 60.2982 44.6848C60.7851 44.3597 61.1662 43.8991 61.3945 43.36L65.3945 45.62C64.568 47.0889 63.2818 48.2456 61.7337 48.9121C60.1856 49.5787 58.4614 49.7181 56.8263 49.3091C55.1912 48.9 53.7358 47.9651 52.6839 46.6481C51.6321 45.3311 51.042 43.7051 51.0045 42.02L50.9945 41.98Z"
                                            fill="white"></path>
                                        <path
                                            d="M65.7949 34.6799H70.6549V40.1099L74.7749 34.6799H80.4149L75.3549 40.9799L80.5249 49.1899H74.7349L71.8949 44.5899L70.6549 46.0999V49.1899H65.7949V34.6799Z"
                                            fill="white"></path>
                                        <path d="M1.10449 41.85H127.284" stroke="white" stroke-width="1.48"
                                            stroke-miterlimit="10"></path>
                                        <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                                        <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                                        <path d="M135.705 16.15L142.145 22.59L156.045 8.69995" stroke="white"
                                            stroke-width="1.58"
                                            stroke-miterlimit="10"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3207_19658">
                                            <rect width="160.33" height="49.5" fill="white"
                                                transform="translate(0.334961)"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            {/* Whatsapp Button  for mobile */}
                            <div className="col-6 mt-2 text-end">
                                {/* Laptop Div */}
                                <AnchorLink href='#leadForm'>
                                    <Button variant="contained" color='primary' className={' text-decoration-none text-white gotham-black'}>
                                        GET QUOTE
                                    </Button>
                                </AnchorLink>
                            </div>
                        </div>
                    </nav>

                    {/* Spacer div for PHONE */}
                    <div className="row mt-10 d-md-none d-block"></div>

                    {/* Hero */}
                    <div className="row g-0 h-100 justify-content-center align-items-center hero-bg full-width">
                        {/* Left Div */}
                        <Reveal left opposite cascade>
                            <div className="col-md-7 px-3 px-md-5 col-12">
                                {/* Laptop Div */}
                                <div className={'d-none d-md-block'}>
                                    <Typography variant="h2" gutterBottom className={'gotham-black'}>
                                        HEY YOU.
                                        <br />
                                        YEAH, YOU.
                                        <br />
                                        <span className={'text-custom-secondary'}>
                                            YOU’RE ALMOST SAFE…
                                        </span>
                                    </Typography>
                                </div>
                                {/* Phone Div */}
                                <div className={'d-block d-md-none mt-5'}>
                                    <Typography variant="h4" gutterBottom className={'gotham-black'}>
                                        HEY YOU.
                                        <br />
                                        YEAH, YOU.
                                        <br />
                                        <span className={'text-custom-secondary'}>
                                            YOU’RE ALMOST SAFE…
                                        </span>
                                    </Typography>
                                </div>

                                <div className={"py-3"}>
                                    <Typography variant="h5" gutterBottom className={'gotham-book d-none d-md-block'}>
                                        You’re a Property Check away from protecting your money and sanity whatever the property, whatever the circumstances. Your money and sanity are safe at last.
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom className={'gotham-book d-block d-md-none'}>
                                        You’re a Property Check away from protecting your money and sanity whatever the property, whatever the circumstances. Your money and sanity are safe at last.
                                    </Typography>
                                </div>
                                {/* Laptop Div */}
                                <div className={'d-none d-md-block'}>
                                    <Typography mt={3} className={'text-custom-secondary gotham-black fw-bolder'}
                                        variant="h4"
                                        gutterBottom>
                                        AS FEATURED IN
                                    </Typography>
                                </div>
                                {/* Phone Div */}
                                <div className={'d-block d-md-none'}>
                                    <Typography className={'text-custom-secondary gotham-black fw-bolder'}
                                        variant="h5"
                                        gutterBottom>
                                        AS FEATURED IN
                                    </Typography>
                                </div>
                                <div className="row mb-4 mb-md-0">
                                    <Marquee key={2} velocity={15} minScale={1}>

                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/k-times.webp')} />
                                        </div>
                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/gulf-news.webp')} />
                                        </div>
                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/dubaieye.webp')} />
                                        </div>
                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/menafn.webp')} />
                                        </div>
                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/the_national.webp')} />
                                        </div>
                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/yourstory.webp')} />
                                        </div>
                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/REALTEK LOGO.png')} />
                                        </div>
                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/FED.png')} />
                                        </div>
                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/P&D.png')} />
                                        </div>
                                        <div className="mx-3">
                                            <img className="img-fluid mx-auto"
                                                src={require('../../../Assets/img/feature_logo/TIMEOUT.png')} />
                                        </div>
                                    </Marquee>

                                </div>
                            </div>
                        </Reveal>


                        {/* Right Div */}
                        <div className="col-md-4 col-12">
                            <section id="leadForm">
                                <LandingForm />
                            </section>
                        </div>
                    </div>

                    {/* Check Types Laptop */}
                    <div
                        className="row h-40 justify-content-center align-items-center inspection-type-bg mb-md-5 d-none d-md-flex">
                        {/* Left Div */}
                        <div className="col-md-1"></div>
                        <div className="col-md-6 text-center">
                            <Reveal left opposite cascade>
                                <Typography variant="h3" gutterBottom className={'text-start gotham-black fw-bolder '}>
                                    THE WORLD’S <br /> <span
                                        className={'text-custom-secondary'}><strong><u>MOST-DETAILED</u></strong></span>
                                    <br /> PROPERTY
                                    INSPECTION
                                </Typography>
                            </Reveal>
                        </div>
                        {/* Right Div */}
                        <div className="col-md-4">
                            <Reveal right opposite cascade>
                                <Typography variant="h3" gutterBottom
                                    className={'text-custom-secondary gotham-black fw-bolder pb-0 mb-0'}>
                                    <Typewriter
                                        words={["NEW BUILD", "SECONDARY MARKET", "HANDOVER", "SNAGGING", "RENOVATION", "COMMERCIAL", "SUPER SPECIFIC", "RENTAL", "MOVE IN", "MOVE OUT", "DEVELOPER", "11-MONTH WARRANTY", "AGENT-LED"]}
                                        loop={0}
                                        cursor
                                        cursorStyle='_'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                    />
                                </Typography>
                                <Typography variant="h4" className={'pt-0 mt-0 gotham-black fw-bolder '}>
                                    CHECKS
                                </Typography>
                            </Reveal>
                        </div>
                    </div>

                    {/* Check Types Phone */}
                    <div
                        className="row h-40 justify-content-center align-items-center inspection-type-bg mb-md-5 d-flex d-sm-flex d-md-none">
                        {/* Left Div */}
                        <div className="col-md-8 text-start">
                            <Reveal left opposite cascade>
                                <Typography variant="h4" gutterBottom
                                    className={'gotham-black fw-bolder text-start px-3'}>
                                    THE WORLD’S <br /><span
                                        className={'text-custom-secondary'}><strong><u>MOST-DETAILED</u></strong></span>
                                    <br /> PROPERTY
                                    INSPECTION
                                </Typography>
                            </Reveal>
                        </div>
                        {/* Right Div */}
                        <div className="col-md-4">
                            <Reveal right opposite cascade>
                                <Typography variant="h4" gutterBottom
                                    className={'text-custom-secondary gotham-black fw-bolder pb-0 mb-0 px-3 text-start'}>
                                    <Typewriter
                                        words={["NEW BUILD", "SECONDARY MARKET", "HANDOVER", "SNAGGING", "RENOVATION", "COMMERCIAL", "SUPER SPECIFIC", "RENTAL", "MOVE IN", "MOVE OUT", "DEVELOPER", "11-MONTH WARRANTY", "AGENT-LED"]}
                                        loop={0}
                                        cursor
                                        cursorStyle='_'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                    />
                                </Typography>
                                <Typography variant="h4"
                                    className={'pt-0 mt-0 px-3 text-start gotham-black fw-bolder '}>
                                    CHECKS
                                </Typography>
                            </Reveal>
                        </div>
                    </div>


                    {/* On average we save */}
                    <div className="">
                        <div className="row h-60 justify-content-center align-items-center">

                            {/* Blob, can ruin responsiveness os hide in phone? */}
                            <div className="tk-blob d-none d-md-block" style={{
                                width: '35em',
                                padding: 0,
                                position: 'absolute',
                                left: '12em',
                                '--time': '20s',
                                '--amount': 7,
                                '--fill': '#76D9A1',
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 747.2 726.7">
                                    <path
                                        d="M539.8 137.6c98.3 69 183.5 124 203 198.4 19.3 74.4-27.1 168.2-93.8 245-66.8 76.8-153.8 136.6-254.2 144.9-100.6 8.2-214.7-35.1-292.7-122.5S-18.1 384.1 7.4 259.8C33 135.6 126.3 19 228.5 2.2c102.1-16.8 213.2 66.3 311.3 135.4z"></path>
                                </svg>
                            </div>

                            {/* LEFT DIV */}
                            <div className="col-md-6 px-5 order-md-1 order-2">
                                <Reveal left opposite cascade>

                                    <div className="row">
                                        {/*<div className="col-md-1"></div>*/}
                                        <div className="col-md-12 p-2 p-md-4">
                                            {/* Laptop Div */}
                                            <Typography variant="h5" gutterBottom style={{ zIndex: '999' }}
                                                className={'gotham-book text-start fw-bolder d-none d-md-block'}>
                                                SEE HOW MUCH <br /> WE'LL PROTECT FOR YOU
                                            </Typography>
                                            {/* Phone Div */}
                                            <Typography variant="subtitle1" gutterBottom style={{ zIndex: '999' }}
                                                className={'gotham-book text-center fw-bolder d-block d-md-none'}>
                                                SEE HOW MUCH WE'LL <br /> PROTECT FOR YOU
                                            </Typography>
                                        </div>
                                    </div>

                                    {/* Property Type */}
                                    <div className="row">
                                        {/* Field numberOfBedrooms */}
                                        <div className="col-md-6 card bg-dark shadow-lg p-2 p-md-4">
                                            {/* Field propertyType */}
                                            <ToggleButtonGroup
                                                fullWidth
                                                color="primary"
                                                value={propertyType}
                                                exclusive
                                                onChange={handleChangePropertyType}
                                                aria-label="Property Type"
                                            >
                                                <ToggleButton value="apartment" className='gotham-black fw-bolder'>Apartment</ToggleButton>
                                                <ToggleButton value="villa" className='gotham-black fw-bolder'>Villa</ToggleButton>
                                            </ToggleButtonGroup>

                                            <img
                                                src={require('../../../Assets/rooms/' + houseImg)}
                                                className={'img-fluid text-center mt-3'} style={{ height: '12em' }} />

                                            {/* Slider */}
                                            <Stack spacing={2} direction="row"
                                                sx={{ mt: 2, mb: 1 }}
                                                alignItems="center">
                                                <p className={'pt-0'}>
                                                    {propertyType === 'villa' ? '2' : 'Studio'}
                                                </p>
                                                <Slider aria-label="Bedrooms"
                                                    value={bedrooms}
                                                    valueLabelDisplay="on"
                                                    valueLabelFormat={(value) => `${value} Bed`}
                                                    step={1}
                                                    marks
                                                    min={propertyType === 'villa' ? 2 : 1}
                                                    max={6}
                                                    onChange={handleChangeBedrooms} />
                                                <p>
                                                    6+
                                                </p>
                                            </Stack>

                                            <div className="row d-block d-md-none">
                                                <div className="col-12">
                                                    <Typography variant="subtitle2" gutterBottom
                                                        className={'fw-bolder text-center mt-2'}>
                                                        ON AN AVERAGE <br /> WE PROTECT
                                                    </Typography>
                                                    <Typography variant="h4" gutterBottom
                                                        className={'gotham-black fw-bolder text-center'}>
                                                        {Object.keys(savingsMap).length === 0 ? '0 AED' : savingsMap[propertyType][bedrooms]}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Amount Saved */}
                                        <div className="col-md-5 my-auto px-1 px-md-0 py-4 py-md-0 p-md-4 mx-md-3 mx-0">
                                            <div className="card transparent-bg shadow-lg d-none d-md-block">
                                                <Typography variant="subtitle2" gutterBottom
                                                    className={'fw-bolder text-center mt-2'}>
                                                    ON AN AVERAGE <br /> WE PROTECT
                                                </Typography>
                                                <Typography variant="h4" gutterBottom
                                                    className={'gotham-black fw-bolder text-center'}>
                                                    {Object.keys(savingsMap).length === 0 ? '0 AED' : savingsMap[propertyType][bedrooms]}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </Reveal>
                            </div>

                            {/* Spacer Div */}
                            <div className="col-md-1 col-0"></div>

                            {/* RIGHT DIV */}
                            <div className="col-md-5 col-12 text-center order-md-2 order-1">
                                <Reveal right opposite cascade>
                                    {/* Laptop */}
                                    <Typography variant="h3" gutterBottom
                                        className={'gotham-black fw-bolder d-none d-md-block text-start'}>
                                        POWERED BY <br /> WORLD-FIRST TECH… <br />
                                        <span className={'text-custom-secondary'}>
                                            AND VENGEANCE
                                        </span>
                                    </Typography>

                                    {/* Phone */}
                                    <Typography variant="h5" gutterBottom
                                        className={'gotham-black fw-bolder d-md-none mt-3 mb-0 text-center px-3'}>
                                        POWERED BY <br /> WORLD-FIRST TECH… <br />
                                        <span className={'text-custom-secondary'}>
                                            AND VENGEANCE
                                        </span>
                                    </Typography>
                                </Reveal>
                            </div>

                        </div>
                    </div>


                    {/* About Div */}
                    {/* Features laptop */}
                    <div className="mt-20 texture-dark-bg py-5 d-none d-md-flex">

                        <div className="row w-100">
                            {/* Top Padding Div */}
                            <div className="mt-10 col-12"></div>

                            {/* 1st */}
                            <Reveal left opposite cascade>
                                <div className="col-md-4 text-center mt-md-0 mt-md-3 mt-5">
                                    <ReactFloaterJs>
                                        <img className="img-fluid mx-auto px-md-5 drop-shadow"
                                            src={require('../../../Assets/img/icon/general/WorldFirstTech.png')}
                                            alt="World's Most Detailed Property Report Card" />
                                    </ReactFloaterJs>
                                    <Typography variant="h5" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        THE WORLD’S MOST-DETAILED
                                        PROPERTY REPORT CARD
                                    </Typography>
                                </div>
                            </Reveal>

                            {/* 2nd */}
                            <Reveal bottom opposite cascade>
                                <div className="col-md-4 text-center mt-md-0 mt-md-3 mt-5">
                                    <ReactFloaterJs>
                                        <img className="img-fluid mx-auto px-md-5 drop-shadow"
                                            src={require('../../../Assets/img/icon/general/StateofArt (1).png')}
                                            alt="World's Most Detailed Property Report Card" />
                                    </ReactFloaterJs>
                                    <Typography variant="h5" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        STATE OF THE ART EQUIPMENT
                                        AND HANDSOME EXPERTS
                                    </Typography>
                                </div>
                            </Reveal>

                            {/* 3rd */}
                            <Reveal right opposite cascade>
                                <div className="col-md-4 text-center mt-md-0  mt-md-3 mt-5">
                                    <ReactFloaterJs>
                                        <img className="img-fluid mx-auto px-md-5 drop-shadow"
                                            src={require('../../../Assets/img/icon/general/Revolution.png')}
                                            alt="World's Most Detailed Property Report Card" />
                                    </ReactFloaterJs>
                                    <Typography variant="h5" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        A TEAM THAT GIVES A SH*T ABOUT <br />
                                        YOU AND YOUR PROPERTY
                                    </Typography>
                                </div>
                            </Reveal>

                            {/* Spacer Div */}
                            <div className="col-md-2 col-0"></div>

                            {/* 4th */}
                            <Reveal right opposite cascade>
                                <div className="col-md-4 text-center mt-md-5 mt-md-3 mt-5">
                                    <ReactFloaterJs>
                                        <img className="img-fluid mx-auto px-md-5 drop-shadow"
                                            src={require('../../../Assets/img/icon/general/TheWorldsMostDetailed.png')}
                                            alt="World's Most Detailed Property Report Card" />
                                    </ReactFloaterJs>
                                    <Typography variant="h5" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        WORLD-FIRST TECH
                                        FOR <br />THE MIDDLE EAST
                                    </Typography>
                                </div>
                            </Reveal>


                            {/* 5th */}
                            <Reveal right opposite cascade>
                                <div className="col-md-4 text-center mt-md-5 mt-md-3 mt-5">
                                    <ReactFloaterJs>
                                        <img className="img-fluid mx-auto px-md-5 drop-shadow"
                                            src={require('../../../Assets/img/icon/general/MoreJustSnagging.png')}
                                            alt="World's Most Detailed Property Report Card" />
                                    </ReactFloaterJs>
                                    <Typography variant="h5" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        BY YOU SIDE NOW, <br />
                                        THEN & FOREVER
                                    </Typography>
                                </div>
                            </Reveal>

                            {/* Bottom Padding Div */}
                            <div className="mt-10 col-12"></div>
                        </div>

                    </div>


                    {/* About Div */}
                    {/* Features mobile */}
                    <div className="exture-dark-bg d-md-none">

                        <div className="row w-100">
                            {/* 1st */}
                            <Reveal left opposite cascade>
                                <div className="col-md-4 text-center">
                                    <img className="img-fluid mx-auto px-md-5 drop-shadow hi-7"
                                        src={require('../../../Assets/img/icon/general/WorldFirstTech.png')}
                                        alt="World's Most Detailed Property Report Card" />
                                    <Typography variant="subtitle2" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        THE WORLD’S MOST-DETAILED <br />
                                        PROPERTY REPORT CARD
                                    </Typography>
                                </div>
                            </Reveal>

                            {/* 2nd */}
                            <Reveal bottom opposite cascade>
                                <div className="col-md-4 text-center mt-3">
                                    <img className="img-fluid mx-auto px-md-5 drop-shadow hi-7"
                                        src={require('../../../Assets/img/icon/general/StateofArt (1).png')}
                                        alt="World's Most Detailed Property Report Card" />
                                    <Typography variant="subtitle2" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        STATE OF THE ART EQUIPMENT
                                        AND HANDSOME EXPERTS
                                    </Typography>
                                </div>
                            </Reveal>

                            {/* 3rd */}
                            <Reveal right opposite cascade>
                                <div className="col-md-4 text-center mt-3">
                                    <img className="img-fluid mx-auto px-md-5 drop-shadow hi-7"
                                        src={require('../../../Assets/img/icon/general/Revolution.png')}
                                        alt="World's Most Detailed Property Report Card" />
                                    <Typography variant="subtitle2" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        A TEAM THAT GIVES A SH*T ABOUT <br />
                                        YOU AND YOUR PROPERTY
                                    </Typography>
                                </div>
                            </Reveal>

                            {/* Spacer Div */}
                            <div className="col-md-2 col-0"></div>

                            {/* 4th */}
                            <Reveal right opposite cascade>
                                <div className="col-md-4 text-center mt-3">
                                    <img className="img-fluid mx-auto px-md-5 drop-shadow hi-7"
                                        src={require('../../../Assets/img/icon/general/TheWorldsMostDetailed.png')}
                                        alt="World's Most Detailed Property Report Card" />
                                    <Typography variant="subtitle2" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        WORLD-FIRST TECH
                                        FOR <br /> THE MIDDLE EAST
                                    </Typography>
                                </div>
                            </Reveal>


                            {/* 5th */}
                            <Reveal right opposite cascade>
                                <div className="col-md-4 text-center mt-3">
                                    <img className="img-fluid mx-auto px-md-5 drop-shadow hi-7"
                                        src={require('../../../Assets/img/icon/general/MoreJustSnagging.png')}
                                        alt="World's Most Detailed Property Report Card" />
                                    <Typography variant="subtitle2" className="gotham-bold text-center mt-4 px-md-2 px-2">
                                        BY YOU SIDE NOW, <br />
                                        THEN & FOREVER
                                    </Typography>
                                </div>
                            </Reveal>

                            {/* Bottom Padding Div */}
                            <div className="mt-3 col-12"></div>
                        </div>

                    </div>

                    {/* Features laptop */}
                    <div className="h-60 w-100 d-none d-md-block py-5" style={{ backgroundColor: '#000000' }}>
                        <div className="h-60 features-gif-bg px-md-4 px-2 py-md-5 py-3">

                            <div className="row my-3">
                                <div className="col-md-6 col-12">
                                    <Reveal left opposite cascade>
                                        <div className="my-3 mt-md-0 p-3 card transparent-bg">
                                            <Typography variant="h3" gutterBottom
                                                className={'gotham-black text-custom-secondary'}>
                                                WE PROTECT YOUR MONEY & SANITY
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className={'gotham-book'}>
                                                State of the art equipment, world-first reporting technology and experts
                                                who
                                                give a sh*t about you and your property combine to ensure your money and
                                                sanity is protected whatever the circumstance.
                                                <br /> <br />
                                                We find more than anyone else on earth, so we protect more of your
                                                hard-earned
                                                cash than anyone else on earth.
                                            </Typography>
                                        </div>
                                    </Reveal>
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-md-0 col-0"></div>
                                <div className="col-md-6 col-12">
                                    <Reveal left opposite cascade>
                                        <div className="my-3 mt-md-0 p-3 card transparent-bg">
                                            <Typography variant="h3" gutterBottom
                                                className={'gotham-black text-custom-secondary'}>
                                                WE TAKE THIS PERSONALLY
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className={'gotham-book'}>
                                                We’ve lost money on property issues. We bet you have, too.
                                                It’s why Property Check exists - delivering peace of mind one Check at a
                                                time.
                                                We Check every property, whether residential, commercial or otherwise,
                                                as if it’s our own.
                                                <br /><br />
                                                We’re not ranked #1 for 5-star Google Reviews for no reason.
                                            </Typography>
                                        </div>
                                    </Reveal>
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col-md-0 col-0"></div>
                                <div className="col-md-6 col-12">

                                    <Reveal left opposite cascade>
                                        <div className="my-3 mt-md-0 p-3 card transparent-bg">
                                            <Typography variant="h3" gutterBottom
                                                className={'gotham-black text-custom-secondary'}>
                                                WHAT DO WE CHECK?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className={'gotham-book'}>
                                                Everything.
                                                <br /><br />
                                                Absolutely everything is included.
                                                <br /><br />
                                                From a speck of paint on a plug socket all the way up to the
                                                components of an A/C unit on the roof. Materials used, installation,
                                                best (or worst) practices are all looked at with a fine-tooth comb.
                                                <br /><br />
                                                We don’t just look at what is and isn’t working today.
                                                We Check every property with a 5 year outlook. Others will tick off
                                                things
                                                that are working now with little regard for tomorrow. But if something
                                                is working now that we think will cause you problems down the line, we
                                                won’t
                                                let it pass.
                                            </Typography>
                                        </div>
                                    </Reveal>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* Features phone */}
                    <div className="h-60 w-100 d-block d-md-none" style={{ backgroundColor: '#000000' }}>
                        <div className="h-60 px-md-4 px-2 py-md-5 py-3">

                            <div className="row">
                                <div className="col-12">
                                    <img className="img-fluid mx-auto"
                                        src={require('../../../Assets/gif/phone 2 reversed.gif')} />
                                </div>

                                <div className="col-md-5 col-12 mt-4">
                                    <Reveal left opposite cascade>
                                        <div className={' p-3'}>
                                            <Typography variant="h4" gutterBottom
                                                className={'gotham-black fw-bolder text-custom-secondary'}>
                                                WE PROTECT YOUR MONEY & SANITY
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className={'gotham-book'}>
                                                State of the art equipment, world-first reporting technology and experts
                                                who
                                                give a sh*t about you and your property combine to ensure your money and
                                                sanity is protected whatever the circumstance.
                                                <br /> <br />
                                                We find more than anyone else on earth, so we protect more of your
                                                hard-earned
                                                cash than anyone else on earth.
                                            </Typography>
                                        </div>
                                    </Reveal>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-0 col-0"></div>
                                <div className="col-md-5 col-12">
                                    <Reveal left opposite cascade>
                                        <div className="mt-md-0 p-3 pt-0">
                                            <Typography variant="h4" gutterBottom
                                                className={'gotham-black fw-bolder text-custom-secondary'}>
                                                WE TAKE THIS PERSONALLY
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className={'gotham-book'}>
                                                We’ve lost money on property issues. We bet you have, too.
                                                It’s why Property Check exists - delivering peace of mind one Check at a
                                                time.
                                                We Check every property, whether residential, commercial or otherwise,
                                                as if it’s our own.
                                                <br /><br />
                                                We’re not ranked #1 for 5-star Google Reviews for no reason.
                                            </Typography>
                                        </div>
                                    </Reveal>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-0 col-0"></div>
                                <div className="col-md-5 col-12">

                                    <Reveal left opposite cascade>
                                        <div className="mt-md-0 p-3 pt-0">
                                            <Typography variant="h4" gutterBottom
                                                className={'gotham-black fw-bolder text-custom-secondary'}>
                                                WHAT DO WE CHECK?
                                            </Typography>
                                            <Typography variant="body1" gutterBottom className={'gotham-book'}>
                                                Everything.
                                                <br /><br />
                                                Absolutely everything is included.
                                                <br /><br />
                                                From a speck of paint on a plug socket all the way up to the
                                                components of an A/C unit on the roof. Materials used, installation,
                                                best (or worst) practices are all looked at with a fine-tooth comb.
                                                <br /><br />
                                                We don’t just look at what is and isn’t working today.
                                                We Check every property with a 5 year outlook. Others will tick off
                                                things
                                                that are working now with little regard for tomorrow. But if something
                                                is working now that we think will cause you problems down the line, we
                                                won’t
                                                let it pass.
                                            </Typography>
                                        </div>
                                    </Reveal>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* How It Works */}
                    <div className='d-none d-md-flex mt-17'></div>
                    <div className="h-100 mt-3">
                        {/* Laptop Div */}
                        <Typography variant="h2"
                            className={'gotham-black text-custom-secondary text-center d-none d-md-block'}>
                            HOW IT WORKS
                        </Typography>

                        {/*  Phone Div */}
                        <Typography variant="h4"
                            className={'gotham-black text-custom-secondary text-center d-block d-md-none'}>
                            HOW IT WORKS
                        </Typography>

                        {/* Laptop Div */}
                        <Typography className={'text-center gotham-book d-none d-md-block'} variant="h5"
                            gutterBottom>
                            No Hassle. No Bullshit. Just Protection.
                        </Typography>
                        {/* Phone Div */}
                        <Typography className={'text-center gotham-book d-block d-md-none'} variant="subtitle1"
                            gutterBottom
                            style={{ lineHeight: '1.5em' }}>
                            No Hassle. No Bullshit. <br /> Just Protection.
                        </Typography>



                        {/* Laptop Display */}
                        <div className="row d-none d-md-flex">

                            {/* 1 */}
                            <>
                                {/* Laptop Display */}
                                <div
                                    className="col-md-5 h-60 justify-content-end align-items-center px-5 px-md-0 d-none d-md-flex">
                                    <Reveal left opposite cascade>
                                        <AnchorLink href='#leadForm'>
                                            <Button variant="contained" color='primary' className={'px-5 pt-3 pb-2'}>
                                                <h3 className={'text-decoration-none text-white fw-bolder'}> Book
                                                    Now</h3>
                                            </Button>
                                        </AnchorLink>
                                        &nbsp;
                                        <img
                                            src={require('../../../Assets/img/icon/click.webp')}
                                            className={'img-fluid mt-2'} style={{ height: '4em' }} />
                                    </Reveal>
                                </div>
                                <div className="col-md-1"></div>
                                <div
                                    className="col-md-6 h-60 d-flex justify-content-center align-items-center how-it-works-one-bg">
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <Reveal right opposite cascade>
                                                <Typography variant="h3" gutterBottom
                                                    className={'gotham-black px-3 px-md-0'}>
                                                    1. BOOK
                                                </Typography>
                                            </Reveal>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Reveal bottom opposite cascade>
                                                <Typography variant="h5" className={'gotham-book px-3 px-md-0'}>
                                                    Booking a Property Check is so easy our nan could do it. And yes, we
                                                    did get her to try.
                                                    <br /> <br />
                                                    It takes 30 seconds, there's no up front payment and then you can
                                                    sit back and leave the rest to
                                                    us.
                                                </Typography>
                                            </Reveal>
                                        </div>
                                    </div>
                                </div>
                            </>


                            {/* 2 */}
                            <>
                                <div className="col-md-1 mb-4"></div>
                                <div className="col-md-4 mb-4 h-60 d-flex justify-content-end align-items-center">
                                    <Reveal left opposite cascade>
                                        <ReactFloaterJs>
                                            <img
                                                src={require('../../../Assets/img/isometric/ISOMETRIC-KITCHEN REV1.png')}
                                                className={'img-fluid mt-2'} />
                                        </ReactFloaterJs>
                                    </Reveal>
                                </div>
                                <div className="col-md-1 mb-4"></div>
                                <div
                                    className="col-md-6 mb-4 h-60 d-flex justify-content-center align-items-center how-it-works-two-bg">
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <Reveal right opposite cascade>
                                                <Typography variant="h3" gutterBottom
                                                    className={'gotham-black px-3 px-md-0'}>
                                                    2. CHECK
                                                </Typography>
                                            </Reveal>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Reveal bottom opposite cascade>
                                                <Typography variant="h5" className={'gotham-book px-3 px-md-0'}>
                                                    Now it's time for the world's most-detailed property inspection - a
                                                    Property Check - carried out
                                                    by experts with world-class equipment and world-first tech.
                                                    <br /> <br />
                                                    Our property inspection app - built by us, for you - is the only
                                                    kind in the world built for homes in
                                                    the Middle East... and it's available nowhere else on earth.
                                                </Typography>
                                            </Reveal>
                                        </div>
                                    </div>
                                </div>
                            </>


                            {/* 3 */}
                            <>
                                <div className="col-md-1 mt-10"></div>
                                <div className="col-md-4 mt-10 h-60 d-flex justify-content-end align-items-center">
                                    <Reveal left opposite cascade>
                                        <ReactFloaterJs>
                                            <img
                                                src={require('../../../Assets/img/phone/PHONE GRID_.png')}
                                                className={'img-fluid mt-2'} />
                                        </ReactFloaterJs>
                                    </Reveal>
                                </div>
                                <div className="col-md-1 mt-10"></div>
                                <div
                                    className="col-md-6 mt-10 h-60 d-flex  justify-content-center align-items-center how-it-works-three-bg">
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <Reveal right opposite cascade>
                                                <Typography variant="h3" gutterBottom
                                                    className={'gotham-black px-3 px-md-0'}>
                                                    3. PROTECTED
                                                </Typography>
                                            </Reveal>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Reveal bottom opposite cascade>
                                                <Typography variant="h5" className={'gotham-book px-3 px-md-0'}>
                                                    Property Check complete, your Property Report Card is delivered to
                                                    you in digital and PDF
                                                    format, usually the same day your property inspection is carried
                                                    out.
                                                    <br /> <br />
                                                    It is, by some distance, the world's most-detailed Property Report
                                                    Card. That means it protects
                                                    more of your money and sanity than anywhere else in the world.
                                                </Typography>
                                            </Reveal>
                                        </div>
                                    </div>

                                </div>

                            </>

                        </div>

                        {/* mobile display */}
                        <div className="row d-block d-md-none">

                            {/* 1 */}
                            <>
                                {/* Phone Display */}
                                <div
                                    className="col-md-6 h-40 d-flex justify-content-center align-items-center how-it-works-one-bg">
                                    <div className="row">
                                        <div className="col-12">
                                            <Reveal right opposite cascade>
                                                <Typography variant="h4" gutterBottom
                                                    className={'gotham-black px-3 px-md-0'}>
                                                    1. BOOK
                                                </Typography>
                                            </Reveal>
                                        </div>
                                        <div className="col-12">
                                            <Reveal bottom opposite cascade>
                                                <Typography variant="subtitle1" className={'gotham-book px-3 px-md-0'}>
                                                    Booking a Property Check is so easy our nan could do it. And yes, we
                                                    did get her to try.
                                                    <br /> <br />
                                                    It takes 30 seconds, there's no up front payment and then you can
                                                    sit back and leave the rest to
                                                    us.
                                                </Typography>
                                            </Reveal>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center align-items-center">
                                            <Reveal left opposite cascade style={{ marginTop: '2em' }}>
                                                <AnchorLink href='#leadForm'>
                                                    <Button variant="contained" color='primary' className={'gotham-black px-5 pt-2 pb-2'}>
                                                        Book Now
                                                    </Button>
                                                </AnchorLink>
                                                &nbsp;
                                                <img
                                                    src={require('../../../Assets/img/icon/click.webp')}
                                                    className={'img-fluid mt-2'} style={{ height: '3em' }} />
                                            </Reveal>
                                        </div>
                                    </div>
                                </div>
                            </>


                            {/* 2 */}
                            <>
                                <div
                                    className="col-md-6 h-40 d-flex justify-content-center align-items-center how-it-works-two-bg">
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <Reveal right opposite cascade>
                                                <Typography variant="h4" gutterBottom
                                                    className={'gotham-black px-3 px-md-0'}>
                                                    2. CHECK
                                                </Typography>
                                            </Reveal>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Reveal bottom opposite cascade>
                                                <Typography variant="subtitle1" className={'gotham-book px-3 px-md-0'}>
                                                    Now it's time for the world's most-detailed property inspection - a
                                                    Property Check - carried out
                                                    by experts with world-class equipment and world-first tech.
                                                    <br /> <br />
                                                    Our property inspection app - built by us, for you - is the only
                                                    kind in the world built for homes in
                                                    the Middle East... and it's available nowhere else on earth.
                                                </Typography>
                                            </Reveal>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center align-items-center">
                                            <Reveal left opposite cascade>
                                                <img
                                                    src={require('../../../Assets/img/isometric/ISOMETRIC-KITCHEN REV1.png')}
                                                    className={'img-fluid mt-2 hi-15'} />
                                            </Reveal>
                                        </div>
                                    </div>

                                </div>

                            </>


                            {/* 3 */}
                            <>
                                <div
                                    className="col-md-6 h-40 d-flex  justify-content-center align-items-center how-it-works-three-bg">
                                    <div className="row">
                                        <div className="col-12 col-md-8">
                                            <Reveal right opposite cascade>
                                                <Typography variant="h4" gutterBottom
                                                    className={'gotham-black px-3 px-md-0'}>
                                                    3. PROTECTED
                                                </Typography>
                                            </Reveal>
                                        </div>
                                        <div className="col-12 col-md-8">
                                            <Reveal bottom opposite cascade>
                                                <Typography variant="subtitle1" className={'gotham-book px-3 px-md-0'}>
                                                    Property Check complete, your Property Report Card is delivered to
                                                    you in digital and PDF
                                                    format, usually the same day your property inspection is carried
                                                    out.
                                                    <br /> <br />
                                                    It is, by some distance, the world's most-detailed Property Report
                                                    Card. That means it protects
                                                    more of your money and sanity than anywhere else in the world.
                                                </Typography>
                                            </Reveal>
                                        </div>

                                        <div className="col-12 my-2 d-flex justify-content-center align-items-center">
                                            <Reveal left opposite cascade>
                                                <img
                                                    src={require('../../../Assets/img/phone/PHONE GRID_.png')}
                                                    className={'img-fluid mt-2 hi-15'} />
                                            </Reveal>
                                        </div>

                                    </div>

                                </div>



                            </>

                        </div>
                    </div>

                    <div className="row"></div>

                    {/* What we have protected so far */}
                    {/* laptop div */}
                    <div className="row texture-dark-bg py-5 my-3 my-md-5 d-none d-md-flex">
                        {/* Left Div */}
                        <div
                            className="col-md-6 h-60 d-flex justify-content-center align-items-center px-md-5 px-5 col-12 order-md-1 order-2">
                            <div className="row">
                                <div className="col-md-1 col-0"></div>
                                <div className={'col-md-11 col-12'}>
                                    <Reveal left opposite cascade>
                                        <Typography variant="h5" gutterBottom
                                            className={'gotham-book d-none d-md-block'}>
                                            WHAT WE'VE <span
                                                className={'text-custom-secondary'}>PROTECTED FOR THE GOOD PEOPLE</span> OF
                                            THE UAE
                                            SO FAR
                                        </Typography>
                                        <Typography variant="h5" gutterBottom
                                            className={'gotham-book d-block d-md-none px-0'}>
                                            WHAT WE'VE <span
                                                className={'text-custom-secondary'}>PROTECTED FOR THE GOOD PEOPLE</span> OF
                                            THE UAE
                                            SO FAR
                                        </Typography>
                                    </Reveal>
                                </div>

                                <div className="col-md-1 col-0"></div>
                                <div
                                    className="card shadow-lg rounded-pill text-dark col-md-11 col-12 bg-custom-gradient text-white border-0 text-center mt-4 ">
                                    <Reveal bottom opposite cascade>
                                        {/* Laptop Div */}
                                        <Typography variant="h2" gutterBottom
                                            className={'py-4 my-0 px-2 gotham-black d-none d-md-block'}>
                                            <span className={' '}>
                                                <CountUp start={10000000}
                                                    end={49191193}
                                                    duration={3}
                                                    separator=","
                                                    decimal=","
                                                    suffix=" AED" />
                                            </span>
                                        </Typography>

                                    </Reveal>
                                </div>

                                <div className="col-md-3"></div>
                                <div
                                    className="card shadow-lg rounded-pill text-dark col-md-9 bg-custom-gradient text-white border-0 text-center mt-4 mb-md-0 mb-5">
                                    <Reveal bottom opposite cascade>
                                        <Typography variant="h5" gutterBottom className={'gotham-medium py-4 my-0 px-2'}>
                                            ⃰ WE HAVE THE DATA, BABY
                                        </Typography>
                                    </Reveal>
                                </div>
                            </div>
                        </div>

                        {/* Right Div */}
                        <div
                            className="col-md-6 h-60 d-flex justify-content-center align-items-center px-5 order-md-2 order-1">
                            <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>
                                <img className="img-fluid mx-auto px-3"
                                    src={require('../../../Assets/img/billboard/BILLBOARD BURJ.png')} />
                            </Tilt>
                        </div>
                    </div>

                    {/* mobile div */}
                    {/* What we have protected so far */}
                    <div className="row texture-dark-bg d-flex d-md-none">
                        {/* Left Div */}
                        <div
                            className="col-md-6 h-20 d-flex justify-content-center align-items-center px-md-5 px-5 col-12 order-md-1 order-2">
                            <div className="row">
                                <div className={'col-12'}>
                                    <Reveal left opposite cascade>
                                        <Typography variant="subtitle1" gutterBottom
                                            className={'gotham-book px-0'}>
                                            WHAT WE'VE <span
                                                className={'text-custom-secondary'}>PROTECTED FOR THE GOOD PEOPLE</span> OF
                                            THE UAE
                                            SO FAR
                                        </Typography>
                                    </Reveal>
                                </div>
                                <div
                                    className="text-dark col-12 text-white border-0 text-center">
                                    <Reveal bottom opposite cascade>

                                        {/* Phone Div */}
                                        <Typography variant="h4" gutterBottom
                                            className={'pt-2 px-2 gotham-black'}>
                                            <span className={' '}>
                                                <CountUp start={10000000}
                                                    end={49191193}
                                                    duration={3}
                                                    separator=","
                                                    decimal=","
                                                    suffix=" AED" />
                                            </span>
                                        </Typography>
                                    </Reveal>
                                </div>

                                <div className="col-md-3"></div>
                                <div
                                    className="text-dark mb-3 col-md-9 text-white border-0 text-center">
                                    <Reveal bottom opposite cascade>
                                        <Typography variant="subtitle1" gutterBottom className={'gotham-medium px-2'}>
                                            ⃰ WE HAVE THE DATA, BABY
                                        </Typography>
                                    </Reveal>
                                </div>
                            </div>
                        </div>

                        {/* Right Div */}
                        <div
                            className="col-md-6 mt-3 h-20 d-flex justify-content-center align-items-center px-5 order-md-2 order-1">
                            <Tilt tiltMaxAngleX={5} tiltMaxAngleY={5}>
                                <img className="img-fluid mx-auto px-3 hi-15"
                                    src={require('../../../Assets/img/billboard/BILLBOARD BURJ.png')} />
                            </Tilt>
                        </div>
                    </div>


                    <div className="row my-5 h-30 d-none d-md-flex"></div>

                    {/* Testimonials */}
                    <div className="row h-60 testimonial-bg">
                        {/* Heading div */}
                        <div className="col-md-11 col-12 mx-md-0 text-end  d-none d-md-block">
                            <Reveal left opposite cascade>
                                <Typography variant="h2" gutterBottom
                                    className={'gotham-black fw-bolder'}>
                                    <span className={'text-custom-secondary'}>RANKED #1 IN THE UAE</span>
                                    <br />
                                    NOW, THEN & FOREVER
                                </Typography>
                            </Reveal>
                            <Reveal left opposite cascade>
                                <Typography variant="h5" className={'fw-bolder px-md-0 px-3'} gutterBottom>
                                    CHECK GOOGLE IF YOU DON’T BELIEVE US
                                </Typography>
                            </Reveal>
                        </div>

                        {/* for mobile */}
                        <div className="col-12 mt-3 text-center d-block d-md-none">
                            <Typography variant="h4" gutterBottom
                                className={'gotham-black fw-bolder px-3'}>
                                <span className={'text-custom-secondary'}>RANKED #1 <br />IN THE UAE</span>
                                <br />
                                NOW, THEN & FOREVER
                            </Typography>
                            <Typography variant="subtitle1" className={'fw-bolder px-md-0 px-3'} gutterBottom>
                                CHECK GOOGLE IF <br /> YOU DON’T BELIEVE US
                            </Typography>
                        </div>
                        <div className="col-md-1 p-0 m-0"></div>


                        {/* Testimonial cards div */}
                        {/* <div className="col-md-12 h-60 mt-3">
                            <Marquee key={1} velocity={5} minScale={0.7}>
                                {googleReview.map((review, id) => (
                                    <Box key={`marquee-example-review-${id}`}
                                        style={{ minWidth: '20em', maxWidth: '30em' }}>
                                        <div className={'card shadow-lg transparent-bg mx-4'}>
                                            <div className="card-body">
                                                <div className="card-header">

                                                    <h5 className="card-title" style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'start'
                                                    }}>
                                                        <Avatar alt="Remy Sharp" src={review?.profile_photo_url} /> <span
                                                            className='ps-2 gotham-medium full-upper-case'>{review?.author_name}</span>
                                                    </h5>

                                                    <Rating name="read-only" value={5} readOnly />
                                                </div>
                                                <p className={'px-3 pt-2 gotham-book'}>
                                                    {review?.text.length > 440 ? `${review?.text.slice(0, 450)}...` : review?.text}
                                                </p>
                                            </div>

                                            <div className="card-footer">
                                                <span
                                                    className='ps-3 gotham-medium'>{review?.relative_time_description}</span>
                                            </div>
                                        </div>

                                    </Box>
                                ))}
                            </Marquee>
                        </div> */}
                    </div>


                    {/* Checkmate Section */}
                    {/* For Laptop */}
                    <div className="row d-none d-md-flex">
                        {/* Space Div */}
                        <div className="col-md-1 col-0"></div>
                        {/* Left Div */}
                        <div
                            className="col-md-5 h-60 d-flex justify-content-center align-items-center order-md-1 order-2">
                            <div className="row">
                                <div className="col-12">
                                    <Reveal left opposite cascade>
                                        <Typography variant='h2' gutterBottom
                                            className={'gotham-black fw-bolder d-none d-md-block'}>
                                            CHECK MATES
                                            <br />
                                            <span className={'text-custom-secondary'}>
                                                BEFORE, DURING & AFTER
                                            </span>
                                        </Typography>
                                    </Reveal>
                                </div>
                                <div className="col-md-10 col-12">
                                    <Reveal left opposite cascade>
                                        <Typography variant='h5' gutterBottom className={'gotham-book px-md-0 px-3'}>
                                            We keep on giving well after the property inspection itself. We help and
                                            consult for
                                            as
                                            long as you need at no extra charge, with whatever it is you need support
                                            with.
                                            We're
                                            not happy until you're happy with your home.
                                            <br /><br />
                                            Call, email, message, even come into our showroom to have coffee and talk
                                            through
                                            anything to do with your property and your home inspection.
                                            <br /><br />
                                            Providing results that protect your money is one thing, but friendly
                                            world-class
                                            customer service is what made Property Check the UAE's #1 ranked company in
                                            the
                                            property
                                            industry.

                                        </Typography>
                                    </Reveal>
                                </div>
                            </div>
                        </div>

                        {/* Right Div */}
                        {/* Laptop Div */}
                        <div
                            className="col-md-6 h-90 d-none d-md-flex justify-content-center align-items-center checkmates-bg order-md-2 order-2">
                            <Reveal right opposite cascade>
                                <ReactFloaterJs>
                                    <img className="img-fluid mx-auto"
                                        src={require('../../../Assets/img/phone/screenshots.png')} />
                                </ReactFloaterJs>
                            </Reveal>
                        </div>
                    </div>

                    {/* Checkmate Section */}
                    {/* For mobile */}
                    <div className="row d-flex d-md-none">
                        {/* Left Div */}
                        <div
                            className="col-12 h-40 d-flex justify-content-center align-items-center order-md-1 order-2">
                            <div className="row">
                                <div className="col-12">
                                    <Reveal left opposite cascade>
                                        <Typography variant='h4' gutterBottom
                                            className={'gotham-black fw-bolder px-3'}>
                                            CHECK MATES
                                            <br />
                                            <span className={'text-custom-secondary'}>
                                                BEFORE, DURING & AFTER
                                            </span>
                                        </Typography>
                                    </Reveal>
                                </div>
                                <div className="col-12">
                                    <Reveal left opposite cascade>
                                        <Typography variant='subtitle1' gutterBottom className={'gotham-book px-md-0 px-3'}>
                                            We keep on giving well after the property inspection itself. We help and
                                            consult for
                                            as
                                            long as you need at no extra charge, with whatever it is you need support
                                            with.
                                            We're
                                            not happy until you're happy with your home.
                                            <br /><br />
                                            Call, email, message, even come into our showroom to have coffee and talk
                                            through
                                            anything to do with your property and your home inspection.
                                            <br /><br />
                                            Providing results that protect your money is one thing, but friendly
                                            world-class
                                            customer service is what made Property Check the UAE's #1 ranked company in
                                            the
                                            property
                                            industry.

                                        </Typography>
                                    </Reveal>
                                </div>
                            </div>
                        </div>

                        {/* Right Div */}
                        {/* Phone Div */}
                        <div
                            className="col-md-6 h-50 d-flex d-md-none justify-content-center align-items-center checkmates-bg order-md-2 order-1">
                            <Reveal right opposite cascade>
                                <ReactFloaterJs>
                                    <img className="img-fluid mx-auto"
                                        src={require('../../../Assets/img/phone/screenshots.png')} />
                                </ReactFloaterJs>
                            </Reveal>
                        </div>
                    </div>


                    {/* No Quotes Section */}
                    <div className="row mt-2 mt-md-5">
                        {/* Space Div */}
                        <div className="col-md-1 col-0"></div>
                        {/* Left Div */}
                        {/* Laptop Div */}
                        <div
                            className="col-md-6 h-90 d-none d-md-flex justify-content-center align-items-center no-quotes-bg">
                            <Reveal left opposite cascade>
                                <ReactFloaterJs>
                                    <img className="img-fluid mx-auto"
                                        src={require('../../../Assets/img/banner/CHECKY PNG WITH OUTLINE.png')} />
                                </ReactFloaterJs>
                            </Reveal>
                        </div>
                        {/* Phone Div */}
                        <div
                            className="col-md-6 h-50 d-flex d-md-none justify-content-center align-items-center no-quotes-bg">
                            <Reveal left opposite cascade>
                                <img className="img-fluid mx-auto hi-15"
                                    src={require('../../../Assets/img/banner/CHECKY PNG WITH OUTLINE.png')} />
                            </Reveal>
                        </div>

                        {/* Right Div */}
                        <div className="col-md-5 h-60 d-flex justify-content-center align-items-center">
                            <div className="row">
                                <div className="col-12">
                                    <Reveal right opposite cascade>
                                        <Typography variant='h2' gutterBottom
                                            className={'gotham-black d-none d-md-block'}>
                                            <span className={'text-custom-secondary'}>
                                                NO DISCOUNTS
                                            </span>
                                            \ <br />
                                            NO MERCY
                                        </Typography>
                                        <Typography variant='h4' gutterBottom
                                            className={'gotham-black px-3 d-block d-md-none'}>
                                            <span className={'text-custom-secondary'}>
                                                NO DISCOUNTS
                                            </span>
                                            <br />
                                            NO MERCY
                                        </Typography>
                                    </Reveal>
                                </div>
                                <div className="col-md-10 col-12">
                                    <Reveal right opposite cascade>
                                        <Typography variant='h5' gutterBottom className={'gotham-book px-md-0 px-3 d-none d-md-block'}>
                                            We pride ourselves on how much time we spend inside your property (more than
                                            anyone else),
                                            the level of detail we go into (more than anyone else on earth) and how much
                                            of your money we
                                            protect with the results (drum roll... it’s more than anyone else).
                                            <br /><br />
                                            It’s why we take limited clients and why they come back over and over again.
                                            We’re a Ferrari
                                            service at a Nissan cost... no one walks into the Ferrari showroom and asks
                                            “give me best
                                            price”.
                                        </Typography>

                                        <Typography variant='subtitle1' gutterBottom className={'gotham-book px-md-0 px-3 d-block d-md-none'}>
                                            We pride ourselves on how much time we spend inside your property (more than
                                            anyone else),
                                            the level of detail we go into (more than anyone else on earth) and how much
                                            of your money we
                                            protect with the results (drum roll... it’s more than anyone else).
                                            <br /><br />
                                            It’s why we take limited clients and why they come back over and over again.
                                            We’re a Ferrari
                                            service at a Nissan cost... no one walks into the Ferrari showroom and asks
                                            “give me best
                                            price”.
                                        </Typography>
                                    </Reveal>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="h-30 d-none d-md-flex"></div>


                    {/* CTA Laptop */}
                    <div className="row h-40 justify-content-center align-items-center cta-bg mb-md-5 d-none d-md-flex">
                        <div className="col-md-7 text-center px-md-5">
                            <Reveal left opposite cascade>
                                <Typography variant="h4" gutterBottom className={'gotham-medium text-start'}>
                                    Now, If you’ve heard enough and want to join the hundreds of homeowners and buyers
                                    who
                                    book
                                    their&nbsp;
                                    <span
                                        className={'text-custom-secondary'}><strong>Property Check</strong></span>&nbsp;
                                    online, you can protect your money and sanity in 30 seconds.
                                </Typography>
                            </Reveal>
                        </div>
                        <div className="col-md-3 text-center">
                            <Reveal right opposite cascade>
                                <AnchorLink href='#leadForm'>
                                    <Button variant="contained" color='primary'
                                        className={'gotham-black px-md-5 pt-md-3 pb-md-2'}>
                                        <h3> GET QUOTE</h3>
                                    </Button>
                                </AnchorLink>
                            </Reveal>
                        </div>
                    </div>

                    {/* CTA Phone */}
                    <div className="row h-20 justify-content-center align-items-center cta-bg mb-md-5 d-flex d-md-none">
                        <div className="col-12 text-center">
                            <Reveal right opposite cascade>
                                <AnchorLink href='#leadForm'>
                                    <Button variant="contained" color='primary' className={'gotham-black px-5 pt-3 pb-2'}>
                                        <h3> GET QUOTE</h3>
                                    </Button>
                                </AnchorLink>
                            </Reveal>
                        </div>
                    </div>


                    <div className="h-10 d-none d-md-flex"></div>

                    {/* Contact Us Section */}
                    <div className="row">
                        {/* Left Div for Map */}
                        {/* Laptop Div */}
                        <div className="col-md-7 h-70 justify-content-center align-items-center d-none d-md-flex">
                            <ReactFloaterJs>
                                <img className="img-fluid mx-auto p-3"
                                    src={require('../../../Assets/img/isometric/map.png')}
                                    style={{ maxHeight: '70vh' }} />
                            </ReactFloaterJs>
                        </div>
                        {/* Mobile Div */}
                        <div className="col-md-7 h-30 justify-content-center align-items-center d-flex d-md-none">
                            <img className="img-fluid mx-auto p-3 hi-15"
                                src={require('../../../Assets/img/isometric/map.png')}
                                style={{ maxHeight: '70vh' }} />
                        </div>

                        {/* Right div for text */}
                        <div className="col-md-5 h-70 d-flex justify-content-center align-items-center">
                            <div className="row">
                                <div className="col-md-12">
                                    <Typography variant='h2' gutterBottom
                                        className={'gotham-black fw-bolder d-none d-md-block'}>
                                        <span className={'text-custom-secondary'}>
                                            DON'T BE SHY
                                        </span>
                                        <br />
                                        STOP ON BY
                                    </Typography>

                                    {/* mobile */}
                                    <Typography variant='h4' gutterBottom
                                        className={'gotham-black fw-bolder d-block d-md-none px-3'}>
                                        <span className={'text-custom-secondary'}>
                                            DON'T BE SHY
                                        </span>
                                        <br />
                                        STOP ON BY
                                    </Typography>
                                </div>

                                <div className="col-md-6 mt-2 mt-md-4">
                                    <div className='row ps-3'>
                                        <Typography variant='h4' gutterBottom
                                            className={'gotham-black fw-bolder text-custom-secondary px-md-0 px-3'}>
                                            VISIT
                                        </Typography>
                                    </div>

                                    <div className="row ps-3">
                                        <Typography variant='subtitle1' gutterBottom
                                            className={'gotham-book px-md-0 px-3'}>
                                            213 Umm Suqeim St
                                            <br /> Dubai
                                            <br />
                                            United Arab Emirates
                                        </Typography>
                                    </div>
                                </div>


                                <div className="col-md-6 mt-2 mt-md-4">
                                    <div className="row ps-3">
                                        <Typography variant='h4' gutterBottom
                                            className={'gotham-black fw-bolder text-custom-secondary px-md-0 px-3'}>
                                            SAY HI
                                        </Typography>
                                    </div>

                                    <div className="row ps-3">
                                        <Typography variant='subtitle1' gutterBottom
                                            className={' gotham-book px-md-0 px-3'}>
                                            <a href="tel:+8002432563" style={{ textDecoration: 'none', color: '#fff' }}>800
                                                CHECK ME (24325 63)</a>
                                            <br />
                                            <a href="mailto:checkmeout@propertycheck.me" style={{
                                                textDecoration: 'none',
                                                color: '#fff'
                                            }}>checkmeout@propertycheck.me</a>
                                        </Typography>
                                    </div>
                                </div>

                                {/* Laptop Div */}
                                <div className="col-md-10 mt-4 d-flex justify-content-center px-3">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14451.33121204832!2d55.2171238!3d25.1075205!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe6d3c79c2c821946!2sProperty%20Check!5e0!3m2!1sen!2s!4v1677103454820!5m2!1sen!2s"
                                        style={{ border: '0', height: '30vh', width: '100%' }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                        title="Google Map"
                                    ></iframe>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="h-10"></div>

                    {/* For laptop */}
                    <div className="row h-10 text-center texture-dark-bg d-none d-md-flex">
                        <a className={'text-decoration-none text-black mt-3'} href={'https://propertycheck.me/'}
                            target={'_blank'}>
                            <svg width="161" height="50" viewBox="0 0 161 50" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_3207_19658)">
                                    <path d="M0.34491 29.59L127.295 29.59V-3.05176e-05L0.34491 -3.05176e-05V29.59Z"
                                        fill="#009540"></path>
                                    <path
                                        d="M8.50488 8.02002H15.0349C18.8949 8.02002 21.5249 9.74002 21.5249 13.18C21.5249 16.68 18.8449 18.55 14.9349 18.55H13.3549V22.53H8.50488V8.02002ZM14.6649 15.13C15.9049 15.13 16.7349 14.57 16.7349 13.53C16.7349 12.47 15.9649 11.93 14.6849 11.93H13.3549V15.17L14.6649 15.13Z"
                                        fill="white"></path>
                                    <path
                                        d="M22.0449 8.02002H29.1349C31.7049 8.02002 33.3449 8.66004 34.3649 9.67004C34.8124 10.1219 35.1609 10.6621 35.3882 11.256C35.6154 11.85 35.7165 12.4848 35.6849 13.12C35.7065 14.0612 35.4456 14.9872 34.9358 15.7786C34.426 16.57 33.6908 17.1905 32.8249 17.56L36.2049 22.53H30.6749L27.9349 18.38H26.9349V22.53H22.0849L22.0449 8.02002ZM28.9949 14.92C30.1549 14.92 30.8549 14.4 30.8549 13.51C30.8549 12.58 30.1349 12.12 29.0149 12.12H26.8949V14.96L28.9949 14.92Z"
                                        fill="white"></path>
                                    <path
                                        d="M36.0254 15.31C36.1275 13.2783 37.0065 11.3636 38.4806 9.9617C39.9547 8.55985 41.9112 7.77808 43.9454 7.77808C45.9796 7.77808 47.9361 8.55985 49.4102 9.9617C50.8843 11.3636 51.7633 13.2783 51.8654 15.31C51.7633 17.3416 50.8843 19.2564 49.4102 20.6582C47.9361 22.0601 45.9796 22.8419 43.9454 22.8419C41.9112 22.8419 39.9547 22.0601 38.4806 20.6582C37.0065 19.2564 36.1275 17.3416 36.0254 15.31ZM46.9654 15.31C46.9883 14.8971 46.9283 14.4838 46.789 14.0945C46.6498 13.7051 46.434 13.3475 46.1544 13.0428C45.8749 12.7381 45.5372 12.4924 45.1613 12.3202C44.7853 12.1479 44.3787 12.0527 43.9654 12.04C43.5539 12.0474 43.1482 12.1378 42.7724 12.3056C42.3966 12.4735 42.0586 12.7155 41.7785 13.017C41.4985 13.3186 41.2821 13.6735 41.1424 14.0606C41.0027 14.4477 40.9425 14.859 40.9654 15.27C40.9425 15.6833 41.0024 16.0971 41.1416 16.4871C41.2808 16.877 41.4964 17.2352 41.7758 17.5407C42.0552 17.8462 42.3928 18.0929 42.7688 18.2662C43.1448 18.4395 43.5516 18.5359 43.9654 18.55C44.3778 18.5426 44.7844 18.4519 45.1609 18.2834C45.5373 18.1149 45.8758 17.8721 46.156 17.5695C46.4363 17.2668 46.6524 16.9107 46.7915 16.5224C46.9306 16.1341 46.9897 15.7217 46.9654 15.31Z"
                                        fill="white"></path>
                                    <path
                                        d="M52.6753 8.02002H59.2053C63.0653 8.02002 65.6953 9.74002 65.6953 13.18C65.6953 16.68 63.0253 18.55 59.1053 18.55H57.5053V22.53H52.6553L52.6753 8.02002ZM58.8353 15.13C60.0753 15.13 60.9053 14.57 60.9053 13.53C60.9053 12.47 60.1353 11.93 58.9053 11.93H57.5053V15.17L58.8353 15.13Z"
                                        fill="white"></path>
                                    <path
                                        d="M66.2148 8.02002H78.5948V12.02H70.9849V13.49H78.0749V16.99H70.9849V18.53H78.6949V22.53H66.2148V8.02002Z"
                                        fill="white"></path>
                                    <path
                                        d="M79.5049 8.02002H86.5949C89.1649 8.02002 90.8049 8.66004 91.8249 9.67004C92.2737 10.1214 92.6237 10.6613 92.8526 11.2552C93.0816 11.8491 93.1845 12.4842 93.1549 13.12C93.177 14.0626 92.9152 14.99 92.4034 15.7818C91.8915 16.5736 91.1534 17.1932 90.2849 17.56L93.6649 22.49H88.1349L85.3949 18.34H84.3949V22.53H79.5049V8.02002ZM86.4549 14.92C87.6149 14.92 88.3149 14.4 88.3149 13.51C88.3149 12.58 87.5949 12.12 86.4749 12.12H84.3549V14.96L86.4549 14.92Z"
                                        fill="white"></path>
                                    <path d="M97.5053 12.12H93.2354V8.02002H106.625V12.12H102.355V22.53H97.5053V12.12Z"
                                        fill="white"></path>
                                    <path
                                        d="M111.505 17.39L106.035 8.02002H111.505L113.995 12.78L116.505 8.02002H121.875L116.395 17.33V22.53H111.505V17.39Z"
                                        fill="white"></path>
                                    <path
                                        d="M8.17516 41.98C8.16535 40.9713 8.35912 39.9709 8.74489 39.0389C9.13066 38.1068 9.7005 37.2621 10.4203 36.5554C11.1401 35.8486 11.9951 35.2944 12.9341 34.9257C13.8731 34.5571 14.8768 34.3817 15.8852 34.41C17.2243 34.3553 18.5516 34.6802 19.714 35.3472C20.8764 36.0142 21.8267 36.9962 22.4552 38.18L18.5051 40.53C18.292 40.008 17.9277 39.5616 17.4592 39.248C16.9906 38.9344 16.439 38.768 15.8751 38.77C15.4854 38.791 15.1039 38.8909 14.7538 39.0634C14.4036 39.2359 14.092 39.4776 13.8378 39.7738C13.5837 40.0701 13.3921 40.4147 13.2748 40.787C13.1575 41.1593 13.1168 41.5515 13.1551 41.94V41.99C13.1551 43.87 14.2751 45.2 15.8751 45.2C16.4589 45.1937 17.0281 45.0171 17.5131 44.6921C17.998 44.367 18.3775 43.9075 18.6052 43.37L22.6052 45.63C21.7786 47.0989 20.4924 48.2556 18.9443 48.9221C17.3962 49.5886 15.672 49.7281 14.0369 49.319C12.4018 48.91 10.9464 47.9751 9.89455 46.6581C8.84268 45.3411 8.25262 43.715 8.21514 42.03L8.17516 41.98Z"
                                        fill="white"></path>
                                    <path
                                        d="M22.9746 34.6799H27.8246V39.7999H32.2646V34.6799H37.1146V49.1899H32.2646V43.9899H27.8246V49.1899H22.9746V34.6799Z"
                                        fill="white"></path>
                                    <path
                                        d="M38.2549 34.6799H50.6349V38.6799H43.0249V40.1499H50.1249V43.6599H43.0249V45.2299H50.7449V49.2299H38.2549V34.6799Z"
                                        fill="white"></path>
                                    <path
                                        d="M50.9945 41.98C50.9847 40.9713 51.1785 39.971 51.5643 39.0389C51.95 38.1068 52.5199 37.2621 53.2397 36.5554C53.9595 35.8487 54.8144 35.2944 55.7534 34.9258C56.6924 34.5571 57.6962 34.3817 58.7045 34.41C60.0451 34.3546 61.374 34.6791 62.5382 35.3461C63.7024 36.0131 64.6544 36.9955 65.2845 38.18L61.2845 40.52C61.0725 39.9972 60.7085 39.5501 60.2396 39.2363C59.7708 38.9226 59.2186 38.7567 58.6545 38.76C58.2647 38.7811 57.8833 38.8809 57.5331 39.0534C57.183 39.2259 56.8714 39.4676 56.6172 39.7639C56.363 40.0601 56.1715 40.4048 56.0542 40.777C55.9368 41.1493 55.8961 41.5415 55.9345 41.93V41.98C55.9345 43.86 57.0545 45.19 58.6545 45.19C59.24 45.1856 59.8114 45.01 60.2982 44.6848C60.7851 44.3597 61.1662 43.8991 61.3945 43.36L65.3945 45.62C64.568 47.0889 63.2818 48.2456 61.7337 48.9121C60.1856 49.5787 58.4614 49.7181 56.8263 49.3091C55.1912 48.9 53.7358 47.9651 52.6839 46.6481C51.6321 45.3311 51.042 43.7051 51.0045 42.02L50.9945 41.98Z"
                                        fill="white"></path>
                                    <path
                                        d="M65.7949 34.6799H70.6549V40.1099L74.7749 34.6799H80.4149L75.3549 40.9799L80.5249 49.1899H74.7349L71.8949 44.5899L70.6549 46.0999V49.1899H65.7949V34.6799Z"
                                        fill="white"></path>
                                    <path d="M1.10449 41.85H127.284" stroke="white" stroke-width="1.48"
                                        stroke-miterlimit="10"></path>
                                    <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                                    <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                                    <path d="M135.705 16.15L142.145 22.59L156.045 8.69995" stroke="white"
                                        stroke-width="1.58"
                                        stroke-miterlimit="10"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_3207_19658">
                                        <rect width="160.33" height="49.5" fill="white"
                                            transform="translate(0.334961)"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <br className={'d-block d-md-none'} />
                            <Typography variant="p" display={'inline'}
                                className={'text-custom-secondary mt-3 mt-md-0'}>&nbsp;&nbsp;2023 Property Check
                                / C H E C K Inspection Services LLC </Typography>
                        </a>
                    </div>

                    {/* For Mobile */}
                    <div className="row h-8 text-center texture-dark-bg d-block d-md-none">
                        <a className={'text-decoration-none text-black mt-3'} href={'https://propertycheck.me/'}
                            target={'_blank'}>
                            <svg width="100" height="50" viewBox="0 0 161 50" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_3207_19658)">
                                    <path d="M0.34491 29.59L127.295 29.59V-3.05176e-05L0.34491 -3.05176e-05V29.59Z"
                                        fill="#009540"></path>
                                    <path
                                        d="M8.50488 8.02002H15.0349C18.8949 8.02002 21.5249 9.74002 21.5249 13.18C21.5249 16.68 18.8449 18.55 14.9349 18.55H13.3549V22.53H8.50488V8.02002ZM14.6649 15.13C15.9049 15.13 16.7349 14.57 16.7349 13.53C16.7349 12.47 15.9649 11.93 14.6849 11.93H13.3549V15.17L14.6649 15.13Z"
                                        fill="white"></path>
                                    <path
                                        d="M22.0449 8.02002H29.1349C31.7049 8.02002 33.3449 8.66004 34.3649 9.67004C34.8124 10.1219 35.1609 10.6621 35.3882 11.256C35.6154 11.85 35.7165 12.4848 35.6849 13.12C35.7065 14.0612 35.4456 14.9872 34.9358 15.7786C34.426 16.57 33.6908 17.1905 32.8249 17.56L36.2049 22.53H30.6749L27.9349 18.38H26.9349V22.53H22.0849L22.0449 8.02002ZM28.9949 14.92C30.1549 14.92 30.8549 14.4 30.8549 13.51C30.8549 12.58 30.1349 12.12 29.0149 12.12H26.8949V14.96L28.9949 14.92Z"
                                        fill="white"></path>
                                    <path
                                        d="M36.0254 15.31C36.1275 13.2783 37.0065 11.3636 38.4806 9.9617C39.9547 8.55985 41.9112 7.77808 43.9454 7.77808C45.9796 7.77808 47.9361 8.55985 49.4102 9.9617C50.8843 11.3636 51.7633 13.2783 51.8654 15.31C51.7633 17.3416 50.8843 19.2564 49.4102 20.6582C47.9361 22.0601 45.9796 22.8419 43.9454 22.8419C41.9112 22.8419 39.9547 22.0601 38.4806 20.6582C37.0065 19.2564 36.1275 17.3416 36.0254 15.31ZM46.9654 15.31C46.9883 14.8971 46.9283 14.4838 46.789 14.0945C46.6498 13.7051 46.434 13.3475 46.1544 13.0428C45.8749 12.7381 45.5372 12.4924 45.1613 12.3202C44.7853 12.1479 44.3787 12.0527 43.9654 12.04C43.5539 12.0474 43.1482 12.1378 42.7724 12.3056C42.3966 12.4735 42.0586 12.7155 41.7785 13.017C41.4985 13.3186 41.2821 13.6735 41.1424 14.0606C41.0027 14.4477 40.9425 14.859 40.9654 15.27C40.9425 15.6833 41.0024 16.0971 41.1416 16.4871C41.2808 16.877 41.4964 17.2352 41.7758 17.5407C42.0552 17.8462 42.3928 18.0929 42.7688 18.2662C43.1448 18.4395 43.5516 18.5359 43.9654 18.55C44.3778 18.5426 44.7844 18.4519 45.1609 18.2834C45.5373 18.1149 45.8758 17.8721 46.156 17.5695C46.4363 17.2668 46.6524 16.9107 46.7915 16.5224C46.9306 16.1341 46.9897 15.7217 46.9654 15.31Z"
                                        fill="white"></path>
                                    <path
                                        d="M52.6753 8.02002H59.2053C63.0653 8.02002 65.6953 9.74002 65.6953 13.18C65.6953 16.68 63.0253 18.55 59.1053 18.55H57.5053V22.53H52.6553L52.6753 8.02002ZM58.8353 15.13C60.0753 15.13 60.9053 14.57 60.9053 13.53C60.9053 12.47 60.1353 11.93 58.9053 11.93H57.5053V15.17L58.8353 15.13Z"
                                        fill="white"></path>
                                    <path
                                        d="M66.2148 8.02002H78.5948V12.02H70.9849V13.49H78.0749V16.99H70.9849V18.53H78.6949V22.53H66.2148V8.02002Z"
                                        fill="white"></path>
                                    <path
                                        d="M79.5049 8.02002H86.5949C89.1649 8.02002 90.8049 8.66004 91.8249 9.67004C92.2737 10.1214 92.6237 10.6613 92.8526 11.2552C93.0816 11.8491 93.1845 12.4842 93.1549 13.12C93.177 14.0626 92.9152 14.99 92.4034 15.7818C91.8915 16.5736 91.1534 17.1932 90.2849 17.56L93.6649 22.49H88.1349L85.3949 18.34H84.3949V22.53H79.5049V8.02002ZM86.4549 14.92C87.6149 14.92 88.3149 14.4 88.3149 13.51C88.3149 12.58 87.5949 12.12 86.4749 12.12H84.3549V14.96L86.4549 14.92Z"
                                        fill="white"></path>
                                    <path d="M97.5053 12.12H93.2354V8.02002H106.625V12.12H102.355V22.53H97.5053V12.12Z"
                                        fill="white"></path>
                                    <path
                                        d="M111.505 17.39L106.035 8.02002H111.505L113.995 12.78L116.505 8.02002H121.875L116.395 17.33V22.53H111.505V17.39Z"
                                        fill="white"></path>
                                    <path
                                        d="M8.17516 41.98C8.16535 40.9713 8.35912 39.9709 8.74489 39.0389C9.13066 38.1068 9.7005 37.2621 10.4203 36.5554C11.1401 35.8486 11.9951 35.2944 12.9341 34.9257C13.8731 34.5571 14.8768 34.3817 15.8852 34.41C17.2243 34.3553 18.5516 34.6802 19.714 35.3472C20.8764 36.0142 21.8267 36.9962 22.4552 38.18L18.5051 40.53C18.292 40.008 17.9277 39.5616 17.4592 39.248C16.9906 38.9344 16.439 38.768 15.8751 38.77C15.4854 38.791 15.1039 38.8909 14.7538 39.0634C14.4036 39.2359 14.092 39.4776 13.8378 39.7738C13.5837 40.0701 13.3921 40.4147 13.2748 40.787C13.1575 41.1593 13.1168 41.5515 13.1551 41.94V41.99C13.1551 43.87 14.2751 45.2 15.8751 45.2C16.4589 45.1937 17.0281 45.0171 17.5131 44.6921C17.998 44.367 18.3775 43.9075 18.6052 43.37L22.6052 45.63C21.7786 47.0989 20.4924 48.2556 18.9443 48.9221C17.3962 49.5886 15.672 49.7281 14.0369 49.319C12.4018 48.91 10.9464 47.9751 9.89455 46.6581C8.84268 45.3411 8.25262 43.715 8.21514 42.03L8.17516 41.98Z"
                                        fill="white"></path>
                                    <path
                                        d="M22.9746 34.6799H27.8246V39.7999H32.2646V34.6799H37.1146V49.1899H32.2646V43.9899H27.8246V49.1899H22.9746V34.6799Z"
                                        fill="white"></path>
                                    <path
                                        d="M38.2549 34.6799H50.6349V38.6799H43.0249V40.1499H50.1249V43.6599H43.0249V45.2299H50.7449V49.2299H38.2549V34.6799Z"
                                        fill="white"></path>
                                    <path
                                        d="M50.9945 41.98C50.9847 40.9713 51.1785 39.971 51.5643 39.0389C51.95 38.1068 52.5199 37.2621 53.2397 36.5554C53.9595 35.8487 54.8144 35.2944 55.7534 34.9258C56.6924 34.5571 57.6962 34.3817 58.7045 34.41C60.0451 34.3546 61.374 34.6791 62.5382 35.3461C63.7024 36.0131 64.6544 36.9955 65.2845 38.18L61.2845 40.52C61.0725 39.9972 60.7085 39.5501 60.2396 39.2363C59.7708 38.9226 59.2186 38.7567 58.6545 38.76C58.2647 38.7811 57.8833 38.8809 57.5331 39.0534C57.183 39.2259 56.8714 39.4676 56.6172 39.7639C56.363 40.0601 56.1715 40.4048 56.0542 40.777C55.9368 41.1493 55.8961 41.5415 55.9345 41.93V41.98C55.9345 43.86 57.0545 45.19 58.6545 45.19C59.24 45.1856 59.8114 45.01 60.2982 44.6848C60.7851 44.3597 61.1662 43.8991 61.3945 43.36L65.3945 45.62C64.568 47.0889 63.2818 48.2456 61.7337 48.9121C60.1856 49.5787 58.4614 49.7181 56.8263 49.3091C55.1912 48.9 53.7358 47.9651 52.6839 46.6481C51.6321 45.3311 51.042 43.7051 51.0045 42.02L50.9945 41.98Z"
                                        fill="white"></path>
                                    <path
                                        d="M65.7949 34.6799H70.6549V40.1099L74.7749 34.6799H80.4149L75.3549 40.9799L80.5249 49.1899H74.7349L71.8949 44.5899L70.6549 46.0999V49.1899H65.7949V34.6799Z"
                                        fill="white"></path>
                                    <path d="M1.10449 41.85H127.284" stroke="white" stroke-width="1.48"
                                        stroke-miterlimit="10"></path>
                                    <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                                    <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                                    <path d="M135.705 16.15L142.145 22.59L156.045 8.69995" stroke="white"
                                        stroke-width="1.58"
                                        stroke-miterlimit="10"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_3207_19658">
                                        <rect width="160.33" height="49.5" fill="white"
                                            transform="translate(0.334961)"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <br className={'d-block d-md-none'} />
                            <Typography variant="p" display={'inline'} style={{ fontSize: '0.7em' }}
                                className={'text-custom-secondary mt-2 mt-md-0'}>2023 Property Check
                                / C H E C K Inspection Services LLC </Typography>
                        </a>
                    </div>

                </div>
            }
        </>
    );
}


export default Landing;
