// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import { Button, Card, CardContent, IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import { log } from "../../../../../Services/LoggerService";
import { getById } from '../../../../../Services/GenericApiService';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function CustombookingformView({ data = null }) {

    // module(s) for api
    const moduleMain = 'custombookingform';

    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    //data handlers
    const [logo, setLogo] = useState(null);

    // ui handlers
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const containerStyle = {
        display: 'flex',
        alignItems: 'center', // Align items vertically in the center
    };

    const textContainerStyle = {
        marginRight: '4em', // Adjust the margin based on your design
    };

    const boxStylePrimary = {
        backgroundColor: data ? data.primaryColor : 'transparent',
        width: '40px', // Set your desired width for the colored box
        height: '40px', // Set your desired height for the colored box
        border: '1px solid #000', // Add border if needed
    };

    const boxStyleSecondary = {
        backgroundColor: data ? data.secondaryColor : 'transparent',
        width: '40px', // Set your desired width for the colored box
        height: '40px', // Set your desired height for the colored box
        border: '1px solid #000', // Add border if needed
    };

    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    const fetchLogo = () => {
        getById(moduleMain + '/media/partners', data.id)
            .then((_res: any) => {
                if (_res.files.length == 0) {
                    setLogo(null)
                }
                else if (_res.files.length != 0) {
                    setLogo(_res.files[0])
                }
            })
            .catch(_err => {
                log(_err)
            })
    }

    // hooks
    useEffect(() => {
        fetchLogo();
    }, []);


    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <Tooltip title={t('common.view')}>
                    <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                        <FontAwesomeIcon className="edit-btn" icon='eye' />
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                onBackdropClick={handleCancelEvent}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('common.recordDetails')}</h1>
                                    <div className="row full-width">


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('custombookingform.name')} data={data.name} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data && (
                                                <div style={containerStyle}>
                                                    <div style={textContainerStyle}>
                                                        <DataDisplayM label={t('custombookingform.viewPrimaryColor')} data={data.primaryColor} />
                                                    </div>
                                                    <div style={boxStylePrimary}></div>
                                                </div>
                                            )}
                                        </div>


                                        <div className="col-md-6">
                                            {data && (
                                                <div style={containerStyle}>
                                                    <div style={textContainerStyle}>
                                                        <DataDisplayM label={t('custombookingform.viewSecondaryColor')} data={data.secondaryColor} />
                                                    </div>
                                                    <div style={boxStyleSecondary}></div>
                                                </div>
                                            )}
                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('custombookingform.tagline')} data={data.tagline} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <>
                                                    <Typography variant="subtitle1" component="div" className="text-muted">
                                                        {t('custombookingform.url')}
                                                    </Typography>
                                                    <a href={`${process.env.REACT_APP_URL_1}partners/${data?.url}`} target="_blank" rel="noopener noreferrer">
                                                        {`${process.env.REACT_APP_URL_1}partners/${data?.url}`}
                                                    </a>
                                                </>
                                            }                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <>
                                                    <Typography variant="subtitle1" component="div" className="text-muted">
                                                        {t('custombookingform.logo')}
                                                    </Typography>
                                                    <img width={250} src={`${process.env.REACT_APP_RESOURCES}partners/${data?.id}/${logo}`} />
                                                </>
                                            }

                                        </div>


                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                        onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default CustombookingformView;
