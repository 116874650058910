// @ts-nocheck
import React, {useEffect} from 'react';
import {useStyles} from './Styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import {Skeleton} from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useTranslation} from "react-i18next";
import {post, getAll} from '../../../../Services/GenericApiService';
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";
import ApexLineChart from "../Chart/Apex/ApexLineChart/ApexLineChart";
import WhereToVoteTwoToneIcon from '@mui/icons-material/WhereToVoteTwoTone';
function Home() {
    const classes = useStyles();

    // module(s) for api
    const moduleMain = 'custombookingformdata';

    // link module(s) for api
    const moduleVisitorCount = 'visitorcount';
    const moduleLandingpage = 'landingpage';

    // translation
    const {t} = useTranslation();

    // data vars
    const [lastThreeDaysData, setLastThreeDaysData] = React.useState([]);
    const [todaysData, setTodaysData] = React.useState([]);
    const [labels, setLabels] = React.useState([]);
    const [leadsCountData, setLeadsCountData] = React.useState([]);
    const [visitorCountData, setVisitorCountData] = React.useState([]);
    const [nextThreeDaysData, setNextThreeDaysData] = React.useState([]);
    const [visitorCount, setVisitorCount] = React.useState({});
    const [leads, setLeads] = React.useState({});

    // ui controls
    const [loading, setLoading] = React.useState(true);

    //format date in yyyy-mm-dd format
    function formatDate(_date) {
        const year = _date.getFullYear();
        const month = String(_date.getMonth() + 1).padStart(2, '0');
        const day = String(_date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // fetch visitorCount
    const fetchVisitorCount = () => {
        setLoading(true); // show loader
        getAll(moduleVisitorCount + '/visitorCount')
            .then((_res: any) => {
                setVisitorCount(_res);
                const val = Object.values(_res);
                setVisitorCountData(val);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch lead counts
    const fetchLeadCount = () => {
        setLoading(true); // show loader
        getAll(moduleLandingpage + '/visitorCount')
            .then((_res: any) => {
                setLeads(_res);
                const label = Object.keys(_res);
                const val = Object.values(_res);
                setLabels(label);
                setLeadsCountData(val);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    //get by last three date
    const fetchLastThreeDays = (_checkDate = true) => {
        setLoading(true); // show loader

        const today = new Date();
        const lastThreeDays = [];

        for (let i = 1; i <= 3; i++) {
            const currentDate = new Date(today);
            currentDate.setDate(today.getDate() - i);
            lastThreeDays.push(formatDate(currentDate));
        }

        let filterPayload = {
            startDate: lastThreeDays[2],
            endDate: lastThreeDays[0],
            page: 0,
            size: 0,
            propertyType: [],
            checkType: []
        }

        post(moduleMain + '/filter', filterPayload)
            .then((_res: any) => {
                // set all data
                setLastThreeDaysData(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    //get by todays date
    const fetchTodays = (_checkDate = true) => {
        setLoading(true); // show loader

        const today = new Date();
        const todayFormatDate = formatDate(today)

        let filterPayload = {
            startDate: todayFormatDate,
            endDate: todayFormatDate,
            page: 0,
            size: 0,
            propertyType: [],
            checkType: []
        }

        post(moduleMain + '/filter', filterPayload)
            .then((_res: any) => {
                // set all data
                setTodaysData(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    //get by next three date
    const fetchNextThreeDays = (_checkDate = true) => {
        setLoading(true); // show loader

        const today = new Date();
        today.setDate(today.getDate() + 1); // Move to tomorrow
        const nextThreeDays = [];

        for (let i = 0; i < 3; i++) {
            const currentDate = new Date(today);
            currentDate.setDate(today.getDate() + i);
            nextThreeDays.push(formatDate(currentDate));
        }

        let filterPayload = {
            startDate: nextThreeDays[0],
            endDate: nextThreeDays[2],
            page: 0,
            size: 0,
            propertyType: [],
            checkType: []
        }

        post(moduleMain + '/filter', filterPayload)
            .then((_res: any) => {
                // set all data
                setNextThreeDaysData(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetchLastThreeDays();
        fetchTodays();
        fetchNextThreeDays();
        fetchVisitorCount();
        fetchLeadCount();
    }, [])

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            <Header/>

            <Sider/>


            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="row">

                    {loading &&
                        <>
                            <div className="col-md-4">
                                <Skeleton className="my-4"/>
                            </div>
                            <div className="col-md-4">
                                <Skeleton className="my-4"/>
                            </div>
                            <div className="col-md-4">
                                <Skeleton className="my-4"/>
                            </div>

                            <div className="col-md-4">
                                <Skeleton animation="wave" variant="text" className={''} height={180}/>
                            </div>
                            <div className="col-md-4">
                                <Skeleton animation="wave" variant="text" className={''} height={180}/>
                            </div>
                            <div className="col-md-4">
                                <Skeleton animation="wave" variant="text" className={''} height={180}/>
                            </div>
                        </>
                    }

                    {!loading &&
                        <>
                            {/* -------------------- Lead Conversion chart ---------------------------- */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="card shadow-lg bg-dark rounded mt-5 pb-5">
                                        <ApexLineChart
                                            yAxisText={'Number Of Visitors'}
                                            xAxistText={'Date'}
                                            chartTitle={'Lead Conversions from Landing Page'}
                                            data={[
                                                {name: 'Visitors Count', data: visitorCountData},
                                                {name: 'Leads Count', data: leadsCountData}]}
                                            labels={labels}
                                            showDataLabels={false}/>
                                    </div>
                                </div>
                            </div>


                            {/* Spacer */}
                            <div className="row h-10"></div>


                            {/* Upcoming Bookings */}
                            <div className="row mt-5">
                                {/* prev booking */}
                                <div className="col-md-4 col-12">
                                    <h3 className='mb-3 text-custom-secondary fw-bolder'> {t('common.prevBooking')}</h3>
                                    <div className={lastThreeDaysData?.length > 0 ? classes.upcomingBookingsCard : 'd-md-none'}>
                                        {lastThreeDaysData?.length > 0 && lastThreeDaysData?.map(_data => (
                                            <Card className={'mb-4'}>
                                                <CardContent>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h6 className={'text-muted'}>{t('home.client')}</h6>
                                                            <h5>{_data.clientName}</h5>
                                                        </div>
                                                        <div className="col-md-6 mt-md-0 mt-2">
                                                            <h6 className={'text-muted'}>{t('home.agent')}</h6>
                                                            <h5>{_data.agentName ? _data.agentName : 'N/A'}</h5>
                                                        </div>
                                                        <div className="col-md-6 mt-md-2 mt-2">
                                                            <h6 className={'text-muted'}>{t('home.visitDate')}</h6>
                                                            <h5>{new Date(_data.visitDate).toLocaleDateString("en-US", {
                                                                month: 'short',
                                                                day: 'numeric',
                                                                year: 'numeric'
                                                            })}</h5>
                                                        </div>
                                                        <div className="col-md-6 mt-md-2 mt-2">
                                                            <h6 className={'text-muted'}>{t('home.visitSlot')}</h6>
                                                            <h5>{_data.visitSlot}</h5>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                    {lastThreeDaysData?.length === 0 &&
                                        <Card sx={{minWidth: 275, marginBottom: '2em'}}>
                                            <CardContent>
                                                <h4 className={'text-center mt-1'}>
                                                    <WhereToVoteTwoToneIcon color='primary' />
                                                    &nbsp; No Bookings
                                                </h4>
                                            </CardContent>
                                        </Card>
                                    }
                                </div>

                                {/* today booking */}
                                <div className="col-md-4 col-12">
                                    <h3 className='mb-3 text-custom-secondary fw-bolder'> {t('common.todayBooking')}</h3>
                                    <div className={todaysData?.length > 0 ? classes.upcomingBookingsCard : 'd-md-none'}>
                                        {todaysData?.length > 0 && todaysData?.map(_data => (
                                            <Card className={'mb-4'}>
                                                <CardContent>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h6 className={'text-muted'}>{t('home.client')}</h6>
                                                            <h5>{_data.clientName}</h5>
                                                        </div>
                                                        <div className="col-md-6 mt-md-0 mt-2">
                                                            <h6 className={'text-muted'}>{t('home.agent')}</h6>
                                                            <h5>{_data.agentName ? _data.agentName : 'N/A'}</h5>
                                                        </div>
                                                        <div className="col-md-6 mt-md-2 mt-2">
                                                            <h6 className={'text-muted'}>{t('home.visitDate')}</h6>
                                                            <h5>{new Date(_data.visitDate).toLocaleDateString("en-US", {
                                                                month: 'short',
                                                                day: 'numeric',
                                                                year: 'numeric'
                                                            })}</h5>
                                                        </div>
                                                        <div className="col-md-6 mt-md-2 mt-2">
                                                            <h6 className={'text-muted'}>{t('home.visitSlot')}</h6>
                                                            <h5>{_data.visitSlot}</h5>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                    {todaysData?.length === 0 &&
                                        <Card sx={{minWidth: 275, marginBottom: '2em'}}>
                                            <CardContent>
                                                <h4 className={'text-center mt-1'}>
                                                    <WhereToVoteTwoToneIcon color='primary' />
                                                    &nbsp; No Bookings
                                                </h4>
                                            </CardContent>
                                        </Card>
                                    }
                                </div>

                                {/* next 3days bookings */}
                                <div className="col-md-4 col-12">
                                    <h3 className='mb-3 text-custom-secondary fw-bolder'> {t('common.nextBooking')}</h3>
                                    <div className={nextThreeDaysData?.length > 0 ? classes.upcomingBookingsCard : 'd-md-none'}>
                                        {nextThreeDaysData?.length > 0 && nextThreeDaysData?.map(_data => (
                                            <Card className={'mb-4'}>
                                                <CardContent>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h6 className={'text-muted'}>{t('home.client')}</h6>
                                                            <h5>{_data.clientName}</h5>
                                                        </div>
                                                        <div className="col-md-6 mt-md-0 mt-2">
                                                            <h6 className={'text-muted'}>{t('home.agent')}</h6>
                                                            <h5>{_data.agentName ? _data.agentName : 'N/A'}</h5>
                                                        </div>
                                                        <div className="col-md-6 mt-md-2 mt-2">
                                                            <h6 className={'text-muted'}>{t('home.visitDate')}</h6>
                                                            <h5>{new Date(_data.visitDate).toLocaleDateString("en-US", {
                                                                month: 'short',
                                                                day: 'numeric',
                                                                year: 'numeric'
                                                            })}</h5>
                                                        </div>
                                                        <div className="col-md-6 mt-md-2 mt-2">
                                                            <h6 className={'text-muted'}>{t('home.visitSlot')}</h6>
                                                            <h5>{_data.visitSlot}</h5>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </div>
                                    {nextThreeDaysData?.length === 0 &&
                                        <Card sx={{minWidth: 275, marginBottom: '2em'}}>
                                            <CardContent>
                                                <h4 className={'text-center mt-1'}>
                                                    <WhereToVoteTwoToneIcon color='primary' />
                                                    &nbsp; No Bookings
                                                </h4>
                                            </CardContent>
                                        </Card>
                                    }
                                </div>
                            </div>


                        </>
                    }

                </div>


            </Box>
        </Box>
    );
}

export default Home;
