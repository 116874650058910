// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { getAll, deleteById } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import BookingForm from '../BookingForm/BookingForm';
import BookingView from '../BookingView/BookingView';
import { log } from "../../../../../Services/LoggerService";
import { formatData } from "../../../../../Services/DataFormatService";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { IconButton, Skeleton, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function BookingMain() {

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'booking';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});

    let columns = [
        { name: "Client Email", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Check Type", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Property Type", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Visit Slot", options: { filter: true, filterType: 'checkbox', filterOptions: { fullWidth: true } } },
        { name: "Area", options: { filter: true, filterOptions: { fullWidth: true } } },
        { name: "Visit Date", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Inspector", options: { filter: false, filterOptions: { fullWidth: true } } },

        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <Tooltip title={t('common.edit')}>
                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                        onEditClick(dataIndex, rowIndex)
                                    }}>
                                        <FontAwesomeIcon className="edit-btn" icon='edit' />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div className="px-2">
                                {allData &&
                                    <BookingView data={allData[rowIndex]} />
                                }
                            </div>

                        </div>
                    )
                        ;
                }
            }
        }
    ];
    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [addMode, setAddMode] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    // fetch dependent data
    const fetch = () => {
        setAddMode(false);
        setEditMode(false);
        setTableData([]);  // reset table data
        setLoading(true); // show loader
        getAll(moduleMain, currentPage, pageSize)
            .then((_res: any) => {
                // set all data
                setAllData(_res.rows)

                // set total count
                setTotalRowCount(_res.count);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.clientEmail, _record?.bookingChecktype?.name, _record?.bookingPropertytype?.name, _record?.visitSlot, _record?.area, _record?.visitDate, _record?.bookingBookinginspector.length > 0 ? _record?.bookingBookinginspector.map(item => item.bookinginspectorUser.email).join(' , '):""]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setDataToEdit(allData[_dataIndex]);
        setEditMode(true);
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }

    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => { setCurrentPage(currentPage); },
        onChangeRowsPerPage: (numberOfRows) => { setPageSize(numberOfRows); },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                {addMode &&
                                    t('common.add') + " " + t('module.booking')
                                }
                                {editMode &&
                                    t('common.edit') + " " + t('module.booking')
                                }
                                {!addMode && !editMode &&
                                    t('module.booking')
                                }
                            </h2>
                        </div>

                        {!addMode && !editMode &&
                            <div className="col-md-2">
                                <Button
                                    variant="contained"
                                    onClick={() => { setAddMode(true) }}
                                    className={'bg-custom-gradient my-2 py-3'}
                                    fullWidth>
                                    {t('common.add')}
                                </Button>
                            </div>
                        }

                        {(editMode || addMode) &&
                            <div className="col-md-2">
                                <Button
                                    variant="contained"
                                    onClick={() => { setAddMode(false); setEditMode(false) }}
                                    className={'bg-custom-gradient my-2 py-3'}
                                    fullWidth>
                                    {t('common.back')}
                                </Button>
                            </div>
                        }

                    </div>

                    {!addMode && !editMode &&
                        <div className="mt-5">
                            <Paper elevation={12}>
                                <MUIDataTable
                                    title={t('common.allRecords')}
                                    data={tableData}
                                    columns={columns}
                                    // @ts-ignore
                                    options={options}
                                />
                            </Paper>
                        </div>
                    }

                </div>

                {/* display add form */}
                {addMode &&
                    <div className="mt-5">
                        <BookingForm
                            add={true}
                            cancelEvent={fetch}
                        />
                    </div>
                }

                {/* display edit form */}
                {editMode &&
                    <div className="mt-5">
                        <BookingForm cancelEvent={fetch}
                            recordToUpdate={dataToEdit} />
                    </div>
                }



                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box>
    );
}

export default BookingMain;
