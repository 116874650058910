// @ts-nocheck  
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getAll } from '../../../../../Services/GenericApiService';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, FormControl, TextField, InputLabel, MenuItem, FormHelperText } from "@mui/material";
import Select from '@mui/material/Select';
import LoadingButton from "@mui/lab/LoadingButton";
import {log} from "../../../../../Services/LoggerService";

// @ts-ignore
function PropertyTypeForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {

    // translation
    const { t } = useTranslation();

    // module(s) for api
    const moduleMain = 'propertytype';

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    //data 
    const [allParent, setAllParent] = useState([]);

    // form methods
    const onSubmit = (_data: any) => {
        if(_data.parentId == 0 || _data.parentId == "") {
            _data.parentId = null;
        }
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // get all parent
    const getAllParent = () => {
        getAll(moduleMain+'/parent')
            .then((_res: any) => {
                // set all data
                setAllParent(_res)
            })
            .catch(_err => {
                log(_err)
            })
    }

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // hooks
    useEffect(() => {
        getAllParent();
        if (isEditMode) {
            reset(recordToUpdate);
        }
    }, []);
    
    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.propertyType')}
                                    </h3>

                                </div>



                                {/* Field Name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                            {...register("name", { required: true })}
                                            label={t('propertyType.name')}
                                            type={"text"}
                                            error={!!errors.name}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Parent Name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="parentIdLabel">
                                            {t('propertyType.parent')}
                                        </InputLabel>
                                        {allParent.length > 0 &&
                                            <Select labelId="parentIdLabel"
                                                id="parentIdSelect"
                                                {...register("parentId", { required: false })}
                                                error={!!errors.checkType}
                                                value={watch("parentId")}
                                                label={t('propertyType.parent')}>
                                                {
                                                    allParent.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allParent.length == 0 &&
                                            <>
                                                <Select labelId="parentIdLabel"
                                                    disabled
                                                    id="parentIdSelect"
                                                    label={t('propertyType.parent')}>
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default PropertyTypeForm;
