import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { HexColorPicker } from "react-colorful";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, FormControl, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadM from "../../../../Helpers/FileUploadM/FileUploadM";


// @ts-ignore
function CustombookingformForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {

    // router object
    const navigate = useNavigate();

    // translation
    const { t } = useTranslation();

    //data handlers
    const [pcolor, setPColor] = useState("#00008B");
    const [scolor, setSColor] = useState("#ffa500");
    const [bcolor, setBColor] = useState("#191917");
    const [tcolor, setTColor] = useState("#ffffff");

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        _data.primaryColor = pcolor;
        _data.secondaryColor = scolor;
        _data.backgroundColor = bcolor;
        _data.textColor = tcolor;
        _data.url = _data.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        _data.name = _data.name.replace(/[^a-zA-Z0-9\s]/g, '');

        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // data vars


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // hooks
    useEffect(() => {
        if (isEditMode) {
            setPColor(recordToUpdate.primaryColor)
            setSColor(recordToUpdate.secondaryColor);
            reset(recordToUpdate);
        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.custombookingform')}
                                    </h3>

                                </div>

                                {/* Field name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                            {...register("name", { required: false })}
                                            label={t('custombookingform.name')}
                                            type={"text"}
                                            error={!!errors.name}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>


                                {/* Field tagline */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="taglineInput"
                                            {...register("tagline", { required: false })}
                                            label={t('custombookingform.tagline')}
                                            type={"text"}
                                            error={!!errors.tagline}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Primary Color */}
                                <div className="col-md-6 mt-4">
                                    <div className="row">
                                        <div className="col-md-6 text-start">
                                            {t('custombookingform.primaryColor')}
                                        </div>
                                        <div className="col-md-6 text-end">
                                            Hex Code: {pcolor}
                                        </div>
                                    </div>
                                    <HexColorPicker color={pcolor} onChange={setPColor} />
                                    <div className='mt-3'>
                                        <TextField
                                            id="primaryColorInput"
                                            label={t('custombookingform.pcHC')}
                                            type="text"
                                            value={pcolor}
                                            onChange={(event) => setPColor(event.target.value)}
                                            variant="outlined"
                                            className="full-width"
                                        />
                                    </div>
                                </div>

                                {/* Secondary Color */}
                                <div className="col-md-6 mt-4">
                                    <div className="row">
                                        <div className="col-md-6 text-start">
                                            {t('custombookingform.secondaryColor')}
                                        </div>
                                        <div className="col-md-6 text-end">
                                            Hex Code: {scolor}
                                        </div>
                                    </div>

                                    <HexColorPicker color={scolor} onChange={setSColor} />

                                    <div className='mt-3'>
                                        <TextField
                                            id="secondaryColorInput"
                                            label={t('custombookingform.scHC')}
                                            type="text"
                                            value={scolor}
                                            onChange={(event) => setSColor(event.target.value)}
                                            variant="outlined"
                                            className="full-width"
                                        />
                                    </div>
                                </div>

                                {/* Background Color */}
                                <div className="col-md-6 mt-4">
                                    <div className="row">
                                        <div className="col-md-6 text-start">
                                            {t('custombookingform.backgroundColor')}
                                        </div>
                                        <div className="col-md-6 text-end">
                                            Hex Code: {bcolor}
                                        </div>
                                    </div>
                                    <HexColorPicker color={bcolor} onChange={setBColor} />
                                    <div className='mt-3'>
                                        <TextField
                                            id="primaryColorInput"
                                            label={t('custombookingform.bcHC')}
                                            type="text"
                                            value={bcolor}
                                            onChange={(event) => setBColor(event.target.value)}
                                            variant="outlined"
                                            className="full-width"
                                        />
                                    </div>
                                </div>

                                {/* Text Color */}
                                <div className="col-md-6 mt-4">
                                    <div className="row">
                                        <div className="col-md-6 text-start">
                                            {t('custombookingform.textColor')}
                                        </div>
                                        <div className="col-md-6 text-end">
                                            Hex Code: {tcolor}
                                        </div>
                                    </div>
                                    <HexColorPicker color={tcolor} onChange={setTColor} />
                                    <div className='mt-3'>
                                        <TextField
                                            id="primaryColorInput"
                                            label={t('custombookingform.tcHC')}
                                            type="text"
                                            value={tcolor}
                                            onChange={(event) => setTColor(event.target.value)}
                                            variant="outlined"
                                            className="full-width"
                                        />
                                    </div>
                                </div>

                                {/* Field logo */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        Upload Your Logo
                                        {/*@ts-ignore */}
                                        <FileUploadM buttonText={'Upload Logo'} multipleFiles={false} />
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default CustombookingformForm;
