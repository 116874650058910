// @ts-nocheck


import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function CustomFooter({ colorCode }) {
    return (
        <>
            <p>
                <a className={'text-decoration-none text-muted'} href={'https://propertycheck.me/'}
                    target={'_blank'}>
                    <div style={{ border: `1px solid ${colorCode.primary}`, backgroundColor:colorCode.primary, padding: '0.2em', borderRadius: '0.2em', display: 'inline-block' }}>
                        <img src={process.env.REACT_APP_URL + '/assets/img/loginLogo.svg'} alt="Powered By" className="img-fluid mr-2" style={{ height: '1.8em' }} />
                    </div>
                    <span>&nbsp;&nbsp;Powered By Property Check</span>
                </a>
            </p>
        </>
    );
}

export default CustomFooter;
