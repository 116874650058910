// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import '../../../../../../index.scss';
import { RootStateOrAny, useSelector } from "react-redux";
import { getAll, post } from "../../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../../Helpers/AlertM/AlertM';
import { log } from "../../../../../../Services/LoggerService";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { Button, Skeleton, TextField, FormControl, FormHelperText } from "@mui/material";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { formatData } from "../../../../../../Services/DataFormatService";


// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function InspectorAvailabilityMain() {
    // translation
    const { t } = useTranslation();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleUser = 'user';
    const moduleInspectorAttributes = 'inspectorattributes';

    // data vars
    const [tableData, setTableData] = React.useState([]);
    const [allData, setAllData] = React.useState([]);
    const [fromDate, setFromDate] = useState(new Date());
    const [selectedTimeSlots, setSelectedTimeSlots] = useState([
        '8 AM - 12 PM',
        '9 AM - 1 PM',
        '2 PM - 6 PM',
    ]);
    const [allInspector, setAllInspector] = useState([]);
    const [selectedInspector, setSelectedInspector] = useState([]);
    const [timeSlots, setTimeSlots] = useState([
        '8 AM - 12 PM',
        '9 AM - 1 PM',
        '2 PM - 6 PM',
    ]);

    let columns = [
        { name: "Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Email", options: { filterOptions: { fullWidth: true } } },
        { name: "Working Days/Week", options: { filterOptions: { fullWidth: true } } },
        { name: "Slot Per Day", options: { filterOptions: { fullWidth: true } } },
        { name: "Booked Slots", options: { filterOptions: { fullWidth: true } } },
        { name: "Available Slots", options: { filterOptions: { fullWidth: true } } },
        { name: "Available Time", options: { filterOptions: { fullWidth: true } } },

    ];

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleVisitSlotChange = (event) => {
        setSelectedTimeSlots(event.target.value)
    }

    const handleInspectorChange = (event) => {
        setSelectedInspector(event.target.value);
    }

    function dateFormat(originalDate) {
        const year = originalDate.getFullYear();
        const month = ('0' + (originalDate.getMonth() + 1)).slice(-2);
        const day = ('0' + originalDate.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }

    const fetchInspectors = () => {
        setLoading(true);
        getAll(moduleUser + '/inspector')
            .then((_res: any) => {
                setAllInspector(_res);
                setLoading(false);
            })
            .catch(_err => {
                log(_err);
                setLoading(false);
            })
    }

    // fetch dependent data
    const fetch = (_check = true) => {

        const dateIndex = new Date(fromDate);
        let dayIndex = (dateIndex.getDay() + 6) % 7 + 1; // Monday as 1, Sunday as 7

        let payload = {
            startDate: dateFormat(fromDate),
            timeSlots: [
                '8 AM - 12 PM',
                '9 AM - 1 PM',
                '2 PM - 6 PM',
            ],
            inspectorId: [],
            dayIndex: dayIndex
        }

        if (selectedInspector) {
            payload['inspectorId'] = selectedInspector
        }

        if (selectedTimeSlots) {
            payload['timeSlots'] = selectedTimeSlots
        }

        setTableData([]);  // reset table data
        setLoading(true); // show loader

        post(moduleInspectorAttributes + '/filter', payload)
            .then((_res: any) => {
                // set all data
                setAllData(_res)

                // set total count
                // setTotalRowCount(_res.count);

                const rows: any = [];
                _res.forEach((_record: any) => {
                    rows.push(formatData([_record['inspectorAttributesUser.firstName']+" "+_record['inspectorAttributesUser.lastName'], 
                    _record['inspectorAttributesUser.email'],_record?.workDaysPerWeek,
                    _record?.slotsPerDay, _record?.bookedSlotCount, _record?.remainingSlot, _record?.slotsAvailable.join(', ')]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetchInspectors();
    }, [currentPage, pageSize])

    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: true,
        // filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: false,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);---------------------
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box>
            <>

                {/* Upper Section */}
                <div className={(theme == 'dark' ? 'bg-dark' : '') + " container-fluid card shadow-lg mt-4"}>
                    <div className="card-body">
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <h3>
                                    Filters
                                </h3>
                            </div>
                        </div>
                        <div className="row mt-3">
                            {!loading &&
                                <>
                                    <div className="col-md-3">
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    value={fromDate}
                                                    onChange={handleFromDateChange}
                                                    minDate={new Date()}
                                                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                    label="Date"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </div>

                                    {/* Field visitSlot */}
                                    <div className="col-md-3">
                                        <FormControl fullWidth>
                                            <InputLabel id="visitSlotInput">
                                                {t('booking.visitSlot')}
                                            </InputLabel>
                                            {timeSlots.length > 0 &&
                                                <Select labelId="visitSlotInput"
                                                    id="visitSlotInput"
                                                    value={selectedTimeSlots}
                                                    multiple
                                                    onChange={handleVisitSlotChange}
                                                    label={t('inspectorManagementMain.visitSlot')}>
                                                    {
                                                        timeSlots.map((el: any) =>
                                                            <MenuItem value={el}
                                                                key={el}> {el}
                                                            </MenuItem>)
                                                    }
                                                </Select>
                                            }
                                            {timeSlots.length == 0 &&
                                                <>
                                                    <Select labelId="locationIdLabel"
                                                        disabled
                                                        id="visitSlotInput"
                                                        label={t('inspectorManagementMain.visitSlot')}>
                                                        <MenuItem value={''}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            }
                                        </FormControl>
                                    </div>

                                    {/* inspector */}
                                    <div className="col-md-3">
                                        <FormControl fullWidth={true}>
                                            <InputLabel id="inpsectorLabel">
                                                {t('inspectorManagementMain.inspector')}
                                            </InputLabel>
                                            {allInspector.length > 0 &&
                                                <Select labelId="checkTypeLabel"
                                                    id="checkTypeSelect"
                                                    value={selectedInspector}
                                                    onChange={handleInspectorChange}
                                                    multiple
                                                    label={t('inspectorManagementMain.inspector')}>
                                                    {
                                                        allInspector.map((el: any) =>
                                                            <MenuItem value={el.id}
                                                                key={el.id}> {el.email}
                                                            </MenuItem>)
                                                    }
                                                </Select>
                                            }
                                            {allInspector.length == 0 &&
                                                <>
                                                    <Select labelId="checkTypeLabel"
                                                        disabled
                                                        id="checkTypeSelect"
                                                        label={t('inspectorManagementMain.inspector')}>
                                                        <MenuItem value={''}>
                                                            No Data
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        No Data
                                                    </FormHelperText>
                                                </>
                                            }
                                        </FormControl>

                                    </div>

                                    <div className="col-md-3">
                                        <Button
                                            variant="contained"
                                            onClick={fetch}
                                            className={'bg-custom-gradient py-3'}
                                            fullWidth>
                                            {t('common.filters')}
                                        </Button>
                                    </div>
                                </>
                            }
                            {loading &&
                                <>
                                    <div className="col-md-12"> <Skeleton className="my-2" /></div>
                                    <div className="col-md-12"> <Skeleton className="my-2" /></div>
                                </>
                            }

                        </div>
                    </div>
                </div>

                <div className="mt-5">
                    <Paper elevation={12}>
                        <MUIDataTable
                            title={t('common.allRecords')}
                            data={tableData}
                            columns={columns}
                            // @ts-ignore
                            options={options}
                        />
                    </Paper>
                </div>

            </>

            {/* Alerts */}
            <AlertM ref={notifications} />

        </Box>
    );
}

export default InspectorAvailabilityMain;
