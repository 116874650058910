import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {Button, FormControl, TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";


// @ts-ignore
function RoleForm({handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent}) {

    // translation
    const {t} = useTranslation();

    // form data
    const {register, handleSubmit, watch, reset, setValue, formState: {errors}} = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode){
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // hooks
    useEffect(() => {
        if (isEditMode) {
            reset(recordToUpdate);            
        }
    }, []);


    return (
        <Box sx={{display: 'flex'}}>
            <Card sx={{width: '75vw', maxHeight: '90vh', overflowY: 'auto'}}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.role')}
                                    </h3>

                                </div>


                                
                                {/* Field title */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="titleInput"
                                                   {...register("title", {required: true})}
                                                   label={t('role.title')}
                                                   type={"text"}
                                                   error={!!errors.title}
                                                   variant="outlined"
                                                   className="full-width"/>
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{width: '100%', textAlign: 'right'}}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                    onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                           type="submit"
                                           variant="contained"
                                           className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default RoleForm;
