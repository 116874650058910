import React from 'react';
import './Booked.css';
import '../../../index.scss';
import {Typography} from "@mui/material";
import {Typewriter} from 'react-simple-typewriter'
import ReactFloaterJs from 'react-floaterjs'

const Booked = () => {
    return (
        <div className={'w-100 overflow-hidden'}>
            <div className="row">
                {/* Left Div */}
                <div className="col-md-6 justify-content-center align-items-center order-1 order-md-0 mt-md-0 mt-3">
                    <div className="row">
                        {/* Spacer */}
                        <div className="col-md-2 col-0 order-2 order-md-0"></div>

                        {/* Content Div */}
                        <div
                            className="col-md-9 col-12 d-flex justify-content-start align-items-center h-100 order-1 order-md-1 px-md-0 px-3">
                            <div className="row">
                                {/* Laptop Div */}
                                <div className="col-12 d-none d-md-flex">
                                    <Typography variant="h2" gutterBottom className={'fw-bolder '} display="block">
                                        <span className={'text-custom-secondary'}>
                                              <Typewriter
                                                  words={["PROTECTION & PEACE OF MIND INCOMING"]}
                                                  loop={1}
                                                  cursor
                                                  cursorStyle='_'
                                                  typeSpeed={70}
                                                  deleteSpeed={50}
                                                  delaySpeed={1000}
                                              />
                                        </span>
                                    </Typography>
                                </div>

                                {/* Phone Div */}
                                <div className="col-12 d-flex d-md-none px-4 px-md-0">
                                    <Typography variant="h4" gutterBottom className={'fw-bolder '} display="block">
                                        <span className={'text-custom-secondary'}>
                                              <Typewriter
                                                  words={["PROTECTION & PEACE OF MIND INCOMING"]}
                                                  loop={1}
                                                  cursor
                                                  cursorStyle='_'
                                                  typeSpeed={70}
                                                  deleteSpeed={50}
                                                  delaySpeed={1000}
                                              />
                                        </span>
                                    </Typography>
                                </div>
                                <div className="col-12 px-4 px-md-0">
                                    <Typography variant="subtitle1" display="block">
                                        Congratulations! You’re almost safe.
                                        <br/><br/>
                                        You’ll have received a WhatsApp notification and one of our Check Heroes
                                        will be in touch very, very shortly to give next steps on us partnering
                                        together to protect your money and sanity.
                                        <br/><br/>
                                        In the meantime, listen to the latest single from the Property Check
                                        recording studio here, co-written by Taylor Swift:
                                    </Typography>
                                </div>
                                <div className="col-12 mt-md-4 mt-3 px-md-0 px-4">
                                    <iframe width="100%" height="315"
                                            src="https://www.youtube.com/embed/GeQTVYgCNmk?si=gMxBPPKkWPDO36ul&amp;start=1"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen></iframe>
                                </div>
                            </div>

                        </div>


                        {/* Spacer */}
                        <div className="col-md-1 col-0 order-0 order-md-2"></div>
                    </div>
                </div>

                {/* Right Div */}
                <div className="col-md-6 d-flex justify-content-center align-items-center order-0 order-md-1">
                    <ReactFloaterJs>
                        <img className="img-fluid " style={{maxHeight: '95vh'}}
                             src={require('../../../Assets/img/banner/CHECKY PNG WITH OUTLINE.png')}/>
                    </ReactFloaterJs>
                </div>
            </div>
        </div>
    );
};

export default Booked;
