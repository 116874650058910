// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useStyles } from './Styles';
import { post, getAll, getById, patch } from "../../../../Services/GenericApiService";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../Services/LoggerService";
import { Typewriter } from 'react-simple-typewriter'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomFooter from '../CustomFooter/CustomFooter';
import {
    Button,
    FormControl,
    TextField,
    Tabs,
    Tab,
    Skeleton,
    Autocomplete,
    Typography, FormHelperText
} from "@mui/material";
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import WhiteLabelledFormBookingSummary from "../WhiteLabelledFormBookingSummary/WhiteLabelledFormBookingSummary";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Slider from '@mui/material/Slider';
import Alert from '@mui/material/Alert';
import NotFoundM from "../../Error/NotFoundM/NotFoundM";
import SpecialProperty from '../SpecialProperty/SpecialProperty';
import Success from '../Success/Success';
import { switchTheme } from '../../../../Global/Actions';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setPageColor } from '../../../../Global/Actions';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled, lighten, darken } from '@mui/system';

const Reveal = require("react-reveal/Reveal")

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

// @ts-ignore
function WhiteLabelledFormMain() {
    // css for module
    const classes = useStyles();

    // Dispatcher
    const dispatch = useDispatch()

    // module(s) for api
    const moduleMain = 'custombookingformdata';

    // linked components API modules (endpoints)
    const moduleCustombookingform = 'custombookingform';
    const moduleEnvironmentVaribales = 'environmentvariables';
    const moduleIntegrations = 'integration';
    const moduleChecktype = 'checktype';
    const country = 'country';

    // translation
    const { t } = useTranslation();

    // get custom url from parameters
    const { url } = useParams();

    dispatch(switchTheme('light'));

    const pageColor = useSelector(
        (state: RootStateOrAny) => state.global.global.pageColor,
    );

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // data vars
    const [areas, setAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState('');
    const [communities, setCommunities] = useState([]);
    const [selectedCommunity, setSelectedCommunity] = useState('');
    const [bookingFor, setBookingFor] = useState('');
    const [successName, setSuccessName] = useState('');
    const [allCustombookingform, setAllCustombookingform] = useState([]);
    const [isCheckedAddreinspection, setIsCheckedAddreinspection] = useState(false);
    const [selectedVisitdate, setSelectedVisitdate] = useState<Date | null>(null);
    const [partnerId, setPartnerId] = useState(null);
    const [selectedCheckType, setSelectedCheckType] = useState<String | null>(null);
    const [propertyType, setPropertyType] = useState('apartment');
    const [bedrooms, setBedrooms] = useState<number>(1);
    const [logoSrc, setLogoSrc] = useState(null);
    const [createRecordId, setCreateRecordId] = useState(null);
    const [visitSlotChip, setVisitSlotChip] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [availableVisitSlot, setAvailableVisitSlot] = useState(null);
    const [checkTypes, setCheckTypes] = useState([]);
    const [avgSaving, setAvgSaving] = useState({});
    const [allCountries, setAllCountries] = useState([]);
    const [selectedAgentCountryCode, setSelectedAgentCountryCode] = useState(971);
    const [selectedClientCountryCode, setSelectedClientCountryCode] = useState(971);
    const [houseImg, setHouseImg] = useState('apartment_1_Bed.png');
    const [daysOfWeek, setDaysOfWeek] = useState(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']);
    const [timeSlots, setTimeSlots] = useState({
        '8 AM - 12 PM': true,
        '9 AM - 1 PM': true,
        '2 PM - 6 PM': true,
    });
    // Get the current date
    const currentDate = new Date();

    const savingsMap = {
        'apartment': {
            0: '999 AED',
            1: '1,499 AED',
            2: '1,999 AED',
            3: '2,499 AED',
            4: '2,999 AED',
            5: '3,499 AED',
            6: 'CALL US'
        },
        'villa': {
            0: '10,000 AED',
            1: '20,000 AED',
            2: '20,000 AED',
            3: '30,000 AED',
            4: '40,000 AED',
            5: '50,000 AED',
            6: '60,000 AED'
        }
    }

    // fetch Custombookingform
    const fetchCheckType = () => {
        setLoading(true); // show loader
        getAll(moduleChecktype)
            .then((_res: any) => {
                setCheckTypes(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    function getNameById(id) {
        const item = checkTypes.find(item => item.id === id);
        return item ? item.name : ""; // Return the name if found, or a default message if not found
    }

    // fetch Custombookingform
    const fetchAvgSaving = () => {
        setLoading(true); // show loader
        getById(moduleEnvironmentVaribales, 'avg_saving')
            .then((_res: any) => {
                let jsonObject = JSON.parse(_res.value);
                setAvgSaving(jsonObject);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // ui handlers
    const [loadAreas, setLoadAreas] = useState(false);
    const [loadCommunities, setLoadCommunities] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [themeColors, setThemeColors] = useState({});
    const [partnerNotFound, setPartnerNotFound] = useState(false);
    const [showPropertyCheckReportCard, setShowPropertyCheckReportCard] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [chipError, setChipError] = useState('');
    const [isAgentOpen, setIsAgentOpen] = useState(false);
    const [isClientOpen, setIsClientOpen] = useState(false);

    const handleAgentCountryCodeChange = (event) => {
        setSelectedAgentCountryCode(event.target.value);
    };

    const handleAgentOpen = () => {
        setIsAgentOpen(true);
    };

    const handleAgentClose = () => {
        setIsAgentOpen(false);
    };

    const handleClientCountryCodeChange = (event) => {
        setSelectedClientCountryCode(event.target.value);
    };

    const handleClientOpen = () => {
        setIsClientOpen(true);
    };

    const handleClientClose = () => {
        setIsClientOpen(false);
    };

    const handleClickCheckType = (_checkType: any) => {
        setSelectedCheckType(_checkType.target.value)
    };

    //handle update record for step 3
    const handleEditRecord = (_payload: any) => {
        setLoading(true); // show loader
        patch(moduleMain, createRecordId, _payload)
            .then(async (_res) => {
                setLoading(false); // hide loader
                setSubmitSuccess(true);
                handleChangeThemeColors();
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                handleChangeThemeColors();
            })
    }


    const handleChangeBedrooms = (event: Event, newValue: number | number[]) => {
        setBedrooms(newValue as number);
    };

    function getDayOfWeek(date) {
        const daysOfWeeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const dayIndex = date.getDay();
        return daysOfWeeks[dayIndex];
    }

    // Function to generate the dates for the current week
    const getWeekDates = () => {
        const weekDates = [];

        for (let i = 0; i < 7; i++) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() + i);

            // Format the date as needed (you can use a library like date-fns for more formatting options)
            const formattedDate = `${date.getDate()}`;
            const isDisabled = date < currentDate;
            const isSelected = selectedDate && selectedDate.date === formattedDate;

            weekDates.push({
                day: getDayOfWeek(date),
                date: formattedDate,
                isDisabled: isDisabled,
                isSelected: isSelected,
            });
        }

        return weekDates;
    };

    function getCompleteDate(dayOfMonth) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-based, so we add 1

        // Ensure the day is within the valid range for the current month
        const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
        const validDayOfMonth = Math.min(dayOfMonth, lastDayOfMonth);

        // Format the date
        const formattedDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${validDayOfMonth.toString().padStart(2, '0')}`;

        return formattedDate;
    }

    const handleDateClick = (clickedDate) => {
        if (!clickedDate.isDisabled) {
            setVisitSlotChip(null);

            setSelectedDate(clickedDate);

            if (availableVisitSlot[clickedDate.date]) {
                setTimeSlots(availableVisitSlot[clickedDate.date]);
            } else {
                // Key not found, set default time slots
                setTimeSlots({
                    '8 AM - 12 PM': true,
                    '9 AM - 1 PM': true,
                    '2 PM - 6 PM': true,
                });
            }

        }
    };

    const handleChangePropertyType = (
        event: React.MouseEvent<HTMLElement>,
        propertyType: string,
    ) => {
        setPropertyType(propertyType);
    };

    // get the branding of partner
    const handleChangeThemeColors = () => {

        const theme = allCustombookingform.filter((_form: any) => _form.url == url);

        if (theme.length > 0) {

            // partner not found false
            setPartnerNotFound(false);

            setThemeColors({
                primary: theme[0].primaryColor,
                secondary: theme[0].secondaryColor,
                background: theme[0].backgroundColor,
                textColor: theme[0].textColor
            })

            // set logo
            setLogoSrc(theme[0].logo);

        } else {
            // default colors
            setThemeColors({
                primary: '#009540',
                secondary: '#1A1B18',
                background: '#191917',
                textColor: '#ffffff'
            })

            // if data loaded but no partner found
            if (allCustombookingform.length > 0) {
                // partner not found
                setPartnerNotFound(true);
            }
        }
    }

    const handleChipClick = (time) => {
        setVisitSlotChip(time);
        setChipError('');
    };

    // fetch dependent data
    const fetch = () => {
        fetchAvailableVisitSlot();
        fetchCustombookingform();
        fetchCheckType();
        fetchAvgSaving();
        fetchCountries();
    }

    // fetch avaiable visitSlot
    const fetchAvailableVisitSlot = () => {
        let tempTday = currentDate.getDate().toString();
        setLoading(true); // show loader
        getAll(moduleMain + '/available/visitSlot')
            .then((_res: any) => {
                setAvailableVisitSlot(_res);
                setVisitSlotChip(null)
                if (_res[tempTday]) {
                    setTimeSlots(_res[tempTday]);
                } else {
                    // Key not found, set default time slots
                    setTimeSlots({
                        '8 AM - 12 PM': true,
                        '9 AM - 1 PM': true,
                        '2 PM - 6 PM': true,
                    });
                }
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // fetch Custombookingform
    const fetchCustombookingform = () => {
        setLoading(true); // show loader
        getAll(moduleCustombookingform)
            .then((_res: any) => {
                setAllCustombookingform(_res);
                const resData = allCustombookingform.filter((_form: any) => _form.url == url);
                setPartnerId(resData[0].id);

                // set loading false in handleChangeThemeColors()
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // handle add record event
    const addRecord = (_formData: any) => {
        // setLoading(true); // show loader
        _formData.formId = partnerId;
        _formData.clientContact = _formData.clientCountryCode + _formData.clientContact;
        _formData.agentContact = _formData.agentCountryCode + _formData.agentContact;
        post(moduleMain, _formData)
            .then(_res => {
                if (bookingFor === 'myself') {
                    setSuccessName(_formData.clientName)
                } else {
                    setSuccessName(_formData.agentName)
                }
                setCreateRecordId(_res?.id)
                setLoading(false); // hide loader
                handleChangeThemeColors();
                setTabValue(2);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                handleChangeThemeColors();
                ;
            })
    }

    // form methods
    const onSubmit = (_data: any) => {
        if (tabValue === 0) {
            setTabValue(1);
            setShowPropertyCheckReportCard(true);
        }
        if (tabValue === 1) {
            //call add record on step 2 next
            _data.checkType = selectedCheckType;
            _data.addReInspection = isCheckedAddreinspection;
            _data.propertyType = propertyType;
            _data.numberOfBedrooms = bedrooms;

            addRecord(_data);
        }
        if (tabValue === 2) {
            let payload = {
                specialInstruction: _data.specialInstruction,
                visitDate: getCompleteDate(selectedDate.date),
            }
            if (visitSlotChip) {
                payload.visitSlot = visitSlotChip;
                handleEditRecord(payload);

            } else {
                setChipError('Please select a visit slot.');
            }
        }
    }


    // get areas
    const onAreaInputChange = (_e, _value) => {
        // reset communities
        setCommunities([]);

        // set loader
        setLoadAreas(true);

        // fetch areas using propertyfinder api
        getAll(moduleIntegrations + '/areas/' + _value.replaceAll(' ', '%20'))
            .then(_res => {
                const groupedOptions = _res.reduce((acc, option) => {
                    const { path_name } = option;
                    if (!acc[path_name]) {
                        acc[path_name] = [];
                    }
                    acc[path_name].push(option.name);
                    return acc;
                }, {});

                const options = Object.entries(groupedOptions).map(([group, options]) => ({
                    group,
                    options,
                }));

                const flatOptions = options.reduce((acc, curr) => {
                    curr.options.forEach((name) => {
                        acc.push({ group: curr.group, name });
                    });
                    return acc;
                }, []);

                setAreas(flatOptions);

                // reset loader
                setLoadAreas(false);
            })
            .catch(_err => {
                log(_err);
                // reset loader
                setLoadAreas(false);
            })
    };

    const fetchCountries = () => {
        setLoading(true); // show loader
        getAll(country)
            .then((_res: any) => {
                setAllCountries(_res);
                setLoading(false);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // get communities
    const onCommunityInputChange = (_e, _value) => {
        // set loader
        setLoadCommunities(true);
        // fetch communities using propertyfinder api
        getAll(moduleIntegrations + '/communities/' + _value.replaceAll(' ', '%20') + '/' + selectedArea.replaceAll(' ', '%20'))
            .then(_res => {
                setCommunities(_res);
                // reset loader
                setLoadCommunities(false);
            })
            .catch(_err => {
                log(_err);
                // reset loader
                setLoadCommunities(false);
            })
    };


    // hooks
    useEffect(() => {
        if (propertyType === 'apartment') {
            setHouseImg(`${propertyType}_${bedrooms}_Bed.png`);
        } else {
            if (bedrooms === 1) {
                setHouseImg(`${propertyType}_2_Bed.png`);

            } else {
                setHouseImg(`${propertyType}_${bedrooms}_Bed.png`);
            }
        }
    }, [propertyType, bedrooms]);

    useEffect(() => {
        fetch();
        // Set today's date as the default selected date
        setSelectedDate({
            day: daysOfWeek[currentDate.getDay()],
            date: currentDate.getDate().toString(),
            isDisabled: false,
            isSelected: true,
        });

        // Update daysOfWeek based on today's day
        const todayIndex = currentDate.getDay();
        const updatedDaysOfWeek = [
            ...daysOfWeek.slice(todayIndex),
            ...daysOfWeek.slice(0, todayIndex)
        ];
        setDaysOfWeek(updatedDaysOfWeek);

    }, []);

    useEffect(() => {
        handleChangeThemeColors();
    }, [allCustombookingform]);

    useEffect(() => {
        // update gradient colors
        // Dynamically update the styles
        const customBgClass = document.querySelectorAll(`.${classes.bgCustomAnimated}`);
        const colorStr = `linear-gradient(100deg, ${themeColors.primary}, ${themeColors.secondary})`;
        customBgClass.forEach((element) => {
            if (element instanceof HTMLElement) {
                element.style.background = colorStr;
            }
        });

        setLoading(false); // hide loader

        dispatch(setPageColor({
            // typography: {
            //     fontFamily: 'Gotham Book',
            // },
            palette: {
                primary: {
                    main: themeColors.primary || '#000000',
                    contrastText: "#fff" //button text white instead of black to support dark theme
                },
                background: {
                    default: themeColors.background
                },
                text: {
                    primary: themeColors.textColor || '#000000',
                    secondary: themeColors.textColor || '#000000',
                },
            },
            components: {
                MuiChip: {
                    styleOverrides: {
                        root: {
                            borderColor: themeColors.textColor || '#000000', // Set border color to primary text color
                        },
                        label: {
                            color: themeColors.textColor || '#000000', // Set text color to primary text color
                        },
                    },
                },
                MuiToggleButtonGroup: {
                    styleOverrides: {
                        root: {
                            borderColor: themeColors.textColor || '#000000', // Set border color to primary text color
                        },
                    },
                },
                MuiToggleButton: {
                    styleOverrides: {
                        root: {
                            borderColor: themeColors.secondary || '#000000', // Set border color to primary text color
                            color: themeColors.textColor || '#000000', // Set text color to primary text color
                            '&.Mui-selected': {
                                color: themeColors.primary || '#000000', // Set text color for selected state
                            },
                            
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            '&': {
                                color: '#000000'
                            },
                            '& li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-8lmg9e-MuiButtonBase-root-MuiMenuItem-root': {
                                color: '#000000'
                            }
                        }
                    }
                },
                MuiSelect: {
                    styleOverrides: {
                        root: {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: themeColors.secondary || '#000000' // Set border color for the focused state
                            },
                            // '& li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-8lmg9e-MuiButtonBase-root-MuiMenuItem-root': {
                            //     color: '#000000'
                            // },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: themeColors.primary || '#000000',
                                borderWidth: '0.15rem',
                            },
                        },
                        // filled: {
                        //     '&:before': {
                        //         borderColor: themeColors.primary || '#000000' // Set border color for the filled variant
                        //     },
                        // },
                        icon: {
                            fill: themeColors.secondary || '#000000' // Set fill color for the dropdown arrow icon
                        }
                    }
                },
                MuiSvgIcon: {
                    styleOverrides: {
                        root: {
                            '&': {
                                color: themeColors.secondary,
                            },
                        }
                    }
                },
                MuiFormLabel: {
                    styleOverrides: {
                        root: {
                            '&': {
                                color: themeColors.secondary,
                            },
                        }
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        option: {
                            '&': {
                                color: '#000000',
                                backgroundColor: '#ffffff'
                            },
                        },
                        root: {
                            '& MuiOutlinedInput-notchedOutline': {
                                borderColor: themeColors.primary || '#000000' // Set border color for the focused state
                            },
                            '&:hover MuiOutlinedInput-notchedOutline': {
                                borderColor: themeColors.primary || '#000000',
                                borderWidth: '0.15rem',
                            },
                        },
                    }
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "*": {
                                "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
                                "-moz-user-select": "text !important" /* Firefox 2+ */,
                                "-ms-user-select": "text !important" /* IE 10+ */,
                                "user-select": "text !important" /* Standard syntax */,
                            },
                            '& label': {
                                color: themeColors.secondary || '#000000',
                            },
                            '& label.Mui-focused': {
                                color: themeColors.secondary || '#000000',
                            },

                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: themeColors.secondary || '#000000',
                                },
                                '&:hover fieldset': {
                                    borderColor: themeColors.primary || '#000000',
                                    borderWidth: '0.15rem',
                                }
                            },
                        },
                    },
                },
                MuiFormHelperText: {
                    styleOverrides: {
                        root: {
                            textTransform: 'initial',
                            fontSize: '1rem',
                        },
                    },
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            "*": {
                                "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
                                "-moz-user-select": "text !important" /* Firefox 2+ */,
                                "-ms-user-select": "text !important" /* IE 10+ */,
                                "user-select": "text !important" /* Standard syntax */,
                            },
                        },
                    },
                },
                MuiFilledInput: {
                    styleOverrides: {
                        root: {
                            "*": {
                                "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
                                "-moz-user-select": "text !important" /* Firefox 2+ */,
                                "-ms-user-select": "text !important" /* IE 10+ */,
                                "user-select": "text !important" /* Standard syntax */,
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            "*": {
                                "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
                                "-moz-user-select": "text !important" /* Firefox 2+ */,
                                "-ms-user-select": "text !important" /* IE 10+ */,
                                "user-select": "text !important" /* Standard syntax */,
                            },
                        },
                    },
                },
            },
        }))
    }, [themeColors]);

    const darkTheme = createTheme(pageColor);

    return (
        <ThemeProvider theme={darkTheme}>

            {/* Loaded Data */}
            {!loading && !partnerNotFound &&
                <div className="row h-100">

                    {/* Left Div */}
                    <div className="col-md-6">
                        <div className="justify-content-center p-md-5 p-3 h-95">

                            <div className="row mt-0 mt-md-3">

                                <div className="col-md-2">
                                    <Reveal>
                                        <img src={logoSrc} className={'img-fluid '}
                                            style={{ width: '5em', height: 'auto' }}
                                            alt={url} />
                                    </Reveal>
                                </div>

                                <div className="col-md-10 mt-md-0 mt-4">
                                    <Reveal>
                                        {/* Tabs */}
                                        <Tabs value={tabValue} aria-label="Form Steps" variant="fullWidth">
                                            <Tab icon={<AssignmentIndTwoToneIcon />} label="CONTACT DETAILS" />
                                            <Tab icon={<HomeTwoToneIcon />} label="PROPERTY DETAILS" />
                                            <Tab icon={<AccessTimeTwoToneIcon />} label="SCHEDULE & BOOK" />
                                        </Tabs>
                                    </Reveal>
                                </div>
                            </div>

                            {/* Form start */}
                            {/* Tab 1 Content */}
                            {tabValue == 0 &&
                                <Reveal>
                                    <form className="row mt-5" onSubmit={handleSubmit(onSubmit)}>

                                        <div className={'mt-3'}>
                                            <h5 className={'pb-0 mb-0 fw-bolder'}>
                                                CONTACT DETAILS
                                            </h5>
                                            <Typography variant="body2"  className={'text-muted p-0'}>
                                                Step 1 of 3
                                            </Typography>

                                            {/* Booking For Choice */}
                                            {bookingFor === '' &&
                                                <>
                                                    <div className="row">

                                                        <div className="col-md-6 mt-md-5 mt-2 pe-md-5">
                                                            <div className={"btn btn-success full-width shadow-lg " + classes.bgCustomAnimated}
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    setBookingFor('myself');
                                                                }}
                                                            >
                                                                <Typography variant="h5" className='pt-2 pb-2 my-auto text-center gotham-book'>
                                                                    <div className="">I'm booking</div>
                                                                    <div className="">for myself</div>
                                                                </Typography>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 mt-md-5 mt-2 pe-md-5">
                                                            <div className={"btn btn-success full-width shadow-lg " + classes.bgCustomAnimated}
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    setBookingFor('client');
                                                                }}
                                                            >
                                                                <Typography variant="h5" className='pt-2 pb-2 my-auto text-center gotham-book'>
                                                                    <div className="">I'm booking</div>
                                                                    <div className="">for client</div>
                                                                </Typography>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {/* Form Fields*/}
                                            {bookingFor != '' &&
                                                <div className="row">
                                                    {bookingFor === 'client' &&
                                                        <>
                                                            <Typography variant="subtitle2" className={'mb-3 mb-md-3 mt-4 fw-bolder'}>
                                                                AGENT CONTACT DETAILS
                                                            </Typography>

                                                            {/* Field agentName */}
                                                            <div className="col-md-6">
                                                                <FormControl fullWidth>
                                                                    <TextField id="agentNameInput"
                                                                        {...register("agentName", { required: true })}
                                                                        label={t('custombookingformdata.agentName')}
                                                                        type={"text"}
                                                                        error={!!errors.agentName}
                                                                        variant="outlined"
                                                                        placeholder="John Doe"
                                                                        className=" shadow" />
                                                                </FormControl>
                                                            </div>

                                                            {/* Field agentEmail */}
                                                            <div className="col-md-6 mt-4 mt-md-0">
                                                                <FormControl fullWidth>
                                                                    <TextField id="agentEmailInput"
                                                                        {...register("agentEmail", { required: true })}
                                                                        label={t('custombookingformdata.agentEmail')}
                                                                        type={"email"}
                                                                        error={!!errors.agentEmail}
                                                                        variant="outlined"
                                                                        placeholder="contact@example.com"
                                                                        className="full-width shadow" />
                                                                </FormControl>
                                                            </div>
                                                            <div className="col-md-3 col-5 mt-4 pe-0">
                                                                <FormControl fullWidth={true}>
                                                                    <InputLabel id="grouped-select-label">
                                                                        Country Code
                                                                    </InputLabel>
                                                                    {allCountries.length > 0 &&
                                                                        <Select
                                                                            placeholder='Select Country'
                                                                            id="grouped-select"
                                                                            className=" shadow"
                                                                            {...register('agentCountryCode', { required: true })}
                                                                            onOpen={handleAgentOpen}
                                                                            onClose={handleAgentClose}
                                                                            onChange={handleAgentCountryCodeChange}
                                                                            value={selectedAgentCountryCode}
                                                                            label="Country Code">
                                                                            {allCountries.map((element: any) => (
                                                                                <MenuItem value={element.countryCode} key={element.countryCode} disableRipple>
                                                                                    {isAgentOpen && ( // Render flag name and code only if menu is open
                                                                                        <>
                                                                                            <img
                                                                                                src={element.flag}
                                                                                                alt={`Flag of ${element.name}`}
                                                                                                style={{ width: '20px', marginRight: '8px' }}
                                                                                            />
                                                                                            {element.name} (+{element.countryCode})
                                                                                        </>
                                                                                    )}
                                                                                    {!isAgentOpen && ( // Render only flag and code if menu is not open
                                                                                        <>
                                                                                            <img
                                                                                                src={element.flag}
                                                                                                alt={`Flag of ${element.name}`}
                                                                                                style={{ width: '20px', marginRight: '8px' }}
                                                                                            />
                                                                                            (+{element.countryCode})
                                                                                        </>
                                                                                    )}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    }
                                                                    {allCountries.length == 0 &&
                                                                        <>
                                                                            <Select labelId="countryLabel"
                                                                                disabled
                                                                                id="countryLabelIdSelect"
                                                                                label="Country">
                                                                            </Select>
                                                                        </>
                                                                    }
                                                                </FormControl>
                                                            </div>

                                                            {/* Field agentContact */}
                                                            <div className="col-md-9 col-7 mt-4">
                                                                <FormControl fullWidth>
                                                                    <TextField id="agentContactInput"
                                                                        {...register("agentContact", { required: true })}
                                                                        label={t('custombookingformdata.agentContact')}
                                                                        type={"number"}
                                                                        error={!!errors.agentContact}
                                                                        variant="outlined"
                                                                        placeholder="50 123 4567"
                                                                        className="full-width shadow" />
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-md-6"></div>
                                                        </>

                                                    }

                                                    <Typography variant="subtitle2" className={'mb-3 mb-md-3 mt-5 fw-bolder'}>
                                                        {bookingFor === 'client' ? 'CLIENT CONTACT DETAILS' : 'YOUR CONTACT DETAILS'}
                                                    </Typography>

                                                    {/* Field clientName */}
                                                    <div className="col-md-6">
                                                        <FormControl fullWidth>
                                                            <TextField id="clientNameInput"
                                                                {...register("clientName", { required: true })}
                                                                label={t('custombookingformdata.clientName')}
                                                                type={"text"}
                                                                error={!!errors.clientName}
                                                                variant="outlined"
                                                                placeholder="John Doe"
                                                                className="full-width shadow" />
                                                        </FormControl>
                                                    </div>

                                                    {/* Field clientEmail */}
                                                    <div className="col-md-6 mt-4 mt-md-0">
                                                        <FormControl fullWidth>
                                                            <TextField id="clientEmailInput"
                                                                {...register("clientEmail", { required: true })}
                                                                label={t('custombookingformdata.clientEmail')}
                                                                type={"email"}
                                                                error={!!errors.clientEmail}
                                                                variant="outlined"
                                                                placeholder="contact@example.com"
                                                                className="full-width shadow" />
                                                        </FormControl>
                                                    </div>

                                                    {/* Field Code */}
                                                    <div className="col-md-3 col-5 mt-4 pe-0">
                                                        <FormControl fullWidth={true}>
                                                            <InputLabel id="grouped-select-label">
                                                                Country Code
                                                            </InputLabel>
                                                            {allCountries.length > 0 &&
                                                                <Select
                                                                    placeholder='Select Country'
                                                                    id="grouped-select"
                                                                    className="shadow"
                                                                    {...register('clientCountryCode', { required: true })}
                                                                    onOpen={handleClientOpen}
                                                                    onClose={handleClientClose}
                                                                    onChange={handleClientCountryCodeChange}
                                                                    value={selectedClientCountryCode}
                                                                    label="Country Code">
                                                                    {allCountries.map((element: any) => (
                                                                        <MenuItem value={element.countryCode} key={element.countryCode} disableRipple>
                                                                            {isClientOpen && ( // Render flag name and code only if menu is open
                                                                                <>
                                                                                    <img
                                                                                        src={element.flag}
                                                                                        alt={`Flag of ${element.name}`}
                                                                                        style={{ width: '20px', marginRight: '8px' }}
                                                                                    />
                                                                                    {element.name} (+{element.countryCode})
                                                                                </>
                                                                            )}
                                                                            {!isClientOpen && ( // Render only flag and code if menu is not open
                                                                                <>
                                                                                    <img
                                                                                        src={element.flag}
                                                                                        alt={`Flag of ${element.name}`}
                                                                                        style={{ width: '20px', marginRight: '8px' }}
                                                                                    />
                                                                                    (+{element.countryCode})
                                                                                </>
                                                                            )}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            }
                                                            {allCountries.length == 0 &&
                                                                <>
                                                                    <Select labelId="countryLabel"
                                                                        disabled
                                                                        className="shadow"
                                                                        id="countryLabelIdSelect"
                                                                        label="Country">
                                                                    </Select>
                                                                </>
                                                            }
                                                        </FormControl>
                                                    </div>

                                                    {/* Field clientContact */}
                                                    <div className="col-md-9 col-7 mt-4">
                                                        <FormControl fullWidth>
                                                            <TextField id="clientContactInput"
                                                                {...register("clientContact", { required: true })}
                                                                label={t('custombookingformdata.clientContact')}
                                                                type={"number"}
                                                                error={!!errors.clientContact}
                                                                variant="outlined"
                                                                placeholder="50 123 4567"
                                                                className="full-width shadow" />
                                                        </FormControl>
                                                    </div>

                                                </div>
                                            }

                                            {bookingFor &&
                                                <div className="row mt-4 float-end mx-2 mx-md-0">
                                                    <Button variant="contained"
                                                        size="small"
                                                        type='submit'
                                                        endIcon={<ArrowForwardIosOutlinedIcon style={{ color: 'white' }} />}>
                                                        {t('common.next')}
                                                    </Button>
                                                </div>
                                            }

                                        </div>
                                    </form>
                                </Reveal>
                            }


                            {/* Tab 2 Content */}
                            {tabValue == 1 &&
                                <Reveal>
                                    <form className="row mt-5" onSubmit={handleSubmit(onSubmit)}>
                                        <div className={'mt-3'}>
                                            <h5 className={'pb-0 mb-0 fw-bolder'}>
                                                PROPERTY DETAILS
                                            </h5>
                                            <Typography variant="body2" className={'text-muted p-0'}>
                                                Step 2 of 3
                                            </Typography>

                                            {/*  Form Fields  */}
                                            <div className="row">

                                                {/* Field propertyType */}
                                                <div className="col-md-6 mt-4">
                                                    <Typography variant="subtitle2" className={'mb-3 fw-bolder '}>
                                                        {t('custombookingformdata.propertyType')}
                                                    </Typography>
                                                    <ToggleButtonGroup
                                                        fullWidth
                                                        color="primary"
                                                        value={propertyType}
                                                        exclusive
                                                        className={'shadow-lg'}
                                                        onChange={handleChangePropertyType}
                                                        aria-label="Property Type"
                                                    >
                                                        <ToggleButton className={'py-3'}
                                                            value="apartment">Apartment</ToggleButton>
                                                        <ToggleButton className={'py-3'}
                                                            value="villa">Villa</ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>


                                                {/* Field checkType */}
                                                <div className="col-md-6 mt-5">
                                                    <div className={'row mt-md-2'}>
                                                        <FormControl fullWidth={true}>
                                                            <InputLabel id="checkTypeLabel">
                                                                Check Type
                                                            </InputLabel>
                                                            {checkTypes.length > 0 &&
                                                                <Select labelId="checkTypeLabel"
                                                                    id="checkTypeSelect"
                                                                    className="shadow mt-1"
                                                                    {...register("checkTypeId", { required: true })}
                                                                    error={!!errors.checkType}
                                                                    value={selectedCheckType}
                                                                    onChange={handleClickCheckType}
                                                                    label='Check Type'>
                                                                    {
                                                                        checkTypes.map((el: any) =>
                                                                            <MenuItem value={el.id}
                                                                                key={el.id}> {el.name}
                                                                            </MenuItem>)
                                                                    }
                                                                </Select>
                                                            }
                                                            {checkTypes.length == 0 &&
                                                                <>
                                                                    <Select labelId="locationIdLabel"
                                                                        disabled
                                                                        id="checkTypeSelect"
                                                                        {...register("checkType", { required: false })}
                                                                        error={!!errors.checkType}
                                                                        value={watch('checkType')}
                                                                        label='Check Type'>
                                                                        <MenuItem value={''}>
                                                                            No Data
                                                                        </MenuItem>
                                                                    </Select>
                                                                    <FormHelperText>
                                                                        No Data
                                                                    </FormHelperText>
                                                                </>
                                                            }
                                                        </FormControl>

                                                    </div>
                                                </div>


                                                {/* Field numberOfBedrooms */}
                                                <div className="col-md-6 mt-4">
                                                    <Typography variant="subtitle2" className={'mt-3 mb-3 fw-bolder '}>
                                                        {t('custombookingformdata.numberOfBedrooms')}
                                                    </Typography>
                                                    <div className="text-center">
                                                        <img
                                                            src={process.env.REACT_APP_URL + '/assets/img/rooms/' + houseImg}
                                                            className={'img-fluid mx-auto'} style={{ height: '10em' }} />
                                                    </div>

                                                    <Stack spacing={2} direction="row" sx={{ mb: 1 }}
                                                        alignItems="center">
                                                        <p className={'pt-0'}>
                                                            {propertyType === 'villa' ? '2' : 'Studio'}
                                                        </p>
                                                        <Slider aria-label="Bedrooms"
                                                            value={bedrooms}
                                                            valueLabelDisplay="on"
                                                            valueLabelFormat={(value) => `${value} Bed`}
                                                            step={1}
                                                            marks
                                                            min={propertyType === 'villa' ? 2 : 1}
                                                            max={6}
                                                            onChange={handleChangeBedrooms} />
                                                        <p>
                                                            6+
                                                        </p>
                                                    </Stack>
                                                </div>

                                                {/* Amount Saved */}
                                                <div className="col-md-6 mt-md-5 mt-3 mb-md-0 mb-4 pt-4 mb-2">
                                                    <div className="card transparent-bg shadow-lg">
                                                        <Alert severity="success" color="primary" icon={false} className='d-flex justify-content-center'>
                                                            <div className="row">
                                                                <Typography variant="subtitle2" gutterBottom
                                                                    className={'text-center mt-2'}>
                                                                    ON AN AVERAGE <br /> WE PROTECT
                                                                </Typography>
                                                                <Typography variant="h5" gutterBottom
                                                                    className={'fw-bolder text-center full-upper-case'}>
                                                                    {avgSaving[propertyType][bedrooms]}
                                                                </Typography>
                                                            </div>
                                                        </Alert>
                                                    </div>
                                                </div>

                                                {/* Field area */}
                                                <div className="col-md-5 mt-4">
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            className="full-width shadow-lg"
                                                            id="areas"
                                                            loading={loadAreas}
                                                            options={areas}
                                                            groupBy={(option) => option.group}
                                                            getOptionLabel={(option) => option.name}
                                                            onInputChange={(e, v) => {
                                                                onAreaInputChange(e, v)
                                                            }}
                                                            onChange={(e, v) => {
                                                                setSelectedArea(v.name);
                                                            }}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    placeholder={'Al Reem Island'}
                                                                    variant="outlined"
                                                                    {...register("area", { required: true })}
                                                                    label={t('custombookingformdata.area')} />}
                                                            renderGroup={(params) => (
                                                                <li key={params.key}>
                                                                    <GroupHeader>{params.group}</GroupHeader>
                                                                    <GroupItems>
                                                                        {params.children.map((option) => (
                                                                            <li key={option.name}>
                                                                                {option}
                                                                            </li>
                                                                        ))}
                                                                    </GroupItems>
                                                                </li>
                                                            )}
                                                        />

                                                    </FormControl>

                                                </div>

                                                {/* Field community */}
                                                <div className="col-md-5 mt-4">
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={communities}
                                                            onInputChange={(e, v) => {
                                                                onCommunityInputChange(e, v)
                                                            }}
                                                            loading={loadCommunities}
                                                            onChange={(e, v) => {
                                                                setSelectedCommunity(v);
                                                            }}
                                                            renderInput={(params) =>
                                                                <TextField {...params}
                                                                    label={t('custombookingformdata.community') + '/Building'}
                                                                    placeholder={'Yas Bay'}
                                                                    {...register("community", { required: true })}
                                                                    variant="outlined"
                                                                    className="full-width shadow-lg" />}
                                                        />
                                                    </FormControl>
                                                </div>

                                                {/* Field unit */}
                                                <div className="col-md-2 mt-4">
                                                    <FormControl fullWidth>
                                                        <TextField id="unitInput"
                                                            {...register("unit", { required: true })}
                                                            label={t('custombookingformdata.unit')}
                                                            type={"text"}
                                                            error={!!errors.unit}
                                                            variant="outlined"
                                                            placeholder={'13'}
                                                            className="full-width shadow-lg" />
                                                    </FormControl>
                                                </div>

                                            </div>

                                            <div className="row mt-4 mx-2 mx-md-0">
                                                <div className="col">
                                                    <Button variant="contained"
                                                        size="small"
                                                        onClick={() => {
                                                            setTabValue(0)
                                                        }}
                                                        startIcon={<ArrowBackIosOutlinedIcon style={{ color: 'white' }} />}>
                                                        {t('common.back')}
                                                    </Button>
                                                </div>
                                                <div className="col text-end">
                                                    <Button variant="contained"
                                                        size="small"
                                                        type='submit'
                                                        endIcon={<ArrowForwardIosOutlinedIcon style={{ color: 'white' }} />}>
                                                        {t('common.next')}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Reveal>
                            }


                            {/* Schedule and book */}
                            {/* Tab 3 Content */}
                            {tabValue == 2 &&
                                <Reveal>
                                    <>
                                        {!submitSuccess &&
                                            <form className="row mt-5" onSubmit={handleSubmit(onSubmit)}>

                                                <div className={'mt-3'}>
                                                    <h5 className={'pb-0 mb-0 fw-bolder'}>
                                                        SCHEDULE & BOOK
                                                    </h5>
                                                    <Typography variant="body2" className={'text-muted p-0'}>
                                                        Step 3 of 3
                                                    </Typography>

                                                    {bedrooms < 6 &&
                                                        <>
                                                            {/* Form Fields*/}
                                                            <div className="row">
                                                                {/* Booking Date */}
                                                                <div className="col-md-12 mt-4">
                                                                    <Typography variant="subtitle2" className={'mb-3 fw-bolder'}>
                                                                        What day would you like your Property Check?
                                                                    </Typography>

                                                                    <div style={{ display: 'inline-flex' }}>
                                                                        {getWeekDates().map((dayInfo, index) => (
                                                                            <div className='mt-1'>
                                                                                <div
                                                                                    style={{ textAlign: 'center' }}>{dayInfo.day}</div>
                                                                                <div
                                                                                    className={'m-1 text-center rounded p-2'}
                                                                                    style={{
                                                                                        border: `1px solid ${themeColors.primary}`,
                                                                                        backgroundColor: dayInfo.isDisabled ? '#eee' : dayInfo.isSelected ? themeColors.primary : 'transparent',
                                                                                        color: dayInfo.isSelected ? 'white' : themeColors.textColor,
                                                                                        cursor: dayInfo.isDisabled ? 'not-allowed' : 'pointer',
                                                                                    }}
                                                                                    onClick={() => handleDateClick(dayInfo)}
                                                                                >
                                                                                    <div>{dayInfo.date >= 10 ? dayInfo.date : '0' + dayInfo.date}</div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                </div>

                                                                {/* Chips for time selction */}
                                                                <div className="col-md-12 mt-4">
                                                                    <Typography variant="subtitle2" className={'mb-3 fw-bolder'}>
                                                                        What time would you like your Property Check?
                                                                    </Typography>

                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <Stack direction="row" spacing={1}
                                                                                className='mt-2'>
                                                                                {Object.entries(timeSlots).map(([slot, isEnabled]) => (
                                                                                    <Chip
                                                                                        key={slot}
                                                                                        color="primary"
                                                                                        label={slot}
                                                                                        sx={{
                                                                                            "& .MuiChip-label": {
                                                                                                color: visitSlotChip === slot ? 'white' : themeColors.textColor
                                                                                            }
                                                                                        }}
                                                                                        variant={visitSlotChip === slot ? 'filled' : 'outlined'}
                                                                                        disabled={!isEnabled}
                                                                                        onClick={() => handleChipClick(slot)}
                                                                                    />
                                                                                ))}
                                                                            </Stack>
                                                                            {chipError &&
                                                                                <p style={{ color: 'red' }}>{chipError}</p>}
                                                                        </div>

                                                                        <div className="col-md-6 mt-4 mt-md-0">
                                                                            {visitSlotChip == '8 AM - 12 PM' &&
                                                                                <Alert severity="warning"
                                                                                    color="primary">
                                                                                    Your Check Heroes will arrive at
                                                                                    7:45 AM
                                                                                </Alert>
                                                                            }

                                                                            {visitSlotChip == '9 AM - 1 PM' &&
                                                                                <Alert severity="warning"
                                                                                    color="primary">
                                                                                    Your Check Heroes will arrive at
                                                                                    8:45 AM
                                                                                </Alert>
                                                                            }

                                                                            {visitSlotChip == '2 PM - 6 PM' &&
                                                                                <Alert severity="warning"
                                                                                    color="primary">
                                                                                    Your Check Heroes will arrive at
                                                                                    1:45 PM
                                                                                </Alert>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                                {/* Special Instruction */}
                                                                {/* Field area */}
                                                                <div className="col-md-12 mt-4">
                                                                    <Typography variant="subtitle2" className={'fw-bolder mb-0 pb-0'}>
                                                                        Any special instructions for the day?
                                                                    </Typography>
                                                                    <Typography variant="body2" className='p-0 mb-3'><FontAwesomeIcon icon="fa-solid fa-circle-info" /> Things like different start times, specific areas to focus on etc</Typography>

                                                                    <FormControl fullWidth className='mt-2'>
                                                                        <TextField id="specialInstructionInput"
                                                                            {...register("specialInstruction", { required: false })}
                                                                            label={t('custombookingformdata.specialInst')}
                                                                            type={"text"}
                                                                            multiline
                                                                            minRows={4}
                                                                            error={!!errors.specialInstruction}
                                                                            variant="outlined"
                                                                            placeholder={t('custombookingformdata.specialInsLong')}
                                                                            className="full-width" />
                                                                    </FormControl>
                                                                </div>
                                                            </div>


                                                            <div className="row mt-4 float-end mx-1">
                                                                <Button variant="contained"
                                                                    size="small"
                                                                    type='submit'>
                                                                    {t('common.submit')}
                                                                </Button>
                                                            </div>

                                                        </>
                                                    }

                                                    {/* Special Property Component  */}
                                                    {bedrooms >= 6 &&
                                                        <Reveal>
                                                            <SpecialProperty />
                                                        </Reveal>
                                                    }

                                                </div>
                                            </form>
                                        }
                                        {submitSuccess &&
                                            <Reveal>
                                                <Success bookingFor={bookingFor} name={successName} />
                                            </Reveal>
                                        }
                                    </>
                                </Reveal>
                            }

                        </div>
                    </div>

                    {/* Right Div */}
                    <div className="col-md-6">
                        <Card sx={{ m: 3, minHeight: '95vh', boxShadow: 10, borderRadius: '20px' }}
                            className={'d-flex justify-content-center align-items-center ' + classes.bgCustomAnimated}>
                            <CardContent className="w-100">
                                {!showPropertyCheckReportCard &&
                                    <>
                                        <div style={{ textAlign: 'center' }}>
                                            <img className="img-fluid"
                                                src={require('../../../../Assets/img/isometric/ISOMETRICROOM1.png')} style={{ height: '15em' }} />
                                        </div>
                                        <div>
                                            <Typography variant="h6" className={'text-white full-upper-case fw-bolder text-center'}>
                                                <Typewriter
                                                    words={["You're booking the world's most-detailed property inspection, powered by Property Check."]}
                                                    loop={1}
                                                    cursor
                                                    cursorStyle='_'
                                                    typeSpeed={70}
                                                    deleteSpeed={50}
                                                    delaySpeed={1000}
                                                />
                                            </Typography>
                                        </div>
                                    </>
                                }

                                {showPropertyCheckReportCard &&
                                    <div>
                                        <WhiteLabelledFormBookingSummary
                                            _bookingSummary={watch()}
                                            _checkType={getNameById(selectedCheckType)}
                                            _propertyType={propertyType}
                                            _rooms={bedrooms}
                                            _agentCountryCode={selectedAgentCountryCode}
                                            _clientCountryCode={selectedClientCountryCode}
                                            _bookingFor={bookingFor}
                                            _amount={savingsMap[propertyType][bedrooms]} />
                                    </div>
                                }
                            </CardContent>
                        </Card>
                    </div>

                    {/* Footer Div */}
                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                        <CustomFooter colorCode={themeColors} />
                    </div>
                </div>


            }


            {/* Loader */}
            {loading &&
                <div className={'row h-100'}>
                    <div className="col-md-6">

                        <div className="row mt-5">
                            <div className="col-md-3 text-center">
                                <div className="px-5">
                                    <Skeleton animation="wave" variant="text" className={''} height={90} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <Skeleton animation="wave" variant="text" height={90} />
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-2 col-1"></div>
                            <div className="col-md-8 col-10">
                                <Skeleton variant="rectangular" height={'55vh'} />
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <Skeleton animation="wave" variant="rectangular"
                            sx={{ m: 3, minHeight: '95vh', boxShadow: 10, borderRadius: '20px' }} />
                    </div>
                </div>
            }

            {/* Partner Not Found */}
            {partnerNotFound && !loading &&
                <div className={'w-100 h-100'}>
                    <NotFoundM />
                </div>
            }

        </ThemeProvider>
    );
}

export default WhiteLabelledFormMain;
