// @ts-nocheck


import Box from '@mui/material/Box';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import {Button} from "@mui/material";
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function SpecialProperty() {

    return (
        <Box sx={{display: 'flex'}}>
          <div className="card shadow-lg full-width mt-md-5 mt-2">
              <div className="p-md-4 p-3">
                  <h2 className={'fw-bolder'}>
                      <span>
                          <CelebrationTwoToneIcon fontSize="large" color="primary" />&nbsp;
                      </span>
                      <span className={'mt-3'}>
                      You've got a special property!
                      </span>
                  </h2>
                  <p className={'mt-4'}>
                      Congratulations! You'll need to confirm a price with us over the phone. Give us a call at <strong>800 CHECK ME (24325 63)</strong> to get quote and finalize the booking.
                  </p>
                  <Button variant="contained"
                          size="large"
                          href={"tel:08002432563"}
                          className={'text-decoration-none text-white float-end'}
                          endIcon={<PhoneTwoToneIcon />}>
                      Call to Get Quote
                  </Button>
              </div>
          </div>
        </Box>
    );
}

export default SpecialProperty;
