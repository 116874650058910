// @ts-nocheck
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Tooltip from "@mui/material/Tooltip";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { decrypt, encrypt } from '../../../../../Services/CryptoService';

// @ts-ignore
function MailServerForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {

    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);
    const [isCheckedIsactive, setIsCheckedIsactive] = React.useState(false);
    const [isCheckedSsltls, setIsCheckedSsltls] = React.useState(false);
    const [showToken, setShowToken] = React.useState(false);
    const [inputType, setInputType] = React.useState('password');
    const [password, setPassword] = React.useState('');

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            if (showToken) {
                _data.password = encrypt(password)
            } else {
                _data.password = password
            }

            handleEditEvent(_data);
        }
    }

    // data vars


    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    const triggerTokenView = (flag) => {
        setShowToken(!showToken);
        flag == 'show' ? setInputType('text') : setInputType('password');
        if (isEditMode) {
            if (flag == 'show') {
                setPassword(decrypt(password));
            } else {
                setPassword(encrypt(password));
            }
        }

    }

    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);
            setPassword(recordToUpdate?.password)
            setIsCheckedSsltls(recordToUpdate.isSSLTLS);
            setIsCheckedIsactive(recordToUpdate.isActive);
        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.mailServer')}
                                    </h3>

                                </div>



                                {/* Host Field */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="hostInput"
                                            {...register("host", { required: true })}
                                            label={t('emailServer.host')}
                                            type={"text"}
                                            error={!!errors.host}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Port Field */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="portInput"
                                            {...register("port", { required: true })}
                                            label={t('emailServer.port')}
                                            type={"text"}
                                            error={!!errors.port}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Email Field */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="emailInput"
                                            {...register("email", { required: true })}
                                            label={t('emailServer.email')}
                                            type={"text"}
                                            error={!!errors.email}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field password */}
                                <div className="col-md-5 pe-0 me-0 mt-4">
                                    {!isEditMode &&
                                        <FormControl fullWidth>
                                            <TextField id="passwordInput"
                                                {...register("password", { required: true })}
                                                label={t('emailServer.password')}
                                                type={inputType}
                                                error={!!errors.password}
                                                variant="outlined"
                                                className="full-width" />
                                        </FormControl>
                                    }
                                    {isEditMode &&
                                        <FormControl fullWidth>
                                            <TextField id="passwordInput"
                                                type={inputType}
                                                value={password}
                                                label={t('emailServer.password')}
                                                error={!!errors.password}
                                                variant="outlined"
                                                onChange={e => setPassword(e.target.value)}
                                                className="full-width" />
                                        </FormControl>
                                    }

                                </div>
                                <div className="col-md-1 mt-4 ms-0 pt-2 d-flex justify-content-start align-items-center">
                                    {!showToken &&
                                        <Tooltip title="Show Password">
                                            <IconButton>
                                                <RemoveRedEyeIcon onClick={() => triggerTokenView('show')} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {showToken &&
                                        <Tooltip title="Hide Password">
                                            <IconButton>
                                                <VisibilityOffIcon onClick={() => triggerTokenView('hide')} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>

                                {/* isSSLTLS Field */}

                                <div className="col-md-2 mt-4">
                                    <FormControl fullWidth error={!!errors.isSSLTLS}>
                                        <FormControlLabel
                                            {...register("isSSLTLS", {})}
                                            label={t('emailServer.ssltls')}
                                            control={<Checkbox checked={isCheckedSsltls}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsCheckedSsltls(_event.target.checked)
                                                    setValue("isSSLTLS", _event.target.checked);
                                                }} />}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field isActive */}
                                <div className="col-md-2 mt-4">
                                    <FormControl fullWidth error={!!errors.isActive}>
                                        <FormControlLabel
                                            {...register("isActive", {})}
                                            label={t('emailServer.active')}
                                            control={<Checkbox checked={isCheckedIsactive}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsCheckedIsactive(_event.target.checked)
                                                    setValue("isActive", _event.target.checked);
                                                }} />}
                                        />
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default MailServerForm;
