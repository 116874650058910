// @ts-nocheck
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Autocomplete, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getAll, post, deleteByIdHard, getById, patch } from '../../../../../Services/GenericApiService';
import { log } from '../../../../../Services/LoggerService';
import { styled, lighten, darken } from '@mui/system';
import { DatePicker } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));


const GroupItems = styled('ul')({
    padding: 0,
});

function BookingForm({ cancelEvent, recordToUpdate, add = false }) {

    // translation
    const { t } = useTranslation();

    // module(s) for api
    const moduleMain = 'booking';

    // linked components API modules (endpoints)
    const moduleChecktype = 'checktype';
    const modulePropertytype = 'propertytype';
    const moduleIntegrations = 'integration';
    const moduleCustombookingform = 'custombookingform';
    const moduleRoomType = 'roomtype';
    const moduleBookingRoomType = 'bookingroomtype';
    const moduleUser = 'user';
    const moduleInspectorAttributes = 'inspectorattributes';
    const moduleInspectionTemplate = 'inspectiontemplate';
    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    //data handlers
    const [allCheckType, setAllCheckType] = useState([]);
    const [allInspector, setAllInspector] = useState([]);
    const [communities, setCommunities] = useState([]);
    const [roomSelections, setRoomSelections] = useState([]);
    const [noOfrooms, setNoOfrooms] = useState("Studio");
    const [parentId, setParentId] = useState(null);
    const [childId, setChildId] = useState(null);
    const [parentChildData, setParentChildData] = useState({});
    const [allParent, setAllParent] = useState([]);
    const [allChild, setAllChild] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedCommunity, setSelectedCommunity] = useState('');
    const [visiSlot, setVisitSlot] = useState('');
    const [selectedVisitdate, setSelectedVisitdate] = useState<Date | null>(null);
    const [allSlots, setAllSlots] = useState([]);
    const [allCustombookingform, setAllCustombookingform] = useState([]);
    const [selectedInspectors, setSelectedInspectors] = useState([]);
    const [prevInspectorId, setPrevInspectorId] = useState([]);
    const [timeSlots, setTimeSlots] = useState([
        '8 AM - 12 PM',
        '9 AM - 1 PM',
        '2 PM - 6 PM',
    ]);
    const today = new Date();
    const nextSevenDays = new Date(today);
    nextSevenDays.setDate(today.getDate() + 6);

    //ui handlers
    const [loadAreas, setLoadAreas] = useState(false);
    const [loadCommunities, setLoadCommunities] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const [onceFlag,setOnceFlag] = useState(false);

    // Handle adding a new row
    const addRoom = () => {
        setRoomSelections([...roomSelections, { rtParentId: '', rtChildId: '' }]);
    };

    // Handle removing a row
    const removeRoom = (index) => {
        const updatedSelections = [...roomSelections];
        updatedSelections.splice(index, 1);
        setRoomSelections(updatedSelections);
    };

    // Handle parent selection change
    const handleParentChange = (index, parentId) => {
        const updatedSelections = [...roomSelections];
        updatedSelections[index] = { parentId, childId: '' }; // Reset child when parent changes
        setRoomSelections(updatedSelections);
    };

    // Handle child selection change
    const handleChildChange = (index, childId) => {
        const updatedSelections = [...roomSelections];
        updatedSelections[index] = { ...updatedSelections[index], childId };
        setRoomSelections(updatedSelections);
    };

    const handleNoOfRooms = (event) => {
        setNoOfrooms(event.target.value);
    };

    const handleParent = (event) => {
        setParentId(event.target.value);
    };

    const handleChild = (event) => {
        setChildId(event.target.value);
    };

    // get all parent
    const getAllChildByParent = (_parentId) => {
        getAll(modulePropertytype + '/child/' + _parentId)
            .then((_res: any) => {
                // set all data
                setAllChild(_res)
            })
            .catch(_err => {
                log(_err)
            })
    }

    // get all parent
    const getAllParent = () => {
        getAll(modulePropertytype + '/parent')
            .then((_res: any) => {
                // set all data
                setAllParent(_res)
            })
            .catch(_err => {
                log(_err)
            })
    }

    // fetch all parent child data room
    const getAllParentChild = () => {
        let id = childId == null ? parentId : childId
        getAll(moduleRoomType + "/propertyType/" + id)
            .then((_res: any) => {
                // set all data
                setParentChildData(_res)
            })
            .catch(_err => {
                log(_err)
            })
    };

    const handleDelete = (_id: number): Promise<boolean> => {
        setIsLoading(true);   // show spinner
        return deleteByIdHard(moduleBookingRoomType, _id)
            .then(async (_res) => {
                setIsLoading(false);  // hide loader
                return true;
            })
            .catch(_err => {
                log(_err)
                setIsLoading(false); // hide loader
                return false;
            })
    }



    // form methods
    const onSubmit = (_data: any) => {
        if (add) {
            _data.visitSlot = visiSlot;
            _data.roomtype = roomSelections;
            _data.inspectorId = selectedInspectors.length > 0 ? selectedInspectors.map(inspector => inspector.userId) : [];
            handleAddEvent(_data);
        } else {
            _data.visitSlot = visiSlot;
            _data.roomtype = roomSelections;
            _data.inspectorId = selectedInspectors.length > 0 ? selectedInspectors.map(inspector => inspector.userId) : [];
            _data.prevInspectorId = prevInspectorId.length > 0 ? prevInspectorId : [];
            handleEditEvent(_data);
        }
    }

    // get pre populated template
    const getPrepopulatedTemplate = () => {
        setIsLoading(true); // show loader

        //these are the property type parent and child id
        let payload = {
            rooms: noOfrooms,
            ptParentId: parentId,
            ptChildId: childId
        }
        post(moduleInspectionTemplate+'/template/rooms', payload)
            .then(_res => {
                setRoomSelections(_res);
                setIsLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setIsLoading(false); // hide loader
            })
    }

    // handle add record event
    const handleAddEvent = (_formData: any) => {
        setIsLoading(true); // show loader
        post(moduleMain, _formData)
            .then(_res => {
                setIsLoading(false); // hide loader
                cancelEvent(); // refresh data
                // @ts-ignore
                notifications.current.successAlert(t('common.createSuccess'), '');
            })
            .catch(_err => {
                log(_err)
                setIsLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    // handle edit record event
    const handleEditEvent = (_formData: any) => {
        setIsLoading(true); // show loader
        patch(moduleMain, recordToUpdate.id, _formData)
            .then(async (_res) => {
                setIsLoading(false); // hide loader
                cancelEvent(); // refresh data
                // @ts-ignore
                notifications.current.successAlert(t('common.editSuccess'), '');
            })
            .catch(_err => {
                log(_err)
                setIsLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                resetEdit();
            })
    }

    // fetch Custombookingform
    const fetchCustombookingform = () => {
        getAll(moduleCustombookingform)
            .then((_res: any) => {
                setAllCustombookingform(_res);
            })
            .catch(_err => {
                log(_err)
            })
    }

    // get areas
    const onAreaInputChange = (_e, _value) => {
        // reset communities
        setCommunities([]);

        // set loader
        setLoadAreas(true);

        // fetch areas using propertyfinder api
        getAll(moduleIntegrations + '/areas/' + _value.replaceAll(' ', '%20'))
            .then(_res => {
                const groupedOptions = _res.reduce((acc, option) => {
                    const { path_name } = option;
                    if (!acc[path_name]) {
                        acc[path_name] = [];
                    }
                    acc[path_name].push(option.name);
                    return acc;
                }, {});

                const options = Object.entries(groupedOptions).map(([group, options]) => ({
                    group,
                    options,
                }));

                const flatOptions = options.reduce((acc, curr) => {
                    curr.options.forEach((name) => {
                        acc.push({ group: curr.group, name });
                    });
                    return acc;
                }, []);

                setAreas(flatOptions);

                // reset loader
                setLoadAreas(false);
            })
            .catch(_err => {
                log(_err);
                // reset loader
                setLoadAreas(false);
            })
    };

    // get communities
    const onCommunityInputChange = (_e, _value) => {
        // set loader
        setLoadCommunities(true);
        // fetch communities using propertyfinder api
        getAll(moduleIntegrations + '/communities/' + _value.replaceAll(' ', '%20') + '/' + selectedArea.replaceAll(' ', '%20'))
            .then(_res => {
                setCommunities(_res);
                // reset loader
                setLoadCommunities(false);
            })
            .catch(_err => {
                log(_err);
                // reset loader
                setLoadCommunities(false);
            })
    };

    // fetch checktype
    const fetchCheckType = () => {
        getAll(moduleChecktype)
            .then((_res: any) => {
                setAllCheckType(_res);
            })
            .catch(_err => {
                log(_err)
            })
    }


    function dateFormat(originalDate) {
        // Ensure originalDate is a Date object
        const date = new Date(originalDate);

        // Check if date is valid
        if (isNaN(date.getTime())) {
            alert("Invalid date");
            return null;  // Return null or handle the error as needed
        }

        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }

    const handleVisitSlotChange = (event) => {
        setAllInspector([]);
        setSelectedInspectors([]);
        setVisitSlot(event.target.value);


        if (selectedVisitdate) {

            const dateIndex = new Date(selectedVisitdate);
            let dayIndex = (dateIndex.getDay() + 6) % 7 + 1; // Monday as 1, Sunday as 7

            let payload = {
                startDate: dateFormat(selectedVisitdate),
                inspectorId: [],
                dayIndex: dayIndex,
                timeSlots: [event.target.value]
            }
            fetchInspector(payload);
        }
    }

    const callInspectorFilterFromDate = (_newValue) => {
        setAllInspector([]);
        setSelectedInspectors([]);
        const dateIndex = new Date(_newValue);
        let dayIndex = (dateIndex.getDay() + 6) % 7 + 1; // Monday as 1, Sunday as 7

        if (visiSlot) {
            let payload = {
                startDate: dateFormat(_newValue),
                inspectorId: [],
                dayIndex: dayIndex,
                timeSlots: [visiSlot]
            }

            fetchInspector(payload);
        }
    }

    const fetchInspector = (payload = true) => {
        post(moduleInspectorAttributes + '/filter', payload)
            .then((_res: any) => {
                setAllInspector(_res);
            })
            .catch(_err => {
                log(_err)
            })
    }

    //for edit mode
    const getInspectorById = (_inspectorId) => {
        getById(moduleInspectorAttributes + '/inspector', _inspectorId)
            .then((_res: any) => {
                setAllInspector([_res]);
                setValue('inspectorId', _res.userId)
            })
            .catch(_err => {
                log(_err)
            })
    }

    useEffect(() => {
        if (parentId) {
            getAllChildByParent(parentId); //get all property type child
        }
    }, [parentId]);

    useEffect(() => {
        if (parentId || childId) {
            getAllParentChild();
        }
    }, [parentId, childId]);

    useEffect(() => {
        if (parentId && childId && noOfrooms && add) {
            getPrepopulatedTemplate();
        }

        if(!add && !onceFlag){

            if (recordToUpdate.bookingBookingroomtype.length > 0) {
                const mappedData = recordToUpdate.bookingBookingroomtype.map(item => ({
                    parentId: item.bookingroomtypeRoomtype.id,
                    childId: item.bookingroomtypeChildRoomtype.id
                }));
                setRoomSelections(mappedData)
            }

            setOnceFlag(true);

        } else if (parentId && childId && noOfrooms && !add && onceFlag) {
            getPrepopulatedTemplate();
        }

    }, [parentId, childId, noOfrooms]);

    useEffect(() => {
        if (allParent.length > 0 && !add) {
            setParentId(recordToUpdate.ptParentId);
        }
    }, [allParent]);

    useEffect(() => {
        if (allChild.length > 0 && !add) {
            setChildId(recordToUpdate.ptChildId);
        }
    }, [allChild]);

    // useEffect(() => {
    //     if(!add && !onceFlag){

    //         if (recordToUpdate.bookingBookingroomtype.length > 0) {
    //             const mappedData = recordToUpdate.bookingBookingroomtype.map(item => ({
    //                 parentId: item.bookingroomtypeRoomtype.id,
    //                 childId: item.bookingroomtypeChildRoomtype.id
    //             }));
    //             setRoomSelections(mappedData)
    //         }

    //         setOnceFlag(true);
    //     }
    // }, [onceFlag]);
    

    // hooks
    useEffect(() => {
        fetchCheckType();
        fetchCustombookingform();
        getAllParent(); //get all parent of property type

        if (!add) {
            reset(recordToUpdate);

            setSelectedVisitdate(recordToUpdate.visitDate);
            setVisitSlot(recordToUpdate.visitSlot);
            setNoOfrooms(recordToUpdate.numberOfBedrooms);

            // if (recordToUpdate.bookingBookingroomtype.length > 0) {
            //     const mappedData = recordToUpdate.bookingBookingroomtype.map(item => ({
            //         parentId: item.bookingroomtypeRoomtype.id,
            //         childId: item.bookingroomtypeChildRoomtype.id
            //     }));
            //     setRoomSelections(mappedData)
            // }


            if (recordToUpdate.bookingBookinginspector.length > 0) {
                let tempArray = [];

                // Map through bookingBookinginspector to create initialSelectedInspectors array and populate tempArray
                const initialSelectedInspectors = recordToUpdate.bookingBookinginspector.map(inspector => {
                    // Push the inspectorId into tempArray
                    tempArray.push(inspector.inspectorId);

                    // Return the object to create initialSelectedInspectors
                    return {
                        userId: inspector.inspectorId,
                        'inspectorAttributesUser.email': inspector.bookinginspectorUser.email,
                    };
                });
                setPrevInspectorId(tempArray)
                setAllInspector(initialSelectedInspectors)
                setSelectedInspectors(initialSelectedInspectors)
            }
        }
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <Card className="full-width mx-5">
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">

                            {/* Contact Details */}
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h4 className="pb-2">
                                        {t('booking.contactDetails')}
                                    </h4>
                                </div>

                                {/* Field clientName */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="clientNameInput"
                                            {...register("clientName", { required: false })}
                                            label={t('booking.clientName')}
                                            type={"text"}
                                            error={!!errors.clientName}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field clientEmail */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="clientEmailInput"
                                            {...register("clientEmail", { required: false })}
                                            label={t('booking.clientEmail')}
                                            type={"text"}
                                            error={!!errors.clientEmail}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field clientContact */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="clientContactInput"
                                            {...register("clientContact", { required: false })}
                                            label={t('booking.clientContact')}
                                            type={"text"}
                                            error={!!errors.clientContact}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>


                            </div>

                            {/* Property Details */}
                            <div className="row">

                                <div className="col-md-12 mt-5">
                                    {/* Form name */}
                                    <h4 className="pb-2">
                                        {t('booking.propertyDetails')}
                                    </h4>
                                </div>

                                {/* Field checkType */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="checkTypeLabel">
                                            {t('booking.checkType')}
                                        </InputLabel>
                                        {allCheckType.length > 0 &&
                                            <Select labelId="checkTypeLabel"
                                                id="checkTypeSelect"
                                                {...register("checkTypeId", { required: false })}
                                                error={!!errors.checkType}
                                                value={watch("checkTypeId")}
                                                label={t('booking.checkType')}>
                                                {
                                                    allCheckType.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allCheckType.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="checkTypeSelect"
                                                    {...register("checkTypeId", { required: false })}
                                                    error={!!errors.checkType}
                                                    value={watch("checkTypeId")}
                                                    label={t('booking.checkType')}>
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>


                                {/* Parent Name */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="ptParentIdLabel" shrink={Boolean(parentId)}>
                                            Parent Property Type
                                        </InputLabel>
                                        {allParent.length > 0 &&
                                            <Select labelId="ptParentIdLabel"
                                                id="ptParentIdSelect"
                                                {...register("ptParentId", { required: true })}
                                                error={!!errors.ptParentId}
                                                value={parentId}
                                                onChange={handleParent}
                                                label="Parent Property Type">
                                                {
                                                    allParent.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allParent.length == 0 &&
                                            <>
                                                <Select labelId="ptParentIdLabel"
                                                    disabled
                                                    id="ptParentIdSelect"
                                                    label="Parent Property Type">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Child Name */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="ptChildIdLabel" shrink={Boolean(childId)}>
                                            Child Property Type
                                        </InputLabel>
                                        {allChild.length > 0 &&
                                            <Select labelId="ptChildIdLabel"
                                                id="ptChildIdSelect"
                                                {...register("ptChildId", { required: true })}
                                                error={!!errors.ptChildId}
                                                value={childId}
                                                onChange={handleChild}
                                                label="Child Property Type">
                                                {
                                                    allChild.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allChild.length == 0 &&
                                            <>
                                                <Select labelId="ptChildIdLabel"
                                                    disabled
                                                    id="ptChildIdSelect"
                                                    label="Child Property Type">
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* no.of room */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="numberOfBedroomsLabel">
                                            Number of Rooms
                                        </InputLabel>
                                        <Select labelId="numberOfBedroomsLabel"
                                            id="numberOfBedroomsSelect"
                                            {...register('numberOfBedrooms', { required: true })}
                                            error={!!errors.numberOfBedrooms}
                                            value={noOfrooms}
                                            onChange={handleNoOfRooms}
                                            label="Number of Rooms">

                                            <MenuItem value="Studio" key="0">Studio</MenuItem>
                                            <MenuItem value="1" key="1">1</MenuItem>
                                            <MenuItem value="2" key="2">2</MenuItem>
                                            <MenuItem value="3" key="3">3</MenuItem>
                                            <MenuItem value="4" key="4">4</MenuItem>
                                            <MenuItem value="5" key="5">5</MenuItem>
                                            <MenuItem value="6" key="6">6</MenuItem>
                                            <MenuItem value="7" key="7">7</MenuItem>
                                            <MenuItem value="8" key="8">8</MenuItem>
                                            <MenuItem value="9" key="9">9</MenuItem>
                                            <MenuItem value="10" key="10">10</MenuItem>
                                            <MenuItem value="11" key="11">11</MenuItem>
                                            <MenuItem value="12" key="12">12</MenuItem>
                                            <MenuItem value="13" key="13">13</MenuItem>
                                            <MenuItem value="14" key="14">14</MenuItem>
                                            <MenuItem value="15" key="15">15</MenuItem>



                                        </Select>
                                    </FormControl>
                                </div>

                                {/* Field unit */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="unitInput"
                                            {...register("unit", { required: true })}
                                            label={t('booking.unit')}
                                            type={"text"}
                                            error={!!errors.unit}
                                            variant="outlined"
                                            placeholder={'13'}
                                            className="full-width shadow-lg" />
                                    </FormControl>
                                </div>

                                {!add && !update &&
                                    <div className="col-md-4 mt-4"></div>
                                }


                                {!add && !update &&
                                    <>
                                        {/* Area */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField
                                                    readOnly
                                                    label={t('booking.area')}
                                                    type={"text"}
                                                    value={recordToUpdate?.area}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>

                                        {/* Community */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField
                                                    readOnly
                                                    label={t('booking.community')}
                                                    type={"text"}
                                                    value={recordToUpdate?.community}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>
                                    </>
                                }

                                {(add || update) &&
                                    <>
                                        {/* Field area */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    className="full-width shadow-lg"
                                                    id="areas"
                                                    loading={loadAreas}
                                                    options={areas}
                                                    groupBy={(option) => option.group}
                                                    getOptionLabel={(option) => option.name}
                                                    onInputChange={(e, v) => {
                                                        onAreaInputChange(e, v)
                                                    }}
                                                    onChange={(e, v) => {
                                                        setSelectedArea(v.name);
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            placeholder={'Al Reem Island'}
                                                            variant="outlined"
                                                            {...register("area", { required: true })}
                                                            label={t('booking.area')} />}
                                                    renderGroup={(params) => (
                                                        <li key={params.key}>
                                                            <GroupHeader>{params.group}</GroupHeader>
                                                            <GroupItems>
                                                                {params.children.map((option) => (
                                                                    <li key={option.name}>
                                                                        {option}
                                                                    </li>
                                                                ))}
                                                            </GroupItems>
                                                        </li>
                                                    )}
                                                />

                                            </FormControl>

                                        </div>

                                        {/* Field community */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={communities}
                                                    onInputChange={(e, v) => {
                                                        onCommunityInputChange(e, v)
                                                    }}
                                                    loading={loadCommunities}
                                                    onChange={(e, v) => {
                                                        setSelectedCommunity(v);
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                            label={t('booking.community') + '/Building'}
                                                            placeholder={'Yas Bay'}
                                                            {...register("community", { required: true })}
                                                            variant="outlined"
                                                            className="full-width shadow-lg" />}
                                                />
                                            </FormControl>
                                        </div>
                                    </>
                                }


                                {!add && !update &&
                                    <div className="col-md-4 mt-4 py-2 d-flex justify-content-start">
                                        <Button
                                            variant="contained"
                                            onClick={() => { setUpdate(true) }}
                                        >
                                            Click to update Area/Community
                                        </Button>
                                    </div>
                                }

                            </div>

                            {/* Schedule and book */}
                            <div className="row">

                                <div className="col-md-12 mt-5">
                                    {/* Form name */}
                                    <h4 className="pb-2">
                                        {t('booking.schedule')} & {t('booking.inspector')}
                                    </h4>
                                </div>

                                {/* Field visitDate */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <DatePicker
                                            label={t('booking.visitDate')}
                                            value={selectedVisitdate}
                                            onChange={(_newValue) => {
                                                setSelectedVisitdate(_newValue);
                                                setValue("visitDate", _newValue, {
                                                    shouldValidate: true,
                                                    shouldDirty: true
                                                });
                                                callInspectorFilterFromDate(_newValue);
                                            }}
                                            minDate={today}    // Set the minimum selectable date to today
                                            maxDate={nextSevenDays} // Set the maximum selectable date to 7 days from today

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    {...register("visitDate", { required: false })}
                                                    error={!!errors.visitDate}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field visitSlot */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <InputLabel id="visitSlotInput">
                                            {t('booking.visitSlot')}
                                        </InputLabel>
                                        {timeSlots.length > 0 &&
                                            <Select labelId="visitSlotInput"
                                                id="visitSlotInput"
                                                value={visiSlot}
                                                onChange={handleVisitSlotChange}
                                                disabled={!selectedVisitdate}
                                                label={t('booking.visitSlot')}>
                                                {
                                                    timeSlots.map((el: any) =>
                                                        <MenuItem value={el}
                                                            key={el}> {el}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {timeSlots.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="visitSlotInput"
                                                    label={t('booking.visitSlot')}>
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>

                                </div>

                                {selectedVisitdate && visiSlot &&
                                    <>
                                        {/* Field Inspector */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth={true}>
                                                {allInspector.length > 0 && (
                                                    <Autocomplete
                                                        multiple
                                                        id="inspectorIdSelect"
                                                        options={allInspector}
                                                        getOptionLabel={(option) => option['inspectorAttributesUser.email']}
                                                        value={selectedInspectors}  // Use state for the value
                                                        onChange={(event, newValue) => {
                                                            setSelectedInspectors(newValue);  // Update state on change
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={t('booking.inspector')}
                                                            // error={selectedInspectors.length === 0}  // Simple required field validation
                                                            // helperText={selectedInspectors.length === 0 ? 'This field is required' : ''}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {allInspector.length === 0 && (
                                                    <>
                                                        <Autocomplete
                                                            multiple
                                                            id="inspectorIdLabel"
                                                            options={[]}  // No options available
                                                            getOptionLabel={(option) => option['inspectorAttributesUser.email']}
                                                            disabled
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={t('booking.inspector')}
                                                                // error={selectedInspectors.length === 0}  // Simple required field validation
                                                                // helperText={selectedInspectors.length === 0 ? 'This field is required' : ''}
                                                                />
                                                            )}
                                                        />
                                                        <FormHelperText>
                                                            No Data
                                                        </FormHelperText>
                                                    </>
                                                )}
                                            </FormControl>
                                        </div>

                                    </>
                                }

                                {/* Special Instruction */}
                                <div className="col-md-12 mt-4">
                                    <FormControl fullWidth className='mt-2'>
                                        <TextField id="specialInstructionInput"
                                            {...register("specialInstruction", { required: false })}
                                            label={t('booking.specialInst')}
                                            type={"text"}
                                            multiline
                                            minRows={4}
                                            error={!!errors.specialInstruction}
                                            variant="outlined"
                                            placeholder={t('booking.specialInst')}
                                            className="full-width" />
                                    </FormControl>
                                </div>

                            </div>

                            {/* Booking Room Type */}
                            <div className="row">

                                <div className="col-md-12 mt-5">
                                    {/* Form name */}
                                    <h4 className="pb-2">
                                        {t('booking.bookingRoomType')}
                                    </h4>
                                </div>

                                {/* room type section */}
                                <div>

                                    {/* Dynamically generated parent and child select boxes */}
                                    {roomSelections.map((selection, index) => (
                                        <div className='row mt-4'>
                                            <div className="col-md-5 mt-4">
                                                <FormControl fullWidth>
                                                    <InputLabel id={`parentLabel-${index}`}>Parent Room Type</InputLabel>
                                                    {Object.entries(parentChildData).length > 0 ? (
                                                        <Select
                                                            labelId={`parentLabel-${index}`}
                                                            value={selection?.parentId || ''}
                                                            onChange={(event) => handleParentChange(index, event.target.value)}
                                                            label="Parent Room Type"
                                                        >
                                                            {Object.entries(parentChildData).map(([parentId, parent]) => (
                                                                <MenuItem key={parentId} value={parentId}>
                                                                    {parent.parentName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    ) : (
                                                        <Select
                                                            labelId={`parentLabel-${index}`}
                                                            disabled
                                                            value=""
                                                            label="Parent Room Type"
                                                        >
                                                            <MenuItem value="">
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                    )}
                                                </FormControl>
                                            </div>

                                            <div className="col-md-5 mt-4">
                                                <FormControl fullWidth>
                                                    <InputLabel id={`childLabel-${index}`}>Child Room Type</InputLabel>
                                                    {selection?.parentId && parentChildData[selection.parentId]?.children.length > 0 ? (
                                                        <Select
                                                            labelId={`childLabel-${index}`}
                                                            value={selection?.childId || ''}
                                                            onChange={(event) => handleChildChange(index, event.target.value)}
                                                            label="Child Room Type"
                                                            disabled={!selection?.parentId} // Disable if no parent is selected
                                                        >
                                                            {parentChildData[selection.parentId]?.children.map((child) => (
                                                                <MenuItem key={child.id} value={child.id}>
                                                                    {child.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    ) : (
                                                        <Select
                                                            labelId={`childLabel-${index}`}
                                                            disabled
                                                            value=""
                                                            label="Child Room Type"
                                                        >
                                                            <MenuItem value="">
                                                                No Data
                                                            </MenuItem>
                                                        </Select>
                                                    )}
                                                    {selection?.parentId && parentChildData[selection.parentId]?.children.length === 0 && (
                                                        <FormHelperText>No Data</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>

                                            <div className="col-md-2 mt-4">
                                                {/* Button to remove the current row */}
                                                <IconButton aria-label="delete" onClick={() => removeRoom(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))}

                                    {/* Button to dynamically add a new row */}
                                    <Button variant="contained" color="primary" onClick={addRoom} className='mt-4'>
                                        Add Room
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>
                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {!add ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box >
    );
}

export default BookingForm;
