// @ts-nocheck
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, FormControl, TextField, Select, MenuItem, InputLabel, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getAll, getById } from '../../../../../../Services/GenericApiService';
import { log } from "../../../../../../Services/LoggerService";
import { eventNames } from 'process';

// @ts-ignore
function IssueForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {

    // translation
    const { t } = useTranslation();

    const moduleCategory = 'category';
    const moduleSubCategory = 'subcategory';


    //data vars
    const [allCategory, setAllCategory] = useState([]);
    const [allSubCategory, setAllSubCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isSubCalled, setIsSubCalled] = useState(false);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // UI toggles
    const handleCategoryChange = (event) => {
        setValue('categoryId', event.target.value);
        setValue('name', '');
        fetchSubCategoryByParentCategory(event.target.value);
    };

    // fetch dependent data
    const fetchAllCategory = () => {
        getAll(moduleCategory)
            .then((_res: any) => {
                // set all data
                setAllCategory(_res);
            })
            .catch(_err => {
                log(_err)
            })
    }

    // fetch dependent data
    const fetchSubCategoryByParentCategory = (_categoryId) => {
        getById(moduleSubCategory + "/category", _categoryId)
            .then((_res: any) => {
                // set all data
                setAllSubCategory(_res);
            })
            .catch(_err => {
                log(_err)
            })
    }

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // hooks
    useEffect(() => {
        fetchAllCategory();
        if (isEditMode) {
            reset(recordToUpdate);
        }
    }, []);

    useEffect(() => {
        if (isEditMode && allCategory.length > 0) {
            setValue('categoryId', recordToUpdate.categoryId);
            fetchSubCategoryByParentCategory(recordToUpdate.categoryId);
        }
    }, [allCategory]);  // Add all dependencies here


    // hooks
    useEffect(() => {
        if (isEditMode && !isSubCalled && allSubCategory.length > 0) {
            setValue('subcategoryId', recordToUpdate.subcategoryId);
            setValue('name', recordToUpdate.name);
            setIsSubCalled(true);
        }
    }, [allSubCategory]);

    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.issue')}
                                    </h3>

                                </div>
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="categoryIdLabel">
                                            {t('issue.category')}
                                        </InputLabel>
                                        {allCategory.length > 0 && (
                                            <Select
                                                labelId="categoryIdLabel"
                                                id="categoryIdSelect"
                                                {...register("categoryId", { required: true })}
                                                error={!!errors.categoryId}
                                                value={watch('categoryId')}
                                                label={t('issue.category')}
                                                onChange={handleCategoryChange}
                                            >
                                                {allCategory.map((el: any) => (
                                                    <MenuItem value={el.id} key={el.id}>
                                                        {el.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                        {allCategory.length === 0 && (
                                            <>
                                                <Select
                                                    labelId="categoryIdLabel"
                                                    disabled
                                                    id="categoryIdSelect"
                                                    error={!!errors.categoryId}
                                                    label={t('issue.category')}
                                                >
                                                    <MenuItem value={''}>No Data</MenuItem>
                                                </Select>
                                                <FormHelperText>No Data</FormHelperText>
                                            </>
                                        )}
                                    </FormControl>
                                </div>

                                {/* sub category */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="subcategoryIdLabel">
                                            {t('issue.subCategory')}
                                        </InputLabel>
                                        {allSubCategory.length > 0 && (
                                            <Select
                                                labelId="subcategoryIdLabel"
                                                id="subcategoryIdSelect"
                                                {...register("subcategoryId", { required: true })}
                                                error={!!errors.subcategoryId}
                                                value={watch('subcategoryId')}
                                                label={t('issue.subCategory')}
                                                disabled={!watch("categoryId")}
                                            >
                                                {allSubCategory.map((el: any) => (
                                                    <MenuItem value={el.id} key={el.id}>
                                                        {el.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                        {allSubCategory.length === 0 && (
                                            <>
                                                <Select
                                                    labelId="subcategoryIdLabel"
                                                    disabled
                                                    id="subcategoryIdSelect"
                                                    error={!!errors.subcategoryId}
                                                    label={t('issue.subCategory')}
                                                    disabled={!watch("categoryId")}
                                                >
                                                    <MenuItem value={''}>No Data</MenuItem>
                                                </Select>
                                            </>
                                        )}
                                    </FormControl>
                                </div>


                                {/* Field name */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="nameInput"
                                            {...register("name", { required: true })}
                                            label={t('category.name')}
                                            type={"text"}
                                            error={!!errors.name}
                                            disabled={!watch("categoryId") || !watch("subcategoryId")}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default IssueForm;
