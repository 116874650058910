// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { getAll } from "../../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { log } from "../../../../../Services/LoggerService";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, TextField, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from '@mui/lab';


// @ts-ignore
function CustombookingformdataForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {

    // module(s) for api
    const moduleMain = 'custombookingformdata';

    // linked components API modules (endpoints)
    const moduleCustombookingform = 'custombookingform';
    const moduleChecktype = 'checktype';

    // translation
    const { t } = useTranslation();

    // UI methods
    const [loading, setLoading] = React.useState(true);

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    // Custom function to convert string to title case
    const toTitleCase = (str) => {
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    };

    // form methods
    const onSubmit = (_data: any) => {
        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            _data.visitSlot = visiSlot
            handleEditEvent(_data);
        }
    }

    // data vars
    const [isCheckedAddreinspection, setIsCheckedAddreinspection] = useState(false);
    const [isClientPaid, setIsClientPaid] = useState(false);
    const [isAgentPaid, setIsAgentPaid] = useState(false);
    const [selectedVisitdate, setSelectedVisitdate] = useState<Date | null>(null);
    const [allCheckType, setAllCheckType] = useState([]);
    const [visiSlot, setVisitSlot] = useState('');
    const [allPropertyType, setAllPropertyType] = useState(['apartment', 'villa']);
    const [allSlots, setAllSlots] = useState([]);
    const [timeSlots, setTimeSlots] = useState([
        '8 AM - 12 PM',
        '9 AM - 1 PM',
        '2 PM - 6 PM',
    ]);
    const today = new Date();
    const nextSevenDays = new Date(today);
    nextSevenDays.setDate(today.getDate() + 6);

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // fetch dependent data
    const [allCustombookingform, setAllCustombookingform] = React.useState([]);

    const handleVisitSlotChange = (event) => {
        setVisitSlot(event.target.value)
    }

    const getSlot = (value) => {

        //extract date
        const dateObject = new Date(value);
        const dateOfMonth = dateObject.getDate();

        const recordToDate = new Date(recordToUpdate?.visitDate);
        const day = recordToDate.getUTCDate();

        //selected date is booking date
        if (recordToUpdate?.visitDate && (dateOfMonth === day)) {
            const newTimeSlots = Object.keys(allSlots[dateOfMonth]).filter(slot => allSlots[dateOfMonth][slot]);
            if (!newTimeSlots.includes(recordToUpdate.visitSlot)) {
                newTimeSlots.push(recordToUpdate.visitSlot);
                setTimeSlots(newTimeSlots)
            }
        }
        else {
            const newTimeSlots = Object.keys(allSlots[dateOfMonth]).filter(slot => allSlots[dateOfMonth][slot]);
            setTimeSlots(newTimeSlots);
        }

    }

    // fetch avaiable visitSlot
    const fetchAvailableVisitSlot = () => {
        setLoading(true); // show loader
        getAll(moduleMain + '/available/visitSlot')
            .then((_res: any) => {
                setAllSlots(_res);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const fetch = () => {
        fetchCustombookingform();
        fetchCheckType();
        fetchAvailableVisitSlot();
    }

    // fetch Custombookingform
    const fetchCustombookingform = () => {
        setLoading(true); // show loader
        getAll(moduleCustombookingform)
            .then((_res: any) => {
                setAllCustombookingform(_res)
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }


    // fetch Custombookingform
    const fetchCheckType = () => {
        setLoading(true); // show loader
        getAll(moduleChecktype)
            .then((_res: any) => {
                setAllCheckType(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // hooks
    useEffect(() => {
        fetch();
        if (isEditMode) {
            reset(recordToUpdate);
            setIsCheckedAddreinspection(recordToUpdate.addReInspection);
            setIsAgentPaid(recordToUpdate.agentPaid);
            setIsClientPaid(recordToUpdate.clientPaid);


        }
    }, []);

    // hooks
    useEffect(() => {
        if (isEditMode && allCheckType.length > 0) {
            setValue('checkTypeId', recordToUpdate?.checkTypeId)
        }

    }, [allCheckType]);


    useEffect(() => {
        if (isEditMode && Object.keys(allSlots).length != 0) {
            setSelectedVisitdate(recordToUpdate.visitDate);
            setVisitSlot(recordToUpdate.visitSlot);

            if (recordToUpdate.visitDate) {
                const givenDate = new Date(recordToUpdate.visitDate);
                const currentDate = new Date();

                givenDate.setHours(0, 0, 0, 0);
                currentDate.setHours(0, 0, 0, 0);

                //if prev date show only booking
                if (givenDate < currentDate) {
                    setTimeSlots([recordToUpdate.visitSlot])
                }

                //if today date show booking slot + available booking slot
                else if (givenDate.getDate() === currentDate.getDate() && givenDate.getMonth() === currentDate.getMonth() &&
                    givenDate.getFullYear() === currentDate.getFullYear()) {
                    let temp = givenDate.getDate()

                    const newTimeSlots = Object.keys(allSlots[temp]).filter(slot => allSlots[temp][slot]);

                    if (!newTimeSlots.includes(recordToUpdate.visitSlot)) {
                        newTimeSlots.push(recordToUpdate.visitSlot);
                        setTimeSlots(newTimeSlots)
                    }

                }
            }
        }
    }, [allSlots])

    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.custombookingformdata')}
                                    </h3>

                                </div>



                                {/* Field agentName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="agentNameInput"
                                            {...register("agentName", { required: false })}
                                            label={t('custombookingformdata.agentName')}
                                            type={"text"}
                                            error={!!errors.agentName}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field agentEmail */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="agentEmailInput"
                                            {...register("agentEmail", { required: false })}
                                            label={t('custombookingformdata.agentEmail')}
                                            type={"text"}
                                            error={!!errors.agentEmail}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field agentContact */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="agentContactInput"
                                            {...register("agentContact", { required: false })}
                                            label={t('custombookingformdata.agentContact')}
                                            type={"text"}
                                            error={!!errors.agentContact}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field clientName */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="clientNameInput"
                                            {...register("clientName", { required: false })}
                                            label={t('custombookingformdata.clientName')}
                                            type={"text"}
                                            error={!!errors.clientName}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field clientEmail */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="clientEmailInput"
                                            {...register("clientEmail", { required: false })}
                                            label={t('custombookingformdata.clientEmail')}
                                            type={"text"}
                                            error={!!errors.clientEmail}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field clientContact */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="clientContactInput"
                                            {...register("clientContact", { required: false })}
                                            label={t('custombookingformdata.clientContact')}
                                            type={"text"}
                                            error={!!errors.clientContact}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field checkType */}
                                <div className="col-md-6 mt-4">

                                    <FormControl fullWidth={true}>
                                        <InputLabel id="checkTypeLabel">
                                            {t('custombookingformdata.checkType')}
                                        </InputLabel>
                                        {allCheckType.length > 0 &&
                                            <Select labelId="checkTypeLabel"
                                                id="checkTypeSelect"
                                                {...register("checkTypeId", { required: false })}
                                                error={!!errors.checkType}
                                                value={watch("checkTypeId")}
                                                label={t('custombookingformdata.checkType')}>
                                                {
                                                    allCheckType.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allCheckType.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="checkTypeSelect"
                                                    {...register("checkTypeId", { required: false })}
                                                    error={!!errors.checkType}
                                                    value={watch("checkTypeId")}
                                                    label={t('custombookingformdata.checkType')}>
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>

                                </div>

                                {/* Field addReInspection */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth error={!!errors.addReInspection}>
                                        <FormControlLabel
                                            {...register("addReInspection", {})}
                                            label={t('custombookingformdata.addReInspection')}
                                            control={<Checkbox checked={isCheckedAddreinspection}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsCheckedAddreinspection(_event.target.checked)
                                                    setValue("addReInspection", _event.target.checked);
                                                }} />}
                                        />
                                    </FormControl>
                                </div>


                                {/* Field propertyType */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <InputLabel id="propertyTypeLabel">
                                            {t('custombookingformdata.propertyType')}
                                        </InputLabel>
                                        {allPropertyType.length > 0 &&
                                            <Select labelId="propertyTypeLabel"
                                                id="propertyTypeSelect"
                                                {...register("propertyType", { required: false })}
                                                error={!!errors.propertyType}
                                                value={watch('propertyType')}
                                                label={t('custombookingformdata.propertyType')}>
                                                {
                                                    allPropertyType.map((el: any) =>
                                                        <MenuItem value={el}
                                                            key={el}> {toTitleCase(el)}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allPropertyType.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="propertyTypeSelect"
                                                    {...register("propertyType", { required: false })}
                                                    error={!!errors.propertyType}
                                                    value={watch('propertyType')}
                                                    label={t('custombookingformdata.propertyType')}>
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                {/* Field numberOfBedrooms */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="numberOfBedroomsInput"
                                            {...register("numberOfBedrooms", { required: false })}
                                            label={t('custombookingformdata.numberOfBedrooms')}
                                            type={"number"}
                                            error={!!errors.numberOfBedrooms}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field area */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="areaInput"
                                            {...register("area", { required: false })}
                                            label={t('custombookingformdata.area')}
                                            type={"text"}
                                            error={!!errors.area}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field community */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="communityInput"
                                            {...register("community", { required: false })}
                                            label={t('custombookingformdata.community')}
                                            type={"text"}
                                            error={!!errors.community}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field unit */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="unitInput"
                                            {...register("unit", { required: false })}
                                            label={t('custombookingformdata.unit')}
                                            type={"text"}
                                            error={!!errors.unit}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field visitDate */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <DatePicker
                                            label={t('custombookingformdata.visitDate')}
                                            value={selectedVisitdate}
                                            onChange={(_newValue) => {
                                                setSelectedVisitdate(_newValue);
                                                getSlot(_newValue);
                                                setValue("visitDate", _newValue, {
                                                    shouldValidate: true,
                                                    shouldDirty: true
                                                });
                                            }}
                                            minDate={today}    // Set the minimum selectable date to today
                                            maxDate={nextSevenDays} // Set the maximum selectable date to 7 days from today

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    {...register("visitDate", { required: false })}
                                                    error={!!errors.visitDate}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field visitSlot */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth>
                                        <InputLabel id="visitSlotInput">
                                            {t('custombookingformdata.visitSlot')}
                                        </InputLabel>
                                        {timeSlots.length > 0 &&
                                            <Select labelId="visitSlotInput"
                                                id="visitSlotInput"
                                                value={visiSlot}
                                                onChange={handleVisitSlotChange}
                                                disabled={!selectedVisitdate}
                                                label={t('custombookingformdata.visitSlot')}>
                                                {
                                                    timeSlots.map((el: any) =>
                                                        <MenuItem value={el}
                                                            key={el}> {el}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {timeSlots.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="visitSlotInput"
                                                    label={t('custombookingformdata.visitSlot')}>
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>

                                </div>

                                {/* Field formId */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="propertyTypeLabel">
                                            {t('custombookingformdata.formId')}
                                        </InputLabel>
                                        {allCustombookingform?.length > 0 ? (
                                            <Select
                                                labelId="propertyTypeLabel"
                                                id="partnerNameSelect"
                                                readOnly
                                                {...register("formId", { required: false })}
                                                error={!!errors.formId}
                                                value={watch('formId')}
                                                label={t('custombookingformdata.formId')}
                                            >
                                                {allCustombookingform.map((el: any) => (
                                                    <MenuItem key={el.id} value={el.id}>
                                                        {el.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        ) : (
                                            <>
                                                <Select
                                                    labelId="propertyTypeLabel"
                                                    disabled
                                                    id="partnerNameSelect"
                                                    {...register("formId", { required: false })}
                                                    error={!!errors.formId}
                                                    value={watch('formId')}
                                                    label={t('custombookingformdata.formId')}
                                                >
                                                    <MenuItem value={''}>No Data</MenuItem>
                                                </Select>
                                                <FormHelperText>No Data</FormHelperText>
                                            </>
                                        )}
                                    </FormControl>

                                </div>

                                {/* Field cient paid */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth error={!!errors.clientPaid}>
                                        <FormControlLabel
                                            {...register("clientPaid", {})}
                                            label={t('custombookingformdata.clientPaid')}
                                            control={<Checkbox checked={isClientPaid}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsClientPaid(_event.target.checked)
                                                    setValue("clientPaid", _event.target.checked);
                                                }} />}
                                        />
                                    </FormControl>
                                </div>

                                {/* Field agent paid */}
                                <div className="col-md-6 mt-4">
                                    <FormControl fullWidth error={!!errors.agentPaid}>
                                        <FormControlLabel
                                            {...register("agentPaid", {})}
                                            label={t('custombookingformdata.agentPaid')}
                                            control={<Checkbox checked={isAgentPaid}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsAgentPaid(_event.target.checked)
                                                    setValue("agentPaid", _event.target.checked);
                                                }} />}
                                        />
                                    </FormControl>
                                </div>


                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default CustombookingformdataForm;
