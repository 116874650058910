// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import { RootStateOrAny, useSelector } from "react-redux";
import { getAll, post, deleteById } from "../../../../../Services/GenericApiService";
import { formatData } from "../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import CustombookingformdataCreate from "../CustombookingformdataCreate/CustombookingformdataCreate";
import CustombookingformdataUpdate from "../CustombookingformdataUpdate/CustombookingformdataUpdate";
import CustombookingformdataView from "../CustombookingformdataView/CustombookingformdataView";
import { log } from "../../../../../Services/LoggerService";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { Button, IconButton, Skeleton, TextField, FormControl, FormHelperText, FormControlLabel, Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import ConfirmationDialog from "../../../../Helpers/ConfirmationDialog/ConfirmationDialog";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function CustombookingformdataMain() {
    // translation
    const { t } = useTranslation();

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'custombookingformdata';
    const moduleChecktype = 'checktype';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [allCheckType, setAllCheckType] = useState([]);
    const [isClientPaid, setIsClientPaid] = useState(false);
    const [isAgentPaid, setIsAgentPaid] = useState(false);
    const [isICBooking, setIsICBooking] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCheckType, setSelectedCheckType] = useState([]);
    const [selectedPropertyType, setSelectedPropertyType] = useState([]);
    const [allPropertyType, setAllPropertyType] = useState(['apartment', 'villa']);

    let columns = [
        { name: "Agent Email", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Client Email", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Check Type", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Property Type", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Visit Slot", options: { filter: true, filterType: 'checkbox', filterOptions: { fullWidth: true } } },
        { name: "Area", options: { filter: true, filterOptions: { fullWidth: true } } },
        { name: "Visit Date", options: { filter: false, filterOptions: { fullWidth: true } } },

        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => {
                    return { align: "right" }
                },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <Tooltip title={t('common.edit')}>
                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                        onEditClick(dataIndex, rowIndex)
                                    }}>
                                        <FontAwesomeIcon className="edit-btn" icon='edit' />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div className="px-2">
                                {/*  delete confirmation dialog  */}
                                <ConfirmationDialog title={'Delete Confirmation'}
                                    body={'Are you sure you want to delete this record?'}
                                    type={'delete'}
                                    isLoading={loading}
                                    dataItemIdx={dataIndex}
                                    confirmEvent={handleDelete} />
                            </div>

                            <div className="px-2">
                                {allData &&
                                    <CustombookingformdataView data={allData[rowIndex]} />
                                }
                            </div>

                        </div>
                    )
                        ;
                }
            }
        }
    ];
    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    // table ui controls
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleIcBookingChange = (data) => {
        if (data) {
            setToDate(null);
            setFromDate(null);
        }
    };

    // Custom function to convert string to title case
    const toTitleCase = (str) => {
        return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    };

    const clearFilter = () => {
        setFromDate(null);
        setToDate(null);
        setSelectedCheckType([]);
        setSelectedPropertyType([]);
        setIsAgentPaid(false);
        setIsAgentPaid(false);
        setIsICBooking(false);
        fetch(false);
    };

    function dateFormat(originalDate) {
        const year = originalDate.getFullYear();
        const month = ('0' + (originalDate.getMonth() + 1)).slice(-2);
        const day = ('0' + originalDate.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }

    // fetch Custombookingform
    const fetchCheckType = () => {
        setIsLoading(true);
        getAll(moduleChecktype)
            .then((_res: any) => {
                setAllCheckType(_res);
                setIsLoading(false);
            })
            .catch(_err => {
                log(_err);
                setIsLoading(false);
            })
    }

    // fetch dependent data
    const fetch = (_check = true) => {
        setTableData([]);  // reset table data
        setLoading(true); // show loader

        let sd = null;
        let ed = null;
        let clientPaid = false;
        let agentPaid = false;
        let icBooking = false;
        let pType = [];
        let cType = [];

        if (_check) {
            if (fromDate) {
                sd = dateFormat(fromDate);
            }

            if (toDate) {
                ed = dateFormat(toDate);
            }

            clientPaid = isClientPaid;
            agentPaid = isAgentPaid;
            icBooking = isICBooking;
            pType = selectedPropertyType;
            cType = selectedCheckType
        }


        let filterPayload = {
            page: currentPage,
            size: pageSize,
            startDate: sd,
            endDate: ed,
            isAgentPaid: agentPaid,
            isClientPaid: clientPaid,
            isICBooking: icBooking,
            propertyType: pType,
            checkType: cType
        }

        post(moduleMain + '/filter', filterPayload)
            .then((_res: any) => {
                // set all data
                setAllData(_res.rows)

                // set total count
                setTotalRowCount(_res.count);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.agentEmail, _record?.clientEmail, _record?.custombookingformdataChecktype?.name, _record?.propertyType === 'apartment' ? 'Studio' : _record?.propertyType, _record?.visitSlot, _record?.area, _record?.visitDate]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
        fetchCheckType();
    }, [currentPage, pageSize])

    const handleCheckTypeChange = (event) => {
        setSelectedCheckType(event.target.value);
    }

    const handlePropertyTypeChange = (event) => {
        setSelectedPropertyType(event.target.value);
    }

    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setDataToEdit(allData[_dataIndex]);
        setEditMode(true);
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }

    const handleDelete = (_dataItemIndex: number) => {
        setLoading(true);   // show spinner
        deleteById(moduleMain, allData[_dataItemIndex].id)
            .then(async (_res) => {
                setLoading(false);  // hide loader
                // @ts-ignore
                notifications.current.successAlert(t('common.deleteSuccess'), '');
                fetch();    // refresh data
            })
            .catch(_err => {
                log(_err)
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
                setLoading(false); // hide loader
            })
    }


    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: true,
        filter: true,
        // filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            setPageSize(numberOfRows);
        },
        page: currentPage,
        count: totalRowCount,
        serverSide: false,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);---------------------
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                {t('module.custombookingformdata')}
                            </h2>
                        </div>
                        <div className="col-md-2">
                            <CustombookingformdataCreate refreshDataEvent={fetch} />
                        </div>
                    </div>

                    {/* Upper Section */}
                    <div className={(theme == 'dark' ? 'bg-dark' : '') + " container-fluid card shadow-lg mt-4"}>
                        <div className="card-body">
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <h3>
                                        Filters
                                    </h3>
                                </div>
                            </div>
                            <div className="row mt-2">

                                <div className="col-md-3">
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={fromDate}
                                                disabled={isICBooking}
                                                onChange={handleFromDateChange}
                                                renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                label="From Date"
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>

                                <div className="col-md-3">
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={toDate}
                                                disabled={isICBooking}
                                                onChange={handleToDateChange}
                                                renderInput={(params) => <TextField {...params} variant="outlined" readOnly />}
                                                label="To Date"
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>

                                <div className="col-md-2">
                                    <FormControl fullWidth>
                                        <FormControlLabel
                                            label={t('custombookingformdata.clientPaid')}
                                            control={<Checkbox checked={isClientPaid}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsClientPaid(_event.target.checked)
                                                }} />}
                                        />
                                    </FormControl>
                                </div>

                                <div className="col-md-2">
                                    <FormControl fullWidth >
                                        <FormControlLabel
                                            label={t('custombookingformdata.agentPaid')}
                                            control={<Checkbox checked={isAgentPaid}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsAgentPaid(_event.target.checked)
                                                }} />}
                                        />
                                    </FormControl>
                                </div>

                                <div className="col-md-2">
                                    <FormControl fullWidth>
                                        <FormControlLabel
                                            label={t('custombookingformdata.icBooking')}
                                            control={<Checkbox checked={isICBooking}
                                                onChange={(_event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsICBooking(_event.target.checked)
                                                    handleIcBookingChange(_event.target.checked)

                                                }} />}
                                        />
                                    </FormControl>
                                </div>

                                {/* check type */}
                                <div className="col-md-3 mt-3">
                                    <FormControl fullWidth={true}>
                                        <InputLabel id="checkTypeLabel">
                                            {t('custombookingformdata.checkType')}
                                        </InputLabel>
                                        {allCheckType.length > 0 &&
                                            <Select labelId="checkTypeLabel"
                                                id="checkTypeSelect"
                                                value={selectedCheckType}
                                                onChange={handleCheckTypeChange}
                                                multiple
                                                label={t('custombookingformdata.checkType')}>
                                                {
                                                    allCheckType.map((el: any) =>
                                                        <MenuItem value={el.id}
                                                            key={el.id}> {el.name}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allCheckType.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="checkTypeSelect"
                                                    label={t('custombookingformdata.checkType')}>
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>

                                </div>

                                {/* Field propertyType */}
                                <div className="col-md-3 mt-3">
                                    <FormControl fullWidth>
                                        <InputLabel id="propertyTypeLabel">
                                            {t('custombookingformdata.propertyTypeSelect')}
                                        </InputLabel>
                                        {allPropertyType.length > 0 &&
                                            <Select labelId="propertyTypeLabel"
                                                id="propertyTypeSelect"
                                                multiple
                                                value={selectedPropertyType}
                                                onChange={handlePropertyTypeChange}
                                                label={t('custombookingformdata.propertyTypeSelect')}>
                                                {
                                                    allPropertyType.map((el: any) =>
                                                        <MenuItem value={el}
                                                            key={el}> {el === 'apartment' ? 'Studio' : toTitleCase(el)}
                                                        </MenuItem>)
                                                }
                                            </Select>
                                        }
                                        {allPropertyType.length == 0 &&
                                            <>
                                                <Select labelId="locationIdLabel"
                                                    disabled
                                                    id="propertyTypeSelect"
                                                    label={t('custombookingformdata.propertyType')}>
                                                    <MenuItem value={''}>
                                                        No Data
                                                    </MenuItem>
                                                </Select>
                                                <FormHelperText>
                                                    No Data
                                                </FormHelperText>
                                            </>
                                        }
                                    </FormControl>
                                </div>

                                <div className="col-md-3 mt-3">
                                    <Button
                                        variant="contained"
                                        onClick={fetch}
                                        className={'bg-custom-gradient py-3'}
                                        fullWidth>
                                        {t('common.filters')}
                                    </Button>
                                </div>
                                <div className="col-md-3 mt-3">
                                    <Button
                                        variant="contained"
                                        onClick={clearFilter}
                                        className={'bg-custom-gradient py-3'}
                                        fullWidth>
                                        {t('common.clearFilter')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>

                </div>

                {/* display edit modal */}
                {editMode &&
                    <div>
                        <CustombookingformdataUpdate refreshDataEvent={fetch}
                            resetEditState={resetEdit}
                            recordToUpdate={dataToEdit} />
                    </div>
                }


                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box >
    );
}

export default CustombookingformdataMain;
