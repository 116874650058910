// @ts-nocheck

import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import ContentPasteSearchTwoToneIcon from '@mui/icons-material/ContentPasteSearchTwoTone';
import TaskTwoToneIcon from '@mui/icons-material/TaskTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';

const Fade = require("react-reveal/Fade")
const Reveal = require("react-reveal/Reveal")

function WhiteLabelledFormBookingSummary({ _bookingSummary, _checkType, _rooms, _propertyType, _amount,_agentCountryCode,_clientCountryCode,_bookingFor }) {

    // translation
    const { t } = useTranslation();

    return (
        <>
            <div className="mb-5">
                <h1 className="gotham-black mb-3 text-center text-white full-upper-case">Check Details</h1>
            </div>

            <div className="d-flex flex-column flex-sm-row justify-content-between w-100">
                {/* Booked For */}
                <Fade>
                    <div className="card py-3 px-4 mx-md-2 mx-0 mb-3 mb-sm-0 w-100">
                        <Typography variant="subtitle1" color='primary' className="fw-bolder gotham-black mb-3 full-upper-case"><AccountCircleTwoToneIcon fontSize="large" color="primary" className={'mb-1'} /> Check For
                        </Typography>
                        <Divider />
                        <Typography variant="caption" className="p-0 m-0 mt-3 text-capitalize text-dark"><span className={'text-muted p-0 m-0 full-upper-case'}>Name</span>
                            <br /> {_bookingSummary.agentName ? _bookingSummary.agentName : _bookingSummary.clientName}</Typography>
                        <Typography variant="caption"  className="p-0 m-0 mt-3 text-dark"><span className={'text-muted p-0 m-0 full-upper-case'}>Contact</span>
                            <br /> +{_bookingFor === 'client' ? _agentCountryCode : _clientCountryCode}{_bookingSummary.agentContact ? _bookingSummary.agentContact : _bookingSummary.clientContact}</Typography>
                        <Typography variant="caption"  className="p-0 m-0 mt-3 text-dark"><span className={'text-muted p-0 m-0 full-upper-case'}>Email</span>
                            <br /> {_bookingSummary.agentEmail ? _bookingSummary.agentEmail : _bookingSummary.clientEmail}</Typography>
                    </div>
                </Fade>

                {/* Where */}
                {_bookingSummary.area && _bookingSummary.community &&
                    <Fade>
                        <div className="card py-3 px-4 mx-md-2 mx-0 mb-3 mb-sm-0 w-100">
                            <Typography variant="subtitle1" color='primary' className="fw-bolder gotham-black full-upper-case mb-3">&nbsp;<PlaceTwoToneIcon fontSize="large" className={'mb-1'}
                                color="primary" /> Address&nbsp;
                            </Typography>
                            <Divider />
                            <Typography variant="caption" className="p-0 m-0 mt-3 text-capitalize text-dark"><span
                                className={'text-muted p-0 m-0 full-upper-case'}>Unit</span>
                                <br /> {_bookingSummary.unit}</Typography>
                            <Typography variant="caption" className="p-0 m-0 mt-3 text-capitalize text-dark"><span
                                className={'text-muted p-0 m-0 full-upper-case'}>Community/Building</span> <br /> {_bookingSummary.community}</Typography>
                            <Typography variant="caption" className="p-0 m-0 mt-3 text-capitalize text-dark"><span
                                className={'text-muted p-0 m-0 full-upper-case'}>Area</span>
                                <br /> {_bookingSummary.area}</Typography>
                        </div>
                    </Fade>
                }

                {/* Check Type */}
                {_checkType && _propertyType && _rooms &&
                    <Fade>
                        <div className="card py-3 px-4 mx-md-2 mx-0 mb-3 mb-sm-0 w-100">
                            <Typography variant="subtitle1" color='primary' className="fw-bolder gotham-black mb-3 full-upper-case"><ContentPasteSearchTwoToneIcon fontSize="large" className={'mb-1'} color="primary" /> Check
                                Type
                            </Typography>
                            <Divider />
                            <Typography variant="caption" className="p-0 m-0 mt-3 text-dark"><span className={'text-muted p-0 m-0 full-upper-case'}>Check Type</span>
                                <br />{_checkType}</Typography>
                            <Typography variant="caption" className="p-0 m-0 mt-3 text-dark"><span className={'text-muted p-0 m-0 full-upper-case'}>Bedrooms</span>
                                <br />{_rooms} </Typography>
                            <Typography variant="caption" className="p-0 m-0 mt-3 text-dark text-capitalize"><span
                                className={'text-muted p-0 m-0 full-upper-case'}>Property Type</span> <br />{_propertyType}</Typography>
                        </div>
                    </Fade>
                }

            </div>

            <div className="d-flex flex-column flex-sm-row">
                <Fade>
                    <div className="card py-3 px-4 mx-md-2 mx-0 mb-3 mb-sm-0 w-100 mt-3">
                        <Typography variant="subtitle1" color='primary' className="fw-bolder gotham-black mb-3 full-upper-case"><TaskTwoToneIcon fontSize="large" className={'mb-1'} color="primary" /> Free Booking</Typography>
                        <Divider />
                        <div className="row">
                            <div className="col-md-8 text-dark">
                                <Typography variant="caption" className="p-0 m-0 mt-3">
                                    <CheckBoxTwoToneIcon /> World's most-detailed property inspection <br />
                                </Typography>
                                <Typography variant="caption" className="p-0 m-0 mt-3">
                                    <CheckBoxTwoToneIcon /> Every snag, every inch covered with world-first tech <br />
                                </Typography>
                                <Typography variant="caption" className="p-0 m-0 mt-3">
                                    <CheckBoxTwoToneIcon /> Full support before, during and after your Property Check <br />
                                </Typography>
                                <Typography variant="caption" className="p-0 m-0 mt-3">
                                    <CheckBoxTwoToneIcon /> Full transparency on your purchase <br />
                                </Typography>
                                <Typography variant="caption" className="p-0 m-0 mt-3">
                                    <CheckBoxTwoToneIcon /> Support with developers - we'll fight those battles for you <br />
                                </Typography>
                                <Typography variant="caption" className="p-0 m-0 mt-3">
                                    <CheckBoxTwoToneIcon /> Get everything fixed to your satisfaction before handover <br />
                                </Typography>
                            </div>
                            <div className="col-md-4 my-auto">
                                <Typography variant="subtitle1" className="p-0 m-0 mt-3 text-capitalize text-muted">Pay On The Day</Typography>
                                <Typography variant="h6" color='primary' className="fw-bolder gotham-black mb-3">
                                    {_amount}
                                </Typography>
                                <Typography  variant="caption" className="p-0 m-0 mt-3 text-dark">
                                    You'll be sent a payment link prior to your Property Check
                                </Typography>
                            </div>
                        </div>

                    </div>
                </Fade>
            </div>
        </>

    );
}


export default WhiteLabelledFormBookingSummary;
