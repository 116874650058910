// @ts-nocheck
import React from 'react';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";

import CssBaseline from '@mui/material/CssBaseline';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import InspectorManagementMain from '../InspectorManagement/InspectorManagementMain/InspectorManagementMain';
import InspectorAvailabilityMain from '../InspectorAvailability/InspectorAvailabilityMain/InspectorAvailabilityMain';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function InspectorDashboardNav() {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Inspector Management" value="1" />
                                    <Tab label="View Inspector Availability" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1"><InspectorManagementMain /></TabPanel>
                            <TabPanel value="2"><InspectorAvailabilityMain /></TabPanel>
                        </TabContext>
                    </Box>
                </div>
            </Box>
        </Box>
    );
}

export default InspectorDashboardNav;
