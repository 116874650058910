// @ts-nocheck
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    bgCustomAnimated: {
        // background: 'linear-gradient(100deg, #001155, #001155, #001155)',
        backgroundSize: '1600% 1600%',
        WebkitAnimation: 'AnimationName 27s ease infinite', // Note the capital 'W' in Webkit
        MozAnimation: 'AnimationName 27s ease infinite',    // Note the capital 'M' in Moz
        OAnimation: 'AnimationName 27s ease infinite',      // Note the capital 'O' in O
        animation: 'AnimationName 27s ease infinite',
    },
});

export { useStyles }
