// @ts-nocheck

import React, {useEffect, useState, useRef} from 'react';
import '../../../../../index.scss';
import Header from "../../../../Layout/Header/Header";
import Sider from "../../../../Layout/Sider/Sider";
import {getAll, deleteById} from "../../../../../Services/GenericApiService";
import {useTranslation} from "react-i18next";
import AlertM from '../../../../Helpers/AlertM/AlertM';
import CheckTypeCreate from "../CheckTypeCreate/CheckTypeCreate";
import CheckTypeUpdate from "../CheckTypeUpdate/CheckTypeUpdate";
import CheckTypeView from "../CheckTypeView/CheckTypeView";
import {log} from "../../../../../Services/LoggerService";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import {IconButton, Skeleton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function CheckTypeMain() {

    // translation
    const {t} = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'checktype';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});

    let columns = [
                {name: "Id", options: {filterOptions: {fullWidth: true}}},
                {name: "Name", options: {filterOptions: {fullWidth: true}}},

        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <Tooltip title={t('common.edit')}>
                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                            onEditClick(dataIndex, rowIndex)
                                        }}>
                                        <FontAwesomeIcon className="edit-btn" icon='edit'/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                                                       
                             <div className="px-2">
                                {allData &&
                                    <CheckTypeView data={allData[rowIndex]} />
                                }
                            </div>

                        </div>
                    )
                        ;
                }
            }
        }
    ];
    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    // fetch dependent data
    const fetch = () => {
        setTableData([]);  // reset table data
        setLoading(true); // show loader
        getAll(moduleMain, currentPage, pageSize)
            .then((_res: any) => {
                // set all data
                setAllData(_res.rows)

                // set total count
                setTotalRowCount(_res.count);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push([_record?.id,_record?.name])
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setDataToEdit(allData[_dataIndex]);
        setEditMode(true);
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }

    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => { setCurrentPage(currentPage); },
        onChangeRowsPerPage: (numberOfRows) => { setPageSize(numberOfRows); },
        page: currentPage,
        count: totalRowCount,
        serverSide:true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        }
    };

  if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            {/* Header */}
            <Header/>

            {/* Sider */}
            <Sider/>

            {/* Content */}
            <Box component="main" sx={{flexGrow: 2, p: 3}} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                            {t('module.checkType')}
                            </h2>
                        </div>
                        <div className="col-md-2">
                            <CheckTypeCreate refreshDataEvent={fetch}/>
                        </div>
                    </div>

                    <div className="mt-5">
                        <Paper elevation={12}>
                            <MUIDataTable
                                title={t('common.allRecords')}
                                data={tableData}
                                columns={columns}
                                // @ts-ignore
                                options={options}
                            />
                        </Paper>
                    </div>

                </div>

                {/* display edit modal */}
                {editMode &&
                    <div>
                        <CheckTypeUpdate refreshDataEvent={fetch}
                                        resetEditState={resetEdit}
                                        recordToUpdate={dataToEdit}/>
                    </div>
                }


                 {/* Alerts */}
                <AlertM ref={notifications}/>

            </Box>


        </Box>
    );
}

export default CheckTypeMain;
