// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import '../../../../../../index.scss';
import { getAll, deleteById } from "../../../../../../Services/GenericApiService";
import { formatData } from "../../../../../../Services/DataFormatService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../../../Helpers/AlertM/AlertM';
import InspectorManagementUpdate from '../InspectorManagementUpdate/InspectorManagementUpdate';
import InspectorManagementView from '../InspectorManagementView/InspectorManagementView';
import { log } from "../../../../../../Services/LoggerService";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { IconButton, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function InspectorManagementMain() {

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'inspectorattributes';

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [dataToEdit, setDataToEdit] = React.useState({});

    let columns = [
        { name: "Name", options: { filterOptions: { fullWidth: true } } },
        { name: "Email", options: { filterOptions: { fullWidth: true } } },
        { name: "Working Days/Week", options: { filterOptions: { fullWidth: true } } },
        { name: "Slot Per Day", options: { filterOptions: { fullWidth: true } } },
        { name: "Holiday Start Date", options: { filterOptions: { fullWidth: true } } },
        { name: "Holiday End Date", options: { filterOptions: { fullWidth: true } } },
        { name: "Holiday Reason", options: { filterOptions: { fullWidth: true } } },

        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <Tooltip title={t('common.edit')}>
                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                        onEditClick(dataIndex, rowIndex)
                                    }}>
                                        <FontAwesomeIcon className="edit-btn" icon='edit' />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div className="px-2">
                                {allData &&
                                    <InspectorManagementView data={allData[rowIndex]} />
                                }
                            </div>

                        </div>
                    )
                        ;
                }
            }
        }
    ];
    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [editMode, setEditMode] = React.useState(false);

    // table ui controls
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    // fetch dependent data
    const fetch = () => {
        setTableData([]);  // reset table data
        setLoading(true); // show loader
        getAll(moduleMain, currentPage, pageSize)
            .then((_res: any) => {
                // set all data
                setAllData(_res.rows)

                // set total count
                setTotalRowCount(_res.count);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.inspectorAttributesUser?.firstName+' '+_record?.inspectorAttributesUser?.lastName,
                    _record?.inspectorAttributesUser?.email,_record?.workDaysPerWeek, _record?.slotsPerDay,_record?.holidayStartDate,_record?.holidayEndDate,_record?.holidayReason
                    ]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setDataToEdit(allData[_dataIndex]);
        setEditMode(true);
    }

    const resetEdit = (_state) => {
        setEditMode(false);
    }


    let options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => { setCurrentPage(currentPage); },
        onChangeRowsPerPage: (numberOfRows) => { setPageSize(numberOfRows); },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
            // console.log(action);
            // console.dir(state);
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <Box>

            {/* Upper Section */}
            <div className="row mt-3">
                <div className="col-md-12">
                    <h2 className="mt-3">
                        {t('module.inspectorManagementMain')}
                    </h2>
                </div>
            </div>

            <div className="mt-5">
                <Paper elevation={12}>
                    <MUIDataTable
                        title={t('common.allRecords')}
                        data={tableData}
                        columns={columns}
                        // @ts-ignore
                        options={options}
                    />
                </Paper>
            </div>

            {/* display edit modal */}
            {editMode &&
                <div>
                    <InspectorManagementUpdate refreshDataEvent={fetch}
                        resetEditState={resetEdit}
                        recordToUpdate={dataToEdit} />
                </div>
            }


            {/* Alerts */}
            <AlertM ref={notifications} />

        </Box>
    );
}

export default InspectorManagementMain;
