// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Button, FormControl, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// @ts-ignore
function InspectorManagementForm({ handleAddEvent, handleCancelEvent, isLoading, isEditMode, recordToUpdate, handleEditEvent }) {

    // translation
    const { t } = useTranslation();

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    //data vars
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);



    //ui controls
    const [showHoliday, setShowHoliday] = useState(false);

    function convertToDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    const handleToDateChange = (date) => {
        setToDate(date);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleCheckboxChange = (event) => {
        setShowHoliday(event.target.checked);
    };

    // form methods
    const onSubmit = (_data: any) => {
        if (fromDate) {
            _data.holidayStartDate = convertToDate(fromDate);
        }
        if (toDate) {
            _data.holidayEndDate = convertToDate(toDate);
        }

        if (!isEditMode) {
            handleAddEvent(_data);
        } else {
            handleEditEvent(_data);
        }
    }

    // ui handlers
    const onCancel = () => {
        handleCancelEvent();
    }

    // hooks
    useEffect(() => {
        if (isEditMode) {
            setFromDate(recordToUpdate?.holidayStartDate);
            setToDate(recordToUpdate?.holidayEndDate);
            reset(recordToUpdate);
        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                {/* Form start */}
                <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="py-2 px-md-4 px-2">
                            <div className="row">

                                <div className="col-md-12 my-2">
                                    {/* Form name */}
                                    <h3 className="pb-2">
                                        {isEditMode ? t('common.edit') : t('common.add')} {t('module.inspectorManagementMain')}
                                    </h3>

                                </div>

                                {/* Field email */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="emailInput"
                                            value={recordToUpdate?.inspectorAttributesUser?.email}
                                            label={t('inspectorManagementMain.email')}
                                            type={"text"}
                                            disabled
                                            error={!!errors.email}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Working days per week */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="slotPerDayInput"
                                            {...register("workDaysPerWeek", { required: true })}
                                            label={t('inspectorManagementMain.workingDayPerWeek')}
                                            type={"number"}
                                            error={!!errors.slotPerDay}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field slot per day */}
                                <div className="col-md-4 mt-4">
                                    <FormControl fullWidth>
                                        <TextField id="slotPerDayInput"
                                            {...register("slotsPerDay", { required: true })}
                                            label={t('inspectorManagementMain.slotsPerDay')}
                                            type={"number"}
                                            error={!!errors.slotPerDay}
                                            variant="outlined"
                                            className="full-width" />
                                    </FormControl>
                                </div>

                                {/* Field isActive */}
                                <div className="col-md-12 mt-4">
                                    <FormControlLabel
                                        control={<Checkbox checked={showHoliday} onChange={handleCheckboxChange} />}
                                        label="Add Holiday"
                                    />
                                </div>

                                {showHoliday &&
                                    <>
                                        {/* Holiday Start Date */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        value={fromDate}
                                                        onChange={handleFromDateChange}
                                                        minDate={new Date()}
                                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                        label={t('inspectorManagementMain.holidayStartDate')}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>

                                        {/* Holiday End Date */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        value={toDate}
                                                        onChange={handleToDateChange}
                                                        minDate={new Date()}
                                                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                                                        label={t('inspectorManagementMain.holidayEndDate')}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>

                                        {/* Holiday reason */}
                                        <div className="col-md-4 mt-4">
                                            <FormControl fullWidth>
                                                <TextField id="holidayReason"
                                                    {...register("holidayReason", { required: toDate ? true : false })}
                                                    label={t('inspectorManagementMain.holidayReason')}
                                                    type={"text"}
                                                    error={!!errors.holidayReason}
                                                    variant="outlined"
                                                    className="full-width" />
                                            </FormControl>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                            {/* Cancel Button */}
                            <Button variant="outlined"
                                onClick={onCancel}>
                                {t('common.cancel')}
                            </Button>

                            &nbsp;&nbsp;

                            {/* Okay Button */}
                            <LoadingButton loading={isLoading}
                                type="submit"
                                variant="contained"
                                className="bg-custom-gradient">
                                {isEditMode ? t('common.edit') : t('common.add')}
                            </LoadingButton>
                            &nbsp;
                        </div>
                    </CardActions>
                </form>
            </Card>
        </Box>
    );
}

export default InspectorManagementForm;
