// @ts-nocheck

import React, { useEffect, useRef } from 'react';
import {
    Box, FormControl, Button, Skeleton,
    FormHelperText, InputLabel, MenuItem, Select, TextField,
} from "@mui/material";
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";
import { RootStateOrAny, useSelector } from "react-redux";
import { getAll, getById, patch } from "../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import AlertM from '../../../Helpers/AlertM/AlertM';
import { log } from "../../../../Services/LoggerService";
import CssBaseline from '@mui/material/CssBaseline';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';


// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function WhatsappTemplates() {

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'whatsapptemplate';

    // get theme from redux
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    // data vars
    const [allData, setAllData] = React.useState([]);
    const [selectedEmailTemplate, setSelectedEmailTemplate] = React.useState(0);
    const [showEmailTemplateFields, setShowEmailTemplateFields] = React.useState(false);
    const [content, setContent] = React.useState('');

    // ui controls
    const [loading, setLoading] = React.useState(true);


    // fetch dependent data
    const fetch = () => {
        setLoading(true); // show loader
        getAll(moduleMain)
            .then((_res: any) => {
                // set all data
                setAllData(_res);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    const handleEmailTemplateChange = (event: any) => {

        setSelectedEmailTemplate(event.target.value);
        fetchTemplateData(event.target.value);
        setShowEmailTemplateFields(true);
    };

    //get template by id
    const fetchTemplateData = (_id: any) => {
        setLoading(true); // show loader
        getById(moduleMain, _id)
            .then((_res: any) => {
                setContent(_res.content);
                setLoading(false);
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    // save template
    const onSubmit = () => {
        setLoading(true); // show loader
        let payload = {
            content: content,
        }
        patch(moduleMain, selectedEmailTemplate, payload)
            .then(async (_res) => {
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.successAlert('Template Successfully Updated', 'Success');

            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
                // @ts-ignore
                notifications.current.errorAlert(t('common.somethingWentWrong'), t('common.tryAgain'));
            })
    }

    useEffect(() => {
        fetch();
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* Header */}
            <Header />

            {/* Sider */}
            <Sider />

            {/* Content */}
            <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                <div className="px-1 px-md-5">

                    {/* Upper Section */}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="mt-3">
                                {t('module.whatsappTemplate')}
                            </h2>
                        </div>
                    </div>

                    <div className="mt-5">
                        {/* Template Form */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className={(theme == 'dark' ? 'bg-dark' : '') + " container card shadow-lg  mb-5 ms-0 me-0"}
                                >
                                    <div className="card-body mb-5 mt-2">
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="row">
                                                    <div className="col-8">
                                                        {!loading &&
                                                            <FormControl fullWidth={true}>
                                                                <InputLabel id="emailTemplateLabel">
                                                                    Whatsapp Template
                                                                </InputLabel>
                                                                {allData.length > 0 ? (
                                                                    <Select
                                                                        labelId="emailTemplateLabelLabel"
                                                                        id="emailTemplateLabelSelect"
                                                                        value={selectedEmailTemplate}
                                                                        onChange={handleEmailTemplateChange}
                                                                        label="Whatsapp Template"
                                                                    >
                                                                        {allData.map((el: any) => (
                                                                            <MenuItem value={el.id} key={el.id}>
                                                                                {el.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <>
                                                                        <Select
                                                                            labelId="emailTemplateLabelLabel"
                                                                            disabled
                                                                            id="emailTemplateLabelSelect"
                                                                            value={''} // Set an empty value to have no data selected
                                                                            label="emailTemplate"
                                                                        >
                                                                            <MenuItem value={''}>
                                                                                No Data
                                                                            </MenuItem>
                                                                        </Select>
                                                                        <FormHelperText>
                                                                            No Data
                                                                        </FormHelperText>
                                                                    </>
                                                                )}
                                                            </FormControl>
                                                        }
                                                        {loading &&
                                                            <Skeleton height={50} />
                                                        }
                                                    </div>
                                                    <div className="col-4 p-0 m-0">
                                                        {/* Save Button */}
                                                        {!loading &&
                                                            <Button
                                                                variant="contained"
                                                                disabled={!selectedEmailTemplate || !content}
                                                                onClick={onSubmit}
                                                                className={'bg-custom-gradient py-3'} fullWidth>
                                                                Save
                                                            </Button>
                                                        }
                                                        {loading &&
                                                            <Skeleton height={50} />
                                                        }
                                                    </div>
                                                </div>


                                                {!loading && showEmailTemplateFields &&
                                                    <div>
                                                        {/* Email Content */}
                                                        <div className="row mt-4">
                                                            <div className="col-12">
                                                                <TextField
                                                                    id="content"
                                                                    label="Add Content"
                                                                    value={content}
                                                                    onChange={e => setContent(e.target.value)}
                                                                    multiline
                                                                    className={'full-width'}
                                                                    minRows={5}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {loading &&
                                                    <Skeleton height={200} />
                                                }
                                            </div>

                                            {/* System Variables */}
                                            <div className="col-4 ps-5">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {!loading &&
                                                            <>
                                                                <h4 className={'text-muted p-0 m-0'}>System Variable</h4>
                                                                <ul className={'text-muted mt-3'}>
                                                                    <li>$agentName</li>
                                                                    <li className={'mt-2'}>$agentEmail</li>
                                                                    <li className={'mt-2'}>$agentContact</li>
                                                                    <li className={'mt-2'}>$clientName</li>
                                                                    <li className={'mt-2'}>$clientEmail</li>
                                                                    <li className={'mt-2'}>$clientContact</li>
                                                                    <li className={'mt-2'}>$checkType</li>
                                                                    <li className={'mt-2'}>$propertyType</li>
                                                                    <li className={'mt-2'}>$numberOfBedrooms</li>
                                                                    <li className={'mt-2'}>$area</li>
                                                                    <li className={'mt-2'}>$community</li>
                                                                    <li className={'mt-2'}>$unit</li>
                                                                    <li className={'mt-2'}>$visitDate</li>
                                                                    <li className={'mt-2'}>$visitSlot</li>
                                                                    <li className={'mt-2'}>$specialInstruction</li>
                                                                </ul>
                                                            </>
                                                        }
                                                        {loading &&
                                                            <Skeleton height={200} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Alerts */}
                <AlertM ref={notifications} />

            </Box>


        </Box >
    );
}

export default WhatsappTemplates;
